import {IHoldersFilter} from "../common/interfaces/holders.interface";
import {getRequest, postRequest} from "./utilities/http";
import {ADMIN_GET_HOLDER_DETAILS, ADMIN_HOLDERS} from "./utilities/api.urls";

export const fetchHolders = async (page: number, perPage: number, filters: IHoldersFilter) => {

    let data = {
        page:page,
        perPage:perPage,
        ...(filters?.name ? { name: filters.name } : {}),
        ...(filters?.institutions && filters?.institutions.length>0 ? { institution: filters.institutions } : {})
    }

    return await postRequest(ADMIN_HOLDERS,data);

}

export const fetchHolderDetails = async (
    holderID: number,
    pageLegacy: number | undefined,
    perPageLegacy: number | undefined,
    pageSpecimen: number | undefined,
    perPageSpecimen: number | undefined
) => {

    const queryParams = new URLSearchParams({
        pageSpecimen: String(pageSpecimen || 1),
        perPageSpecimen: String(perPageSpecimen || 20),
        pageLegacy: String(pageLegacy || 1),
        perPageLegacy: String(perPageLegacy || 20),
    });

    return await getRequest(`${ADMIN_GET_HOLDER_DETAILS}/${holderID}?${queryParams}`)
}