import { postRequest } from "./utilities/http";
import { CULTURAL_PROPERTY } from "./utilities/api.urls";
import { IHomeFilter } from "../common/interfaces/home.interface";

export async function fetchCentralRegistryData(
  page?: number,
  perPage?: number,
  searchFilters?: IHomeFilter
) {
  const queryParams = new URLSearchParams({
    page: String(page || 1),
    perPage: String(perPage || 10),
  });
  const url = `${CULTURAL_PROPERTY}?${queryParams}`;
  const data = searchFilters ? { ...searchFilters, page, perPage } : { page, perPage };
  return postRequest(url, data);
}
