import { FC, useMemo } from "react";
import Avatar from "@mui/material/Avatar";
import "./CustomAvatar.scss";
import { useAuthStore } from "../../store/authStore";

type Size = "small" | "medium" | "large";

interface IAvatarProps {
  size?: Size;
  src?: string;
}

const CustomAvatar: FC<IAvatarProps> = ({ size, src }) => {
  const firstName = useAuthStore((state) => state.authState.firstName);
  const lastName = useAuthStore((state) => state.authState.lastName);

  const getClassName = useMemo(() => {
    return `custom-avatar ${
      size === "small" ? "custom-avatar--small" : size === "large" ? "custom-avatar--large" : ""
    }`;
  }, [size]);

  const showFirstInitial = useMemo(() => {
    if (firstName && !src) return firstName.slice(0, 1);
  }, [firstName, src]);

  const showLastnameInitial = useMemo(() => {
    if (lastName && !src) return lastName.slice(0, 1);
  }, [lastName, src]);

  return (
    <>
      {firstName && lastName ? (
        <Avatar src={src} className={getClassName}>
          {showFirstInitial} {showLastnameInitial}
        </Avatar>
      ) : (
        <Avatar />
      )}
    </>
  );
};
export default CustomAvatar;
