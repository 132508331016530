import { IHolderDetailsLegacy } from "../../../common/interfaces/holders.interface";
import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { getSignificanceLevelLabel, getStatusLabel } from "../../../utilities/helpers";

export const HolderDetailsLegacyTableRow: FC<{
  index: number;
  legacy: IHolderDetailsLegacy;
  handleRowClick: () => void;
}> = ({ legacy, handleRowClick, index }) => {
  return (
    <TableRow key={index} onClick={handleRowClick}>
      <TableCell>{legacy.title}</TableCell>
      <TableCell>{legacy.institutionName}</TableCell>
      <TableCell>{getSignificanceLevelLabel(legacy.significanceLevel)}</TableCell>
      <TableCell>{getStatusLabel(legacy.recordStatus)}</TableCell>
      <TableCell>{legacy.yearOfProclamation}</TableCell>
      <TableCell>{legacy.dateOfRegistration}</TableCell>
      <TableCell>{legacy.specimenRangeFrom}</TableCell>
      <TableCell>{legacy.specimenRangeTo}</TableCell>
    </TableRow>
  );
};
