import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { FC, ReactNode, useMemo } from "react";
import { ITableHeadings, ITableMetadata } from "../../../common/interfaces/common.interfaces";
import "./CentralRegistryTable.scss";

const CentralRegistryTable: FC<{
  loader: boolean;
  headings: ITableHeadings[];
  children: ReactNode;
  metadata: ITableMetadata;
  handlePageChange: (page: number) => void;
  handleRowsPerPageChange: (newItemsPerPage: number) => void;
  noPagination?: boolean;
}> = ({
  headings,
  children,
  metadata,
  handlePageChange,
  handleRowsPerPageChange,
  loader,
  noPagination,
}) => {
  const labelDisplayedRows = useMemo(
    () => (paginationInfo: { page: number }) => {
      const { page } = paginationInfo;

      return `Страна ${page + 1}`;
    },
    // eslint-disable-next-line
    [metadata.itemsPerPage]
  );

  return (
    <TableContainer className="basic-table">
      <Table sx={{ minWidth: 650 }} aria-label="basic table">
        <TableHead className="basic-table__header">
          <TableRow>
            {headings.map((item) => (
              <TableCell key={item.id}>
                <p className="basic-table__row">{item.label}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <TableRow>
              <TableCell colSpan={headings.length} align="center">
                <CircularProgress
                  sx={{ marginTop: "1em", position: "absolute", right: "50%", top: "67%" }}
                />
              </TableCell>
            </TableRow>
          ) : (
            children
          )}
        </TableBody>
        <TableFooter>
          {!noPagination && (
            <TableRow>
              <TablePagination
                onPageChange={(event: React.MouseEvent | null, selectedPage: number) => {
                  handlePageChange(selectedPage);
                }}
                page={metadata.currentPage - 1}
                count={metadata.totalItems}
                rowsPerPage={metadata.itemsPerPage}
                labelRowsPerPage="Редова по страни"
                onRowsPerPageChange={(event) => {
                  handleRowsPerPageChange(Number(event.target.value));
                }}
                labelDisplayedRows={labelDisplayedRows}
                nextIconButtonProps={{ disabled: false }}
                backIconButtonProps={{ disabled: false }}
              />
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
export default CentralRegistryTable;
