import { Box } from "@mui/material";
import BasicTable from "../../../components/BasicTable/BasicTable";
import { useEffect, useState } from "react";
import NoDataTableRow from "../../../components/NoDataTableRow/NoDataTableRow";
import { ALL_NEWS_TABLE_HEADINGS } from "../../../common/constants/news.constants";
import { INewsTable } from "../../../common/interfaces/news.interface";
import { ITableMetadata } from "../../../common/interfaces/common.interfaces";
import { initialMetadataState } from "../../../common/constants/common.constants";
import { createToastNotification } from "../../../utilities/helpers";
import { getAllNews } from "../../../api/news";
import { useNavigate } from "react-router-dom";
import { ADMIN_NEWS_DETAILS } from "../../../common/constants/routes.constants";
import AllNewsTableRow from "../../../components/News/AllNewsTableRow/AllNewsTableRow";
import { useAuthStore } from "../../../store/authStore";
import { UserRole } from "../../../common/enums/common.enums";

const ListOfAllNews = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState<INewsTable[]>();
  const [loading, setLoading] = useState(false);
  const [metadata, setMetadata] = useState<ITableMetadata>(initialMetadataState);
  const { authState } = useAuthStore();
  const isMasterAdmin = authState.role === UserRole.MasterAdmin;

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [metadata.currentPage, metadata.itemsPerPage]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getAllNews(metadata.currentPage, metadata.itemsPerPage);
      setNews(response.news);
      setMetadata(response);
    } catch (error: any) {
      createToastNotification("error", "Дошло је до грешке приликом приказа листе вести", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (id: number) => {
    navigate(`${ADMIN_NEWS_DETAILS}/${id}`);
  };

  return (
    <Box className="page-table">
      {isMasterAdmin ? (
        <BasicTable
          headings={ALL_NEWS_TABLE_HEADINGS}
          loader={loading}
          metadata={metadata}
          setMetadata={setMetadata}
        >
          {news && news.length > 0 ? (
            news.map((item, index) => (
              <AllNewsTableRow
                key={index}
                handleRowClick={handleRowClick}
                index={index}
                item={item}
              />
            ))
          ) : (
            <NoDataTableRow message="Нема вести за приказ" />
          )}
        </BasicTable>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ListOfAllNews;
