import React, { useEffect, useMemo, useState } from "react";
import BasicTable from "../../components/BasicTable/BasicTable";
import { CENTRAL_REGISTER_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import PublicationFormModal from "../../components/PublicationsFormModal/PublicationFormModal";
import HomeTableRow from "./HomeTableRow/HomeTableRow";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import LatestNews from "./LatestNews/LatestNews";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import { createToastNotification } from "../../utilities/helpers";
import { fetchCulturalProperty } from "../../api/home";
import HomeTableHeader from "../../components/HomeTableHeader/HomeTableHeader";
import { IHome, IHomeFilter } from "../../common/interfaces/home.interface";
import { useFilterStore } from "../../store/filtersStore";
import { getPublishedNews } from "../../api/news";
import { useNavigate } from "react-router";
import { ALL_NEWS, NEWS } from "../../common/constants/routes.constants";
import { useAuthStore } from "../../store/authStore";

const Home = () => {
  const {
    getStatuses,
    getSignificanceLevels,
    getYearsOfProclamation,
    getOtherResponsibilities,
    getInstitutions,
    getHolders,
    getAuthors,
    getListsOfProclaimedAssets,
  } = useFilterStore();
  const { authState } = useAuthStore();
  const [searchFilters, setSearchFilters] = useState<IHomeFilter>({});
  const [loading, setLoading] = useState(false);
  const [showPublicationsForm, setShowPublicationsForm] = useState(false);
  const [culturalProperties, setCulturalProperties] = useState<IHome[]>([]);
  const [homePaginationMetadata, setHomePaginationMetadata] = useState({
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 50,
    totalItems: 0,
    totalPages: 0,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const newsPerPage = 3;
  const [latestNews, setLatestNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCulturalProperties();
    // eslint-disable-next-line
  }, [homePaginationMetadata.currentPage, homePaginationMetadata.itemsPerPage]);

  useEffect(() => {
    getStatuses();
    getSignificanceLevels();
    getYearsOfProclamation();
    getOtherResponsibilities();
    getInstitutions();
    getHolders();
    getAuthors();
    getListsOfProclaimedAssets();
    fetchLatestNews();
    // eslint-disable-next-line
  }, []);

  const fetchLatestNews = async () => {
    try {
      const response = await getPublishedNews(1, 7);
      setLatestNews(response.news);
    } catch (error: any) {
      createToastNotification(error, error?.response?.data?.message);
    }
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - newsPerPage, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + newsPerPage, latestNews.length - newsPerPage)
    );
  };

  const visibleNews = useMemo(() => {
    return latestNews.slice(currentIndex, currentIndex + newsPerPage);
  }, [latestNews, currentIndex, newsPerPage]);

  const getCulturalProperties = async () => {
    setLoading(true);
    try {
      const filteredSearchFilters = Object.fromEntries(
        Object.entries(searchFilters).filter(([_, value]) => {
          return (
            value != null &&
            value !== "" &&
            !(Array.isArray(value) && value.length === 0) &&
            !(typeof value === "object" && Object.keys(value).length === 0)
          );
        })
      );

      const response = await fetchCulturalProperty(
        homePaginationMetadata.currentPage,
        homePaginationMetadata.itemsPerPage,
        filteredSearchFilters
      );
      setCulturalProperties(response.data);
      setHomePaginationMetadata({
        currentPage: response.currentPage,
        itemsPerPage: response.itemsPerPage,
        itemCount: response.itemCount,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
      });
    } catch (error: any) {
      createToastNotification(error, error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack direction="column" spacing={6}>
      <Typography variant="h4" textAlign="center">
        Претрага централног регистра
      </Typography>
      <Box className="page-table">
        <HomeTableHeader
          getCulturalProperties={getCulturalProperties}
          setSearchFilters={setSearchFilters}
          showBtn
        />
        <BasicTable
          loader={loading}
          headings={CENTRAL_REGISTER_TABLE_HEADINGS}
          metadata={homePaginationMetadata}
          setMetadata={setHomePaginationMetadata}
        >
          {culturalProperties.length > 0 ? (
            culturalProperties.map((item, index) => (
              <HomeTableRow item={item} index={index} key={index} />
            ))
          ) : (
            <Typography m={2}>Нема културних добара за приказ!</Typography>
          )}
        </BasicTable>
      </Box>
      <Stack mt={1} direction="column" alignItems="center">
        <Typography variant="h4" textAlign="center">
          Вести
        </Typography>
        <Button
          color="primary"
          sx={{
            position: "relative",
            alignSelf: "flex-end",
            marginTop: 2,
          }}
          endIcon={<KeyboardDoubleArrowRightOutlinedIcon />}
          onClick={() => {
            if (authState.accessToken) {
              navigate(ALL_NEWS);
            } else {
              navigate(NEWS);
            }
          }}
        >
          Све вести
        </Button>
        <Stack direction="row" className="latest-news" mt={4} mb={4}>
          {latestNews.length > 3 && (
            <IconButton onClick={handlePrevClick}>
              <ArrowBackIosIcon />
            </IconButton>
          )}
          {visibleNews && visibleNews.length > 0 ? (
            visibleNews.map((news, index) => <LatestNews publishedNews={news} key={index} />)
          ) : (
            <Typography>Нема новости за приказ!</Typography>
          )}
          {latestNews.length > 3 && (
            <IconButton onClick={handleNextClick}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </Stack>
        {showPublicationsForm && (
          <PublicationFormModal handleClose={() => setShowPublicationsForm(false)} />
        )}
      </Stack>
    </Stack>
  );
};

export default Home;
