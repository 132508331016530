import { create } from "zustand";
import { Theme } from "@mui/material";
import { createTheme } from "../theme/theme-config";

const lightTheme = createTheme({ responsiveFontSizes: true });

interface ThemeState {
  themeOptions: Theme;
  setThemeOptions: (newOptions: Theme) => void;
}

export const useThemeStore = create<ThemeState>((set) => ({
  themeOptions: lightTheme,

  setThemeOptions: (newOptions: Theme) => set({ themeOptions: newOptions }),
}));
