import * as Yup from "yup";

export const SpecimenInfoValidationSchema = Yup.object({
  digitalCopyLink: Yup.string().nullable().url("Молимо вас унесите валидан URL"),
  conservation: Yup.object({
    sterilization: Yup.object({
      yearOfProtection: Yup.number()
        .typeError("Година мора бити број")
        .integer("Година мора бити цео број")
        .min(0, "Година мора бити позитиван број"),
    }),
    bookBlockConservation: Yup.object({
      yearOfProtection: Yup.number()
        .typeError("Година мора бити број")
        .integer("Година мора бити цео број")
        .min(0, "Година мора бити позитиван број"),
    }),
    bindingRestauration: Yup.object({
      yearOfProtection: Yup.number()
        .typeError("Година мора бити број")
        .integer("Година мора бити цео број")
        .min(0, "Година мора бити позитиван број"),
    }),
  }),
});
