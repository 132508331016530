import { getRequest } from "./utilities/http";
import {
  AUTHORS,
  BIBLIOGRAPHIC_TYPE,
  HOLDERS,
  INSTITUTIONS,
  OTHER_RESPONSIBILITIES,
  PROCLAIMED_CULTURAL_ASSETS,
  RECORD_STATUS,
  SIGNIFICANCE_LEVEL,
  YEAR_OF_PROCLAMATION,
} from "./utilities/api.urls";

export async function fetchRecordStatuses() {
  return getRequest(RECORD_STATUS);
}

export async function fetchAuthors(page?: number, perPage?: number, search?: string) {
  const queryParams = new URLSearchParams({
    page: String(page),
    perPage: String(perPage),
  });

  search && queryParams.append("search", search);

  const url = `${AUTHORS}?${queryParams}`;
  return getRequest(url);
}

export async function fetchInstitutions(page?: number, perPage?: number, search?: string) {
  const queryParams = new URLSearchParams({
    page: String(page),
    perPage: String(perPage),
  });

  search && queryParams.append("search", search);

  const url = `${INSTITUTIONS}?${queryParams}`;
  return getRequest(url);
}

export async function fetchSignificanceLevel() {
  return getRequest(SIGNIFICANCE_LEVEL);
}

export async function fetchHolders(search?: string) {
  const queryParams = new URLSearchParams();
  search && queryParams.append("search", search);

  const url = `${HOLDERS}?${queryParams}`;
  return getRequest(url);
}

export async function fetchYearsOfProclamation(search?: string) {
  const queryParams = new URLSearchParams();
  search && queryParams.append("search", search);

  const url = `${YEAR_OF_PROCLAMATION}?${queryParams}`;
  return getRequest(url);
}
export async function fetchProclaimedCulturalAssets(
  name?: string,
  page?: number,
  perPage?: number
) {
  const queryParams = new URLSearchParams({
    page: String(page),
    perPage: String(perPage),
  });

  name && queryParams.append("name", name);
  const url = `${PROCLAIMED_CULTURAL_ASSETS}?${queryParams}`;

  return getRequest(url);
}

export async function fetchOtherResponsibilities(page?: number, perPage?: number, search?: string) {
  const queryParams = new URLSearchParams({
    page: String(page),
    perPage: String(perPage),
  });

  search && queryParams.append("search", search);
  const url = `${OTHER_RESPONSIBILITIES}?${queryParams}`;

  return getRequest(url);
}

export async function fetchBibliographicUnitType() {
  return getRequest(BIBLIOGRAPHIC_TYPE);
}
