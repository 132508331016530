import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { fetchAuthors } from "../../api/filters";
import { LetterTranslator } from "../../utilities/letter-translator";

interface Option {
  authorId: number | null;
  authorName: string;
}

interface AuthorsCustomComponentProps {
  setSearchValue: (value: { authorId: number | null; authorName: string }[]) => void;
  searchTitle: string;
  optionsData: Option[];
  updateAuthorOptions: (authors: { authorID: number; authorName: string }[]) => void;
  getOptionLabel: (option: Option) => string;
  getOptionId: (option: Option) => number | null;
  handleBlur?: () => void;
  value?: any;
  error?: boolean;
  helperText?: string | undefined;
}

const filterOptions = (options: Option[], state: any) => {
  const inputValue = state.inputValue.toLowerCase();
  const latValue = LetterTranslator.cyr2lat(inputValue);
  const cyrValue = LetterTranslator.lat2cyr(inputValue);

  return options.filter((option: Option) => {
    const optionValue = option.authorName.toLowerCase();
    return (
      optionValue.includes(inputValue) ||
      optionValue.includes(cyrValue) ||
      optionValue.includes(latValue)
    );
  });
};

export const AuthorsComponent: React.FC<AuthorsCustomComponentProps> = ({
  setSearchValue,
  searchTitle,
  optionsData,
  updateAuthorOptions,
  getOptionLabel,
  getOptionId,
  handleBlur,
  error,
  helperText,
  value,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<Option[]>(optionsData);

  const handleAddOption = () => {
    if (inputValue && !options.some((option) => getOptionLabel(option) === inputValue)) {
      const newOption: Option = { authorId: null, authorName: inputValue };
      setOptions([...options, newOption]);

      const newBackendOption = {
        authorId: newOption.authorId,
        authorName: newOption.authorName,
      };
      setSearchValue([
        ...options.map((option) => ({
          authorId: getOptionId(option),
          authorName: getOptionLabel(option),
        })),
        newBackendOption,
      ]);

      setInputValue("");
    }
  };

  useEffect(() => {
    setOptions(optionsData);
  }, [optionsData]);

  const handleChange = (event: any, newValue: (string | Option)[]) => {
    const validOptions = newValue.map((value) => {
      if (typeof value === "string") {
        return { authorId: null, authorName: value };
      }
      return {
        authorId: getOptionId(value),
        authorName: getOptionLabel(value),
      };
    });

    setSearchValue(validOptions);
  };

  const updateOptions = async () => {
    const response = await fetchAuthors(1, 50, inputValue);
    const authors = response.authors as { authorID: number; authorName: string }[];
    updateAuthorOptions(authors);
  };

  return (
    <Autocomplete
      noOptionsText=""
      multiple
      value={value}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return getOptionLabel(option);
      }}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (newInputValue.length < 1) {
          setOptions(optionsData);
        }
        if (newInputValue?.length > 1) {
          updateOptions();
        }
        setInputValue(newInputValue);
      }}
      onBlur={handleBlur}
      freeSolo
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleAddOption();
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={searchTitle}
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={getOptionId(option)}>
          {getOptionLabel(option)}
        </li>
      )}
      filterOptions={filterOptions}
    />
  );
};
