import { create } from "zustand";

interface ModalState {
  isModalOpen: boolean;
  isCreateListModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  openCreateListModal: () => void;
  closeCreateListModal: () => void;
}

const useModalStore = create<ModalState>((set) => ({
  isModalOpen: false,
  isCreateListModalOpen: false,
  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  openCreateListModal: () => set({ isCreateListModalOpen: true }),
  closeCreateListModal: () => set({ isCreateListModalOpen: false }),
}));

export default useModalStore;
