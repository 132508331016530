import {ITableHeadings, ITableSort} from "../interfaces/common.interfaces";

export const NEWS_LINK = "/assets/icons/link-icon.svg";

export const NEWS_PLACEHOLDER_IMAGE = "/assets/featured-image-placeholder.png";

export const PUBLISHED_NEWS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "Наслов" },
  { id: 2, value: "", label: "Датум објављивања" },
  { id: 3, value: "", label: "" },
];

export const ALL_NEWS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "Наслов" },
  { id: 2, value: "", label: "Датум објављивања" },
  { id: 2, value: "", label: "Статус" },
  { id: 3, value: "", label: "" },
];

export const NEWS_SORT: ITableSort[] = [
  { id: 1, value: "name desc", label: "Име (А-Ш)" },
  { id: 2, value: "name asc", label: "Име (Ш-А)" },
  { id: 5, value: "date of creation oldest", label: "Датум креирања (најстарије)" },
  { id: 6, value: "date of creation newest", label: "Датум креирања (најновије)" },
];
