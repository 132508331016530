import { ListItemText, MenuItem, Paper, Radio, Stack, TextField, Typography } from "@mui/material";
import FileUploaderZone from "../../../uploader/FileUploaderZone/FileUploaderZone";
import React, { FC, useEffect, useState } from "react";
import { formatDate, getSignificanceLevelLabel, getStatusLabel } from "../../../utilities/helpers";
import { useFilterStore } from "../../../store/filtersStore";
import "./MonographicPublicationContainer.scss";
import { DatePicker } from "@mui/x-date-pickers";
import OtherResponsibilitiesCustomComponent from "../../OtherResponsibilityComponent/otherResponsibilityComponent";
import { AuthorsComponent } from "../../AuthorsComponent/AuthorsComponent";
import { fetchAuthors, fetchOtherResponsibilities } from "../../../api/filters";

interface PublicationContainerProps {
  formik: any;
}
const MonographicPublicationContainer: FC<PublicationContainerProps> = ({ formik }) => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = formik;
  const {
    statuses,
    getStatuses,
    significanceLevel,
    getSignificanceLevels,
    getOtherResponsibilities,
  } = useFilterStore();
  const allStatuses = statuses.recordStatus || [];
  const allSignificanceLevels = significanceLevel.significanceLevels || [];
  const [files, setFiles] = useState<File[] | null>(null);

  const handleFileChange = (files: File[] | null) => {
    setFiles(files);
    setFieldValue("mono.media", files);
  };

  const handleRemoveFile = (index: number) => {
    if (!files) {
      setFiles([]);
      setFieldValue("mono.media", []);
      return;
    }

    const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
    setFiles(updatedFiles);
    setFieldValue("mono.media", updatedFiles);
  };

  useEffect(() => {
    setFieldValue("mono.media", files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    populateAuthors();
    populateOtherResp();
    getStatuses();
    getSignificanceLevels();
    getOtherResponsibilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [optionsAuthor, setOptionsAuthor] = useState<{ authorId: number; authorName: string }[]>(
    []
  );
  const populateAuthors = async () => {
    const response = await fetchAuthors(1, 50);
    const authors = response.authors as { authorID: number; authorName: string }[];
    setOptionsAuthor(
      authors.map((author) => ({
        authorId: author.authorID,
        authorName: author.authorName,
      }))
    );
  };

  const [optionsOtherResp, setOptionsOtherResp] = useState<{ id: number | null; name: string }[]>(
    []
  );
  const populateOtherResp = async () => {
    const response = await fetchOtherResponsibilities(1, 50);
    const other = response.responsibilities as { id: number | null; name: string }[];
    setOptionsOtherResp(
      other.map((resp) => ({
        id: resp.id,
        name: resp.name,
      }))
    );
  };

  return (
    <form className="publication-form-container">
      <Stack direction="column" spacing={2} p={1}>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Број националне библиографије"
            name="mono.nationalBibliographyNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.nationalBibliographyNumber}
          />
          <TextField
            fullWidth
            label="Начин израде"
            required
            select
            name="mono.isHandwriting"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.isHandwriting}
            error={touched.mono?.isHandwriting && Boolean(errors.mono?.isHandwriting)}
            helperText={touched.mono?.isHandwriting && errors.mono?.isHandwriting}
          >
            <MenuItem value="true">Рукописно</MenuItem>
            <MenuItem value="false">Штампано</MenuItem>
          </TextField>
        </Stack>
        <TextField
          fullWidth
          label="Наслов"
          required
          name="mono.title"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.mono.title}
          error={touched.mono?.title && Boolean(errors.mono?.title)}
          helperText={touched.mono?.title && errors.mono?.title}
        />
        <AuthorsComponent
          setSearchValue={(value) => {
            if (Array.isArray(value)) {
              const updatedAuthors = value.map((selectedValue) => {
                if (typeof selectedValue === "string") {
                  return { authorId: null, authorName: selectedValue };
                } else {
                  const selectedAuthor = optionsAuthor.find(
                    (author) => author.authorName === selectedValue.authorName
                  );
                  return selectedAuthor
                    ? { authorId: selectedAuthor.authorId, authorName: selectedAuthor.authorName }
                    : { authorId: null, authorName: selectedValue.authorName };
                }
              });
              setFieldValue("mono.authors", updatedAuthors);
            }
          }}
          searchTitle="Аутори"
          optionsData={optionsAuthor}
          updateAuthorOptions={(authors) => {
            setOptionsAuthor([
              ...optionsAuthor,
              ...authors
                .map((author) => ({ authorId: author.authorID, authorName: author.authorName }))
                .filter(
                  (author) =>
                    !optionsAuthor.some(
                      (existingAuthor) => existingAuthor.authorId === author.authorId
                    )
                ),
            ]);
          }}
          getOptionLabel={(a) => a.authorName}
          getOptionId={(a) => a.authorId}
          value={
            formik.values.mono.authors
              ? formik.values.mono.authors.map((item: any) => ({
                  authorId: item.authorID,
                  authorName: item.authorName,
                }))
              : []
          }
        />
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Година издања"
            className="publication-form-container__date"
            name="mono.yearOfPublication"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.yearOfPublication}
          />
          <TextField
            fullWidth
            label="Meсто издања"
            className="publication-form-container__date"
            name="mono.placeOfIssue"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.placeOfIssue}
            error={touched.mono?.placeOfIssue && Boolean(errors.mono?.placeOfIssue)}
            helperText={touched.mono?.placeOfIssue && errors.mono?.placeOfIssue}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Статус записа"
            required
            select
            name="mono.recordStatus"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.recordStatus}
            error={touched.mono?.recordStatus && Boolean(errors.mono?.recordStatus)}
            helperText={touched.mono?.recordStatus && errors.mono?.recordStatus}
          >
            {allStatuses.length > 0 &&
              allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <ListItemText primary={getStatusLabel(status)} />
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label="Ниво значаја"
            required
            select
            name="mono.significanceLevel"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.significanceLevel}
            error={touched.mono?.significanceLevel && Boolean(errors.mono?.significanceLevel)}
            helperText={touched.mono?.significanceLevel && errors.mono?.significanceLevel}
          >
            {allSignificanceLevels.length > 0 &&
              allSignificanceLevels.map((significance) => (
                <MenuItem key={significance} value={significance}>
                  <ListItemText primary={getSignificanceLevelLabel(significance)} />
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Година проглашења"
            name="mono.yearOfProclamation"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.yearOfProclamation}
            error={touched.mono?.yearOfProclamation && Boolean(errors.mono?.yearOfProclamation)}
            helperText={touched.mono?.yearOfProclamation && errors.mono?.yearOfProclamation}
          />
          <TextField
            fullWidth
            label="Cobiss/Bisis ID"
            name="mono.cobissID"
            onBlur={handleBlur}
            value={values.mono.cobissID}
            error={touched.mono?.cobissID && Boolean(errors.mono?.cobissID)}
            helperText={touched.mono?.cobissID && errors.mono?.cobissID}
            onChange={handleChange}
          />
        </Stack>
        {values.mono.isHandwriting === "true" && (
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ width: "50%" }}
              label="Материјал за писање"
              name="mono.writingMaterial"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mono.writingMaterial}
            />
            <TextField
              sx={{ width: "50%" }}
              label="Сигнатура"
              name="mono.signatureBibliographicUnit"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.mono.signatureBibliographicUnit}
            />
          </Stack>
        )}
        <DatePicker
          format="DD-MM-YYYY"
          label="Датум регистрације"
          onChange={(value) => {
            const formattedDate = formatDate(value!.toISOString());
            setFieldValue("mono.dateOfRegistration", formattedDate);
          }}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Број страна/листова"
            name="mono.numberOfPages"
            onBlur={handleBlur}
            value={values.mono.numberOfPages}
            error={touched.mono?.numberOfPages && Boolean(errors.mono?.numberOfPages)}
            helperText={touched.mono?.numberOfPages && errors.mono?.numberOfPages}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Димензије"
            name="mono.dimension"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.mono.dimension}
            error={touched.mono?.dimension && Boolean(errors.mono?.dimension)}
            helperText={touched.mono?.dimension && errors.mono?.dimension}
          />
        </Stack>
        <TextField
          fullWidth
          label="Линк ка дигиталним библиотекама"
          name="mono.digitalLibraryLink"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.mono.digitalLibraryLink}
          error={touched.mono?.digitalLibraryLink && Boolean(errors.mono?.digitalLibraryLink)}
          helperText={touched.mono?.digitalLibraryLink && errors.mono?.digitalLibraryLink}
        />

        <OtherResponsibilitiesCustomComponent
          setSearchValue={(value) => setFieldValue("mono.otherResponsibilities", value)}
          searchTitle="Остале одговорности"
          optionsData={optionsOtherResp}
          getOptionLabel={(resp) => resp.name}
          getOptionId={(resp) => resp.id}
          handleBlur={handleBlur}
          error={touched.mono?.otherResponsibilities && Boolean(errors.mono?.otherResponsibilities)}
          helperText={touched.mono?.otherResponsibilities && errors.mono?.otherResponsibilities}
          updateOtherRespoOptions={(others) => {
            setOptionsOtherResp([
              ...optionsOtherResp,
              ...others.filter((o) => !optionsOtherResp.some((existing) => existing.id === o.id)),
            ]);
          }}
        />
        <Typography variant="body2">
          Одаберите врсту библиографске јединице на основу језика
        </Typography>
        <Stack direction="row" spacing={2}>
          <Paper elevation={0} className="publication-form-container__checkbox-buttons">
            <Radio
              checked={values.mono.isForeign === false}
              onChange={() => {
                setFieldValue("mono.isForeign", false);
              }}
              value={false}
            />
            <Typography variant="body2">Српска књига</Typography>
          </Paper>
          <Paper elevation={0} className="publication-form-container__checkbox-buttons">
            <Radio
              checked={values.mono.isForeign === true}
              onChange={() => {
                setFieldValue("mono.isForeign", true);
              }}
              value={true}
            />
            <Typography variant="body2">Страна књига</Typography>
          </Paper>
        </Stack>
        <Typography variant="body2">Дигитални објекат</Typography>
        <FileUploaderZone
          handleFileChange={handleFileChange}
          handleRemoveFile={handleRemoveFile}
          files={files}
          maxFiles={1}
          allowedFormats={[
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
            "application/vnd.oasis.opendocument.spreadsheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
            "application/vnd.oasis.opendocument.presentation",
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/webp",
          ]}
          customSizeTitle={
            "pdf, docx, doc, odt, xls, xlsx, csv, ods, ppt, pptx, pps, ppsx, odp, jpg, jpeg, png, webp. Максимална величина фотографије је 50MB."
          }
        />
      </Stack>
    </form>
  );
};
export default MonographicPublicationContainer;
