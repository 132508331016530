import { getRequest, postRequest } from "./utilities/http";
import { LISTS_CULTURAL_PROPERTY, CULTURAL_PROPERTY, ADD_UNIT } from "./utilities/api.urls";
import { ICreateList } from "../common/interfaces/lists.interface";

export async function createList(data: ICreateList) {
  return postRequest(LISTS_CULTURAL_PROPERTY, data);
}

export function fetchAssetDetails(ID: number) {
  return getRequest(`${LISTS_CULTURAL_PROPERTY}/${ID}`);
}

export async function deleteAsset(ID: number) {
  const url = `${LISTS_CULTURAL_PROPERTY}/${ID}/delete`;
  return postRequest(url, {});
}

export async function fetchUnitForList(
  id: number,
  bibliographicUnit: string,
  page?: number,
  perPage?: number
) {
  const url = `${CULTURAL_PROPERTY}`;
  return postRequest(url, {
    lists: [id],
    itemType: [bibliographicUnit],
    page: page,
    perPage: perPage,
  });
}

export async function removeUnitFromList(bibliographicUnitID: number) {
  const url = `${LISTS_CULTURAL_PROPERTY}/${bibliographicUnitID}/delete-unit`;
  return postRequest(url, {});
}

export async function fetchUnits(significanceLevel: string | undefined, bibliographicUnit: string) {
  const url = `${CULTURAL_PROPERTY}`;
  return postRequest(url, {
    significanceLevel: [significanceLevel],
    itemType: [bibliographicUnit],
  });
}

export function addUnitToList(data: { listID: number; bibliographicUnitID: number }) {
  const url = `${LISTS_CULTURAL_PROPERTY}${ADD_UNIT}`;
  return postRequest(url, data);
}
