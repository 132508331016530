import { Card, CardContent, CardHeader } from "@mui/material";
import DetailsCardRow from "../../BibliographicUnitDetails/BasicDetailsCardComponent/DetailsCardRow/DetailsCardRow";
import { getSignificanceLevelLabel } from "../../../utilities/helpers";
import { FC } from "react";

const ListBasicInfo: FC<{ asset: any }> = ({ asset }) => {
  return (
    <Card>
      <CardHeader title="Основне информације" />
      <CardContent>
        <DetailsCardRow title="Назив листе" content={asset?.name} />
        <DetailsCardRow title="Ниво значаја" content={getSignificanceLevelLabel(asset?.level)} />
        <DetailsCardRow title="Редни број листе" content={asset?.ordinal} />
        <DetailsCardRow title="Година проглашења листе" content={asset?.year} />
        <DetailsCardRow
          title="Званични број проглашења из службеног гласника"
          content={asset?.issueNumber}
        />
      </CardContent>
    </Card>
  );
};

export default ListBasicInfo;
