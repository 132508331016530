export const colors = {
  neutral: {
    50: "#F8F9FA",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D2D6DB",
    400: "#9DA4AE",
    500: "#6C737F",
    600: "#4D5761",
    700: "#2F3746",
    800: "#1C2536",
    900: "#111927",
  },
  blue: {
    lightest: "#FAFDFF",
    light: "#ECF4FA",
    main: "#3178B3",
    dark: "#245D8B",
    darkest: "#193E5D",
    contrastText: "#FFFFFF",
  },
  green: {
    lightest: "#F6FEF9",
    light: "#EDFCF2",
    main: "#16B364",
    dark: "#087443",
    darkest: "#084C2E",
    contrastText: "#FFFFFF",
  },
  indigo: {
    lightest: "#F5F7FF",
    light: "#EBEEFE",
    main: "#6366F1",
    dark: "#4338CA",
    darkest: "#312E81",
    contrastText: "#FFFFFF",
  },
  purple: {
    lightest: "#F9F5FF",
    light: "#F4EBFF",
    main: "#9E77ED",
    dark: "#6941C6",
    darkest: "#42307D",
    contrastText: "#FFFFFF",
  },
  success: {
    lightest: "#F0FDF9",
    light: "#3FC79A",
    main: "#10B981",
    dark: "#0B815A",
    darkest: "#134E48",
    contrastText: "#FFFFFF",
  },
  info: {
    lightest: "#ECFDFF",
    light: "#CFF9FE",
    main: "#06AED4",
    dark: "#0E7090",
    darkest: "#164C63",
    contrastText: "#FFFFFF",
  },
  warning: {
    lightest: "#FFFAEB",
    light: "#FEF0C7",
    main: "#F79009",
    dark: "#B54708",
    darkest: "#7A2E0E",
    contrastText: "#FFFFFF",
  },
  error: {
    lightest: "#FEF3F2",
    light: "#FEE4E2",
    main: "#F04438",
    dark: "#B42318",
    darkest: "#7A271A",
    contrastText: "#FFFFFF",
  },
};
