import { alpha } from "@mui/system/colorManipulator";
import type { ColorRange, PaletteColor } from "@mui/material/styles/createPalette";
import { colors } from "../common/constants/colors.constants";

const withAlphas = (color: PaletteColor): PaletteColor => {
  return {
    ...color,
    alpha4: alpha(color.main, 0.04),
    alpha8: alpha(color.main, 0.08),
    alpha12: alpha(color.main, 0.12),
    alpha30: alpha(color.main, 0.3),
    alpha50: alpha(color.main, 0.5),
  };
};

export const neutral: ColorRange = colors.neutral;

export const blue = withAlphas(colors.blue);

export const green = withAlphas(colors.green);

export const indigo = withAlphas(colors.indigo);

export const purple = withAlphas(colors.purple);

export const success = withAlphas(colors.success);

export const info = withAlphas(colors.info);

export const warning = withAlphas(colors.warning);

export const error = withAlphas(colors.error);
