export const CENTRAL_REGISTRY = "/central-registry";
export const LOGIN_PAGE = "/login";
export const HOME_PAGE = "/";
export const LIST_OF_INSTITUTIONS = "/institutions";
export const NEWS = "/news";
export const ALL_NEWS = "/admin/news";
export const NEWS_DETAILS = "/news-details";
export const ADMIN_NEWS_DETAILS = "/admin/news-details";
export const BIBLIOGRAPHIC_UNIT_DETAILS = "/bibliographic-unit-details";
export const USERS = "/users";
export const USER = "/user";
export const HOLDERS = '/admin/holders'
export const HOLDER_DETAILS = '/admin/holder/details'
export const ADD_NEWS = "/admin/news/add";
export const EDIT_NEWS = "/admin/news/edit";
export const LIST_OF_CULTURAL_ASSETS = "/cultural-assets-list";
