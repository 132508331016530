import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import CssBaseline from "@mui/material/CssBaseline";
import Routes from "./Routes";
import { useThemeStore } from "./store/useThemeStore";
import "./App.scss";
import { Slide, ToastContainer } from "react-toastify";

function App() {
  const { themeOptions } = useThemeStore();
  const theme = createTheme(themeOptions);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer position="top-right" transition={Slide} newestOnTop autoClose={7000} />
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Routes />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
