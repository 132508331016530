import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import PublicationFormModal from "../../components/PublicationsFormModal/PublicationFormModal";
import CentralRegistryTableRow from "./CentralRegisterTableRow/CentralRegistryTableRow";
import { CENTRAL_REGISTER_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import useModalStore from "../../store/useModalsStore";
import CentralRegistryTableHeader from "../../components/CentralRegistryTableHeader/CentralRegistryTableHeader";
import { IHomeFilter } from "../../common/interfaces/home.interface";
import { createToastNotification } from "../../utilities/helpers";
import { fetchCentralRegistryData } from "../../api/central-registry";
import { useFilterStore } from "../../store/filtersStore";
import { useNavigate } from "react-router";
import CentralRegistryTable from "./CentralRegistryTable/CentralRegistryTable";

const CentralRegistry = () => {
  const defaultPerPage = 50;
  const defaultAllPerPage = 100;
  const { isModalOpen, closeModal } = useModalStore();
  const [loading, setLoading] = useState(false);
  const [centralRegistryData, setCentralRegistryData] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetching, setFetching] = useState(false);
  const fetchingRef = useRef(fetching);
  const [metadataCentralRegistry, setMetadataCentralRegistry] = useState({
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: defaultPerPage,
    totalItems: 0,
    totalPages: 0,
  });

  const [allData, setAllData] = useState([]);
  const [metadataAllData, setMetadataAllData] = useState({
    page: 1,
    perPage: defaultAllPerPage,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxAllDataPage, setMaxAllDataPage] = useState<number | undefined>(undefined);
  const maxAllDataPageRef = useRef(maxAllDataPage);
  const [initiallyPopulatedData, setInitiallyPopulatedData] = useState<boolean>(false);

  const [searchFilters, setSearchFilters] = useState<IHomeFilter>({});
  const {
    getListsOfProclaimedAssets,
    getStatuses,
    getSignificanceLevels,
    getYearsOfProclamation,
    getOtherResponsibilities,
    getInstitutions,
    getHolders,
    getAuthors,
  } = useFilterStore();
  const navigate = useNavigate();

  const performSearch = async () => {
    await initiallyPopulateData();
  };

  useEffect(() => {
    getListsOfProclaimedAssets();
    getStatuses();
    getSignificanceLevels();
    getYearsOfProclamation();
    getOtherResponsibilities();
    getInstitutions();
    getHolders();
    getAuthors();
    initiallyPopulateData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initiallyPopulatedData) {
      showRegisterData();
    }
    // eslint-disable-next-line
  }, [metadataCentralRegistry.currentPage, metadataCentralRegistry.itemsPerPage]);

  const initiallyPopulateData = async () => {
    const filteredSearchFilters = Object.fromEntries(
      Object.entries(searchFilters).filter(([_, value]) => {
        return (
          value != null &&
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(typeof value === "object" && Object.keys(value).length === 0)
        );
      })
    );

    try {
      const fetchedData = await fetchCentralRegistryData(
        1,
        defaultAllPerPage,
        filteredSearchFilters
      );

      // @ts-ignore
      setAllData(fetchedData.units);
      setMetadataAllData({ page: 1, perPage: defaultAllPerPage });
      let data = fetchedData.units.slice(0, defaultPerPage);
      setCentralRegistryData(data);
      setMetadataCentralRegistry((prevState) => ({
        ...prevState,
        currentPage: 1,
        itemsPerPage: defaultPerPage,
      }));

      setInitiallyPopulatedData(true);
      maxAllDataPageRef.current = undefined;
    } catch (error: any) {
      setMetadataAllData({ page: 1, perPage: defaultAllPerPage });
      setMetadataCentralRegistry((prevState) => ({
        ...prevState,
        currentPage: 1,
        itemsPerPage: defaultPerPage,
      }));
      setCentralRegistryData([]);
      createToastNotification(error, error?.response?.data?.message);
    }
  };

  const showRegisterData = async () => {
    fetchingRef.current = true;
    setLoading(true);
    const filteredSearchFilters = Object.fromEntries(
      Object.entries(searchFilters).filter(([_, value]) => {
        return (
          value != null &&
          value !== "" &&
          !(Array.isArray(value) && value.length === 0) &&
          !(typeof value === "object" && Object.keys(value).length === 0)
        );
      })
    );

    const initialPage = metadataAllData.page;
    const initialPerPage = metadataAllData.perPage;
    const initialRegistryPage = metadataCentralRegistry.currentPage;
    const initialRegistryPerPage = metadataCentralRegistry.itemsPerPage;
    try {
      let data = allData.slice(
        (metadataCentralRegistry.currentPage - 1) * metadataCentralRegistry.itemsPerPage,
        (metadataCentralRegistry.currentPage - 1) * metadataCentralRegistry.itemsPerPage +
          metadataCentralRegistry.itemsPerPage
      );
      if (data.length < metadataCentralRegistry.itemsPerPage) {
        const fetchedData = await fetchCentralRegistryData(
          metadataAllData.page + 1,
          metadataAllData.perPage,
          filteredSearchFilters
        );

        let updatedAllData = [...allData, ...fetchedData.units];
        // @ts-ignore
        setAllData([...allData, ...fetchedData.units]);
        setMetadataAllData({
          page: fetchedData.currentPage,
          perPage: fetchedData.itemsPerPage,
        });
        // @ts-ignore
        data = updatedAllData.slice(
          (metadataCentralRegistry.currentPage - 1) * metadataCentralRegistry.itemsPerPage,
          (metadataCentralRegistry.currentPage - 1) * metadataCentralRegistry.itemsPerPage +
            metadataCentralRegistry.itemsPerPage
        );

        setCentralRegistryData(data);
        if (fetchedData.units.length < defaultAllPerPage) {
          if (maxAllDataPageRef.current !== undefined) {
            return;
          }
          maxAllDataPageRef.current = metadataCentralRegistry.currentPage;
        }
      } else {
        setCentralRegistryData(data);
      }
    } catch (error: any) {
      setMetadataAllData({ page: initialPage, perPage: initialPerPage });
      setMetadataCentralRegistry((prevState) => ({
        ...prevState,
        currentPage: initialRegistryPage,
        itemsPerPage: initialRegistryPerPage,
      }));
      createToastNotification(error, error?.response?.data?.message);
    } finally {
      setLoading(false);
      fetchingRef.current = false;
    }
  };

  const handlePageChange = (page: number) => {
    if (page < 0 || (maxAllDataPageRef.current !== undefined && page > maxAllDataPageRef.current)) {
      return;
    }
    setMetadataCentralRegistry({ ...metadataCentralRegistry, currentPage: page + 1 });
  };

  const handleRowsPerPageChange = (newItemsPerPage: number) => {
    setMetadataCentralRegistry({
      ...metadataCentralRegistry,
      itemsPerPage: newItemsPerPage,
      currentPage: 1,
    });
    maxAllDataPageRef.current = undefined;
  };

  const handleRowClick = (item: any) => {
    const itemType = item.specimenID === null ? "bibliographicUnit" : "specimen";
    const id = item.specimenID === null ? item.bibliographicUnitID : item.specimenID;
    if (id) {
      const path = `/details/${id}/${itemType}`;
      navigate(path);
    }
  };

  return (
    <Box className="page-table">
      <CentralRegistryTableHeader
        setSearchFilters={setSearchFilters}
        performSearch={performSearch}
      />
      <CentralRegistryTable
        loader={loading}
        headings={CENTRAL_REGISTER_TABLE_HEADINGS}
        metadata={metadataCentralRegistry}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      >
        {centralRegistryData.length > 0 ? (
          centralRegistryData.map((item, index) => (
            <CentralRegistryTableRow
              key={index}
              item={item}
              index={index}
              handleRowClick={() => handleRowClick(item)}
            />
          ))
        ) : (
          <Typography m={2}>Нема културних добара за приказ!</Typography>
        )}
      </CentralRegistryTable>
      {isModalOpen && (
        <PublicationFormModal
          handleClose={() => {
            closeModal();
            initiallyPopulateData();
          }}
        />
      )}
    </Box>
  );
};

export default CentralRegistry;
