import { ITableHeadings } from "../interfaces/common.interfaces";

export const CENTRAL_REGISTER_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "РБ" },
  { id: 2, value: "", label: "НАСЛОВ/НАЗИВ" },
  { id: 3, value: "", label: "АУТОР" },
  { id: 4, value: "", label: "МЕСТО ИЗДАЊА" },
  { id: 5, value: "", label: "ГОД. ИЗДАЊА" },
  { id: 6, value: "", label: "СОПСТВЕНИК" },
  { id: 7, value: "", label: "ИНСТИТУЦИЈА" },
  { id: 8, value: "", label: "ОСТАЛЕ ОДГОВОРНОСТИ" },
  { id: 9, value: "", label: "НИВО ЗНАЧАЈА" },
  { id: 10, value: "", label: "ВРСТА ГРАЂЕ" },
  { id: 11, value: "", label: "СТАТУС ЗАПИСА" },
  { id: 12, value: "", label: "ГОД. ПРОГЛАШЕЊА" },
  { id: 13, value: "", label: "ДАТУМ УПИСА" },
];

export const SPECIMENS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "specimenID", label: "ID ПРИМЕРКА" },
  { id: 2, value: "signature", label: "СИГНАТУРА ПРИМЕРКА" },
  { id: 3, value: "inventoryNumber", label: "ИНВЕНТАРНИ БРОЈ" },
  { id: 4, value: "holderName", label: "ИМЕ СОПСТВЕНИКА" },
  { id: 5, value: "institutionName", label: "НАЗИВ ИНСТИТУЦИЈЕ" },
];

export const INSTITUTIONS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "ИД ИНСТИТУЦИЈЕ" },
  { id: 2, value: "", label: "НАЗИВ" },
];

export const USER_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "ИД " },
  { id: 2, value: "", label: "ИМЕ И ПРЕЗИМЕ" },
  { id: 3, value: "", label: "EМАИЛ" },
];

export const HOLDERS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "ИД" },
  { id: 2, value: "", label: "НАЗИВ СОПСТВЕНИКА" },
  { id: 3, value: "", label: "ИНСТИТУЦИЈА" },
];

export const HOLDER_DETAILS_LEGACY_TABLE_HEADINGS: ITableHeadings[] = [
  // { id: 1, value: "bibliographicUnitID", label: "БИБЛИОГРАФСКА ЈЕДИНИЦА ID" },
  { id: 1, value: "title", label: "НАЗИВ" },
  { id: 2, value: "institutionName", label: "НАЗИВ ИНСТИТУЦИЈЕ" },
  { id: 3, value: "signifianceLevel", label: "НИВО ЗНАЧАЈА" },
  { id: 4, value: "recordStatus", label: "СТАТУС ЗАПИСА" },
  { id: 5, value: "yearOfProclamation", label: "ГОДИНА ПРОГЛАШЕЊА" },
  { id: 6, value: "dateOfRegistration", label: "ДАТУМ УПИСА У РЕГИСТАР" },
  { id: 7, value: "specimenRangeFrom", label: "РАСПОН ПРИМЕРКА ОД" },
  { id: 8, value: "specimenRangeTo", label: "РАСПОН ПРИМЕРКА ДО" },
];

export const HOLDER_DETAILS_SPECIMEN_TABLE_HEADINGS: ITableHeadings[] = [
  // { id: 1, value: "bibliographicUnitID", label: "БИБЛИОГРАФСКА ЈЕДИНИЦА ID" },
  // { id: 2, value: "specimenID", label: "ID ПРИМЕРКА" },
  { id: 1, value: "title", label: "НАЗИВ" },
  { id: 2, value: "authorName", label: "ИМЕ И ПРЕЗИМЕ АУТОРА" },
  { id: 3, value: "yearOfPublication", label: "ГОДИНА ИЗДАЊА" },
  { id: 4, value: "placeOfIssue", label: "МЕСТО ИЗДАЊА" },
  { id: 5, value: "cobbisID", label: "КОБИС ИД" },
  { id: 6, value: "nationalBibliographyNumber", label: "БРОЈ НАЦИОНАЛНЕ БИОГРАФИЈЕ" },
  { id: 7, value: "signature", label: "СИГНАТУРА" },
  { id: 8, value: "inventoryNumber", label: "ИНВЕРТАРНИ БРОЈ" },
  { id: 9, value: "institutionName", label: "ИНСТИТУЦИЈА" },
];

export const CULTURAL_ASSETS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "НАЗИВ" },
  { id: 2, value: "", label: "НИВО ЗНАЧАЈА" },
  { id: 3, value: "", label: "ГОДИНА" },
];

export const LISTS_TABLE_HEADINGS: ITableHeadings[] = [
  { id: 1, value: "", label: "НАЗИВ" },
  { id: 2, value: "", label: "АУТОРИ" },
  { id: 3, value: "", label: "ГОДИНА ИЗДАЊА" },
  { id: 4, value: "", label: "МЕСТО ИЗДАЊА" },
  { id: 7, value: "", label: "" },
];
