import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { FC } from "react";
import "./DeleteUnitComponent.scss";

const DeleteUnitCard: FC<{
  handleDelete: () => void;
  deleteButtonName: string;
}> = ({ handleDelete, deleteButtonName }) => {
  return (
    <Card className="delete-unit-card">
      <CardHeader title="Брисање" className="delete-unit-card__title" />
      <CardContent className="delete-unit-card__content">
        <Button
          variant="outlined"
          color="error"
          className="delete-unit-card__action"
          onClick={handleDelete}
        >
          {deleteButtonName}
        </Button>
        <p className="delete-unit-card__warning">
          Имајте на уму да се једном обрисани подаци не могу касније вратити!
        </p>
      </CardContent>
    </Card>
  );
};

export default DeleteUnitCard;
