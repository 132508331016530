import { Box, Paper, Stack, Typography } from "@mui/material";
import ModalActions from "../Modal/ModalActions";
import CustomModal from "../Modal/CustomModal";
import { FC, useState } from "react";
import LegacyLibrariesContainer from "./LegacyLibrariesContainer/LegacyLibrariesContainer";
import { DocumentType } from "../../common/enums/common.enums";
import { useFormik } from "formik";
import { validationSchema } from "../../common/validation-schemas/publications.shema";
import { createLegacyPublication, createMonographicPublication } from "../../api/publications";
import { createToastNotification } from "../../utilities/helpers";
import MonographicPublicationContainer from "./MonographicPublicationContainer/MonographicPublicationContainer";
import "./PublicationFormModal.scss";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { useAuthStore } from "../../store/authStore";
const PublicationFormModal: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const { authState } = useAuthStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const defaultInstitutionId = Number(authState?.institution?.id);
  const formik = useFormik({
    initialValues: {
      mono: {
        isHandwriting: false,
        title: "",
        authors: [],
        otherResponsibilities: [],
        isForeign: false,
        yearOfPublication: "",
        dateOfRegistration: "",
        placeOfIssue: "",
        numberOfPages: "",
        dimension: "",
        digitalLibraryLink: "",
        cobissID: "",
        nationalBibliographyNumber: "",
        media: [],
        recordStatus: "",
        significanceLevel: "",
        yearOfProclamation: undefined,
        writingMaterial: "",
        signatureBibliographicUnit: "",
      },
      legacy: {
        title: EMPTY_STRING,
        holder: {
          holderID: null,
          holderName: EMPTY_STRING,
        },
        institutionID: defaultInstitutionId,
        recordStatus: EMPTY_STRING,
        significanceLevel: EMPTY_STRING,
        yearOfProclamation: null,
        dateOfRegistration: EMPTY_STRING,
        description: EMPTY_STRING,
        specimenRangeТо: EMPTY_STRING,
        specimenRangeFrom: EMPTY_STRING,
      },
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
  });
  const [selectedType, setSelectedType] = useState<DocumentType>(DocumentType.Monograph);
  function removeEmptyFields(obj: Record<string, any>): Record<string, any> {
    return Object.entries(obj)
      .filter(([_, value]) => value !== undefined && value !== null && value !== "")
      .reduce(
        (acc, [key, value]) => {
          if (key === "holder" && value.holderName === "") {
            return acc;
          }
          if (key === "yearOfProclamation" && value === 0) {
            return acc;
          }
          acc[key] = value;
          return acc;
        },
        {} as Record<string, any>
      );
  }

  const createLegacyLibraryDTO = removeEmptyFields({
    title: formik.values.legacy.title,
    institutionID: formik.values.legacy.institutionID,
    recordStatus: formik.values.legacy.recordStatus,
    holder: {
      holderID: formik.values.legacy.holder.holderID || null,
      holderName: formik.values.legacy.holder.holderName,
    },
    significanceLevel: formik.values.legacy.significanceLevel,
    description: formik.values.legacy.description,
    specimenRangeFrom: formik.values.legacy.specimenRangeFrom,
    specimenRangeTo: formik.values.legacy.specimenRangeТо,
    yearOfProclamation: Number(formik.values.legacy.yearOfProclamation),
    dateOfRegistration: formik.values.legacy.dateOfRegistration,
  });

  const createPublicationDTO = removeEmptyFields({
    nationalBibliographyNumber: formik.values.mono.nationalBibliographyNumber,
    isHandwriting: formik.values.mono.isHandwriting,
    title: formik.values.mono.title,
    authors: formik.values.mono.authors,
    yearOfPublication: formik.values.mono.yearOfPublication,
    placeOfIssue: formik.values.mono.placeOfIssue,
    recordStatus: formik.values.mono.recordStatus,
    significanceLevel: formik.values.mono.significanceLevel,
    yearOfProclamation: formik.values.mono.yearOfProclamation,
    numberOfPages: formik.values.mono.numberOfPages,
    dimension: formik.values.mono.dimension,
    digitalLibraryLink: formik.values.mono.digitalLibraryLink,
    otherResponsibilities: formik.values.mono.otherResponsibilities,
    cobissID: formik.values.mono.cobissID,
    isForeign: formik.values.mono.isForeign,
    dateOfRegistration: formik.values.mono.dateOfRegistration,
    writingMaterial: formik.values.mono.writingMaterial,
    signatureBibliographicUnit: formik.values.mono.signatureBibliographicUnit,
    media: formik.values.mono.media,
  });

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    if (selectedType === DocumentType.Monograph) {
      try {
        await createMonographicPublication(createPublicationDTO);
        createToastNotification("success", "Успешно креирана монографска публикација.");
        handleClose();
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
    } else {
      try {
        await createLegacyPublication(createLegacyLibraryDTO);
        createToastNotification("success", "Успешно креиран легат и библиотека целина.");
        handleClose();
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <CustomModal open={true} title="Додај нову библиографску јединицу" handleClose={handleClose}>
      <Box sx={{ maxHeight: "70vh", overflowY: "auto" }} className="publication-form-modal">
        <Stack direction="column" spacing={2}>
          <Typography variant="body2" pl={1}>
            Одаберите тип библиографиске јединице
          </Typography>
          <Stack direction="row" spacing={2} pl={1}>
            <Paper
              className={`publication-form-modal__paper-buttons ${
                selectedType === DocumentType.Monograph ? "active" : ""
              }`}
              elevation={0}
              onClick={() => setSelectedType(DocumentType.Monograph)}
            >
              <Typography variant="body2">Монографска публикација</Typography>
            </Paper>
            <Paper
              className={`publication-form-modal__paper-buttons ${
                selectedType === DocumentType.Legacy ? "active" : ""
              }`}
              elevation={0}
              onClick={() => setSelectedType(DocumentType.Legacy)}
            >
              <Typography variant="body2">Легат и библиотека целина</Typography>
            </Paper>
          </Stack>
          {selectedType === DocumentType.Monograph && (
            <MonographicPublicationContainer formik={formik} />
          )}
          {selectedType === DocumentType.Legacy && <LegacyLibrariesContainer formik={formik} />}
        </Stack>
      </Box>
      <ModalActions
        buttonAction={handleSubmit}
        buttonText="Сачувај"
        cancelBtnText="Назад"
        cancelAction={handleClose}
        disabled={isSubmitting}
      />
    </CustomModal>
  );
};

export default PublicationFormModal;
