import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import CustomSelectInput from "../../../components/CustomSelectInputs/CustomSelectInput";
import { useFilterStore } from "../../../store/filtersStore";
import { UserRole } from "../../../common/enums/common.enums";
import editIcon from "../../../assets/icons/edit-icon.png";
import { useAuthStore } from "../../../store/authStore";
import ModalActions from "../../../components/Modal/ModalActions";
import { createToastNotification } from "../../../utilities/helpers";

interface StatusOption {
  value: string;
  label: string;
}

interface UserDetailsProps {
  formik: any;
}

const UserDetails: FC<UserDetailsProps> = ({ formik }) => {
  const { institutions, getInstitutions } = useFilterStore();
  const { authState } = useAuthStore();
  const [isEdit, setIsEdit] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { values, handleChange, setFieldValue } = formik;
  const institutionOptions: StatusOption[] = institutions.map((inst) => ({
    value: inst.institutionID.toString(),
    label: inst.institutionName,
  }));

  const roles = [
    { value: UserRole.MasterAdmin, label: "Мастер администратор" },
    { value: UserRole.Admin, label: "Администратор" },
    { value: UserRole.ProtectiveLibrary, label: "Библиотека заштитарка" },
  ];

  const handleEditClick = () => {
    setIsEdit(true);
    setShowButton(false);
  };

  useEffect(() => {
    getInstitutions();
    // eslint-disable-next-line
  }, []);

  //TODO discuss
  const isRoleDisabled = authState.email === values.user.email;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await formik.submitForm();
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Stack border="1px solid #000" borderRadius={1} p={1.5}>
      {authState.role === UserRole.MasterAdmin && showButton && (
        <Stack alignItems="flex-end" mb={2}>
          <Button
            variant="outlined"
            size="small"
            endIcon={<img src={editIcon} alt="edit-icon" />}
            onClick={handleEditClick}
          >
            Измени
          </Button>
        </Stack>
      )}
      <Stack direction="column" spacing={2}>
        <TextField
          fullWidth
          label="Име"
          name="user.firstname"
          value={values.user.firstname}
          onChange={handleChange}
          disabled={!isEdit}
        />
        <TextField
          fullWidth
          label="Презиме"
          name="user.lastname"
          value={values.user.lastname}
          onChange={handleChange}
          disabled={!isEdit}
        />
        <TextField
          fullWidth
          label="Имејл"
          name="user.email"
          value={values.user.email}
          onChange={handleChange}
          disabled={!isEdit}
        />
        <CustomSelectInput
          disabled={!isEdit}
          values={institutionOptions}
          label="Институција која врши упис"
          onChange={(value) => {
            const selectedInstitution = institutions.find(
              (institution) => institution.institutionID === value
            );
            if (selectedInstitution) {
              setFieldValue("user.institution", {
                institutionID: selectedInstitution.institutionID,
                institutionName: selectedInstitution.institutionName,
              });
            }
          }}
          preselectedValueIds={
            values?.user.institution?.institutionID
              ? [values.user.institution.institutionID.toString()]
              : []
          }
        />
        <FormControl fullWidth>
          <InputLabel id="role-label">Улога</InputLabel>
          <Select
            name="user.role"
            label="Улога"
            value={formik.values.user.role || ""}
            onChange={formik.handleChange}
            disabled={!isEdit || isRoleDisabled}
          >
            {roles.map((role) => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {isEdit && (
        <ModalActions
          buttonAction={handleSubmit}
          disabled={isSubmitting}
          buttonText="Сачувај"
          cancelBtnText="Назад"
          cancelAction={() => {
            setIsEdit(false);
            setShowButton(true);
          }}
        />
      )}
    </Stack>
  );
};
export default UserDetails;
