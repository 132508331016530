import type { ThemeOptions } from "@mui/material/styles/createTheme";
import { ThemeConfig } from "../theme-config";
import { createPalette as createLightPalette } from "../light/create-palette";
import { createShadows as createLightShadows } from "../light/create-shadows";
import { createComponents as createLightComponents } from "../light/create-components";

export const createColorOptions = (config: ThemeConfig): ThemeOptions => {
  const palette = createLightPalette();
  const components = createLightComponents({ palette });
  const shadows = createLightShadows();

  return {
    components,
    palette,
    shadows,
  };
};
