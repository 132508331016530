import HomeTableHeader from "../HomeTableHeader/HomeTableHeader";
import React, { FC, useEffect, useState } from "react";
import { IHomeFilter } from "../../common/interfaces/home.interface";
import { Button, MenuItem, Stack, Select, FormControl, InputLabel } from "@mui/material";
import { createToastNotification, getUnitType, mapLabels } from "../../utilities/helpers";
import { fetchBibliographicUnitType } from "../../api/filters";
import CustomSelectInput from "../CustomSelectInputs/CustomSelectInput";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

interface TableHeaderProps {
  performSearch: () => Promise<void>;
  setSearchFilters: React.Dispatch<React.SetStateAction<IHomeFilter>>;
}

const CentralRegistryTableHeader: FC<TableHeaderProps> = ({ performSearch, setSearchFilters }) => {
  const [conservationValue, setConservationValue] = useState<boolean | null>(null);
  const [digitizationValue, setDigitizationValue] = useState<boolean | null>(null);
  const [microfilmValue, setMicrofilmValue] = useState<boolean | null>(null);
  const [bibliographicType, setBibliographicType] = useState<{ types: string[] }>({ types: [] });
  const currentYear = dayjs();
  const allBibliographicType = bibliographicType?.types || [];
  const bibliographicTypeLabels =
    allBibliographicType.length > 0 ? mapLabels(allBibliographicType, getUnitType) : [];
  const [isBibliographicUnitSelected, setIsBibliographicUnitSelected] = useState(false);
  const handleSearchChange = (field: keyof IHomeFilter | any, value: any) => {
    setSearchFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (value === null || (Array.isArray(value) && value.length === 0)) {
        // @ts-ignore
        delete updatedFilters[field];
      } else {
        // @ts-ignore
        updatedFilters[field] = value;
      }

      return updatedFilters;
    });
  };

  const handleDateChange = (value: any) => {
    if (value) {
      const formattedDate = dayjs(value).format("YYYY-MM");
      handleSearchChange("dateOfRegistration", [formattedDate]);
    } else {
      handleSearchChange("dateOfRegistration", []);
    }
  };

  const handleChange = (
    field: "hasConservation" | "hasDigitalization" | "hasMicrofilm",
    value: boolean | null
  ) => {
    switch (field) {
      case "hasConservation":
        setConservationValue(value);
        handleSearchChange("hasConservation", value);
        break;
      case "hasDigitalization":
        setDigitizationValue(value);
        handleSearchChange("hasDigitalization", value);
        break;
      case "hasMicrofilm":
        setMicrofilmValue(value);
        handleSearchChange("hasMicrofilm", value);
        break;
      default:
        break;
    }
  };

  const getBibliographicType = async () => {
    try {
      const response = await fetchBibliographicUnitType();
      setBibliographicType(response);
    } catch (error: any) {
      createToastNotification(error, error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getBibliographicType();
  }, []);

  return (
    <>
      <HomeTableHeader
        getCulturalProperties={async () => {}}
        setSearchFilters={setSearchFilters}
        isCentralRegistry
        setIsBibliographicUnitSelected={setIsBibliographicUnitSelected}
      />
      <Stack className="filter-section" direction="row" spacing={1} mb={1}>
        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Конзервација</InputLabel>
          <Select
            disabled={isBibliographicUnitSelected}
            label="Конзервација"
            value={conservationValue ?? ""}
            onChange={(event) => {
              const selectedValue = event.target.value;
              if (selectedValue === conservationValue?.toString()) {
                handleChange("hasConservation", null);
              } else {
                handleChange(
                  "hasConservation",
                  event.target.value === "null" ? null : event.target.value === "true"
                );
              }
            }}
          >
            <MenuItem value="true">Извршена</MenuItem>
            <MenuItem value="false">Није извршена</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Дигитализација</InputLabel>
          <Select
            disabled={isBibliographicUnitSelected}
            label="Дигитализација"
            value={digitizationValue ?? ""}
            onChange={(event) => {
              const selectedValue = event.target.value;
              if (selectedValue === digitizationValue?.toString()) {
                handleChange("hasDigitalization", null);
              } else {
                handleChange(
                  "hasDigitalization",
                  event.target.value === "null" ? null : event.target.value === "true"
                );
              }
            }}
          >
            <MenuItem value="true">Постоји дигитална копија</MenuItem>
            <MenuItem value="false">Не постоји дигитална копија</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Микрофилм</InputLabel>
          <Select
            disabled={isBibliographicUnitSelected}
            value={microfilmValue ?? ""}
            label="Микрофилм"
            onChange={(event) => {
              const selectedValue = event.target.value;

              if (selectedValue === microfilmValue?.toString()) {
                handleChange("hasMicrofilm", null);
              } else {
                handleChange(
                  "hasMicrofilm",
                  selectedValue === "true" ? true : selectedValue === "false" ? false : null
                );
              }
            }}
          >
            <MenuItem value="true">Постоји микрофилм</MenuItem>
            <MenuItem value="false">Не постоји микрофилм</MenuItem>
          </Select>
        </FormControl>

        <CustomSelectInput
          values={bibliographicTypeLabels}
          label="Врста библиографске грађе"
          onChange={(value) => handleSearchChange("unitType", value)}
          width="20.5%"
          multiple={true}
        />
        <DatePicker
          label="Датум регистрације"
          openTo="year"
          format="YYYY-MM"
          views={["year", "month"]}
          yearsOrder="desc"
          sx={{ width: "20%" }}
          maxDate={currentYear}
          onChange={handleDateChange}
          slotProps={{ field: { clearable: true } }}
        />
        <Button variant="contained" onClick={performSearch}>
          <SearchIcon />
        </Button>
      </Stack>
    </>
  );
};

export default CentralRegistryTableHeader;
