import {toast} from "react-toastify";
import {MAX_FILE_SIZE} from "../common/constants/common.constants";

export const validateFileFormat = (acceptedFiles: any[], maxFiles: number, allowedFormats: string[]) => {
    if (acceptedFiles.length > maxFiles) {
        toast.error(`Не можете приложити више ${maxFiles} од докумената`);
        throw new Error(`Cannot upload ${acceptedFiles.length} number of files`);
    }

    for (let i = 0; i < acceptedFiles.length; i++) {
        if (acceptedFiles[i].size > MAX_FILE_SIZE) {
            toast.error("Приложени документ је превелик");
            throw new Error("File is to large");
        }
        if (allowedFormats.length > 0 && !allowedFormats.includes(acceptedFiles[i].type) && !allowedFormats.includes(acceptedFiles[i].name.split('.').pop())) {
            toast.error("Можете убацити само дозвољене формате");
            throw new Error('Invalid file type provided');
        }
    }
};

export function uploadFile(
    alreadyUploaded:File[],
    acceptedFiles: any,
    handleFileChange: (files: File[]) => void,
    maxFiles: number,
    allowedFormats: string[]
) {
    if (!Array.isArray(alreadyUploaded) || !Array.isArray(acceptedFiles)) {
        throw new Error('Both arguments must be arrays');
    }
    // @ts-ignore
    const all =[...new Set([...alreadyUploaded, ...acceptedFiles])];
    validateFileFormat(all, maxFiles, allowedFormats);

    handleFileChange(all);
}