import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import errorImg from "../../assets/error-404.png";
import { useNavigate } from "react-router";
import { HOME_PAGE } from "../../common/constants/routes.constants";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        py: "80px",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 6,
          }}
        >
          <Box
            alt="Not found"
            component="img"
            src={errorImg}
            sx={{
              height: "auto",
              maxWidth: "100%",
              width: 400,
            }}
          />
        </Box>
        <Typography align="center" variant="h4">
          Страница коју сте тражили није пронађена.
        </Typography>
        <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
          Или сте покушали да приступите рути која не постоји или сте овде дошли грешком.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Button onClick={() => navigate(HOME_PAGE)}>Bратите се назад</Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
