import footerLogo from "../../assets/headerLogo.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__image">
        <img src={footerLogo} alt="footer-logo" />
      </div>
    </footer>
  );
};
export default Footer;
