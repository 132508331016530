import { getRequest, patchRequest } from "./utilities/http";
import { USER, USERS } from "../common/constants/routes.constants";

export function fetchUsers(
  page?: number,
  perPage?: number,
  sortBy?: string,
  sortOrder?: "asc" | "desc" | string | undefined | null
) {
  const queryParams = new URLSearchParams({
    page: String(page || 1),
    perPage: String(perPage || 20),
  });

  if (sortBy && sortOrder) {
    queryParams.append("sortBy", sortBy);
    queryParams.append("sortOrder", sortOrder);
  }

  const url = `/users?${queryParams.toString()}`;
  return getRequest(url);
}

export function fetchUserDetails(userID: number) {
  const url = `${USERS}/${userID}`;
  return getRequest(url);
}

export function editUserDetail(data: any) {
  const url = USER;
  return patchRequest(url, data);
}
