import CustomModal from "../Modal/CustomModal";
import { Button, Stack, TableCell, TableRow, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import BasicTable from "../BasicTable/BasicTable";
import { USER_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import {
  IInstitutionInfo,
  IInstitutionsDetails,
} from "../../common/interfaces/institutions.interface";
import editIcon from "../../assets/icons/edit-icon.png";
import { useAuthStore } from "../../store/authStore";
import { UserRole } from "../../common/enums/common.enums";
import ModalActions from "../Modal/ModalActions";
import { editInstitutionDetails } from "../../api/institutions";
import { createToastNotification } from "../../utilities/helpers";
import { useFilterStore } from "../../store/filtersStore";

const InstitutionModal: FC<{
  institutionDetails: IInstitutionsDetails | undefined;
  handleClose: () => void;
  institutionPaginationMetadata: {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
  };
  setInstitutionPaginationMetadata: React.Dispatch<
    React.SetStateAction<{
      currentPage: number;
      totalPages: number;
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
    }>
  >;
}> = ({
  institutionDetails,
  handleClose,
  institutionPaginationMetadata,
  setInstitutionPaginationMetadata,
}) => {
  const { authState } = useAuthStore();
  const [isEdit, setIsEdit] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<IInstitutionInfo>({
    institutionalName: institutionDetails?.institutionalName ?? "",
    institutionalCity: institutionDetails?.institutionalCity ?? "",
    institutionalStreet: institutionDetails?.institutionalStreet ?? "",
    institutionalStreetNumber: institutionDetails?.institutionalStreetNumber ?? "",
    institutionalPostalCode: institutionDetails?.institutionalPostalCode ?? "",
    institutionalPhone: institutionDetails?.institutionalPhone ?? "",
  });
  const { getInstitutions } = useFilterStore();
  if (!institutionDetails) return null;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const userRows = institutionDetails.users?.users.map((user) => (
    <TableRow key={user.userID} className="nonclickable-style">
      <TableCell>{user.userID}</TableCell>
      <TableCell>
        {user.firstName} {user.lastName}
      </TableCell>
      <TableCell>{user.email}</TableCell>
    </TableRow>
  ));

  const handleEditClick = () => {
    setIsEdit(true);
    setShowButton(false);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await editInstitutionDetails(institutionDetails.institutionalID, formValues);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    } finally {
      setIsEdit(false);
      handleClose();
      getInstitutions();
    }
    setIsSubmitting(false);
  };

  return (
    <CustomModal
      open={true}
      title="Детаљи о институцији"
      handleClose={handleClose}
      fullWidth
      maxWidth="xl"
    >
      <Stack>
        {authState.role === UserRole.MasterAdmin && showButton && (
          <Stack alignItems="flex-end" mb={2}>
            <Button
              variant="outlined"
              size="small"
              endIcon={<img src={editIcon} alt="edit-icon" />}
              onClick={handleEditClick}
            >
              Измени
            </Button>
          </Stack>
        )}
        <Stack direction="row" spacing={3} mb={3}>
          <Stack spacing={2} flex={1}>
            <TextField
              fullWidth
              label="Назив"
              name="institutionalName"
              value={formValues.institutionalName ?? ""}
              disabled={!isEdit}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Адреса"
              name="institutionalCity"
              value={formValues.institutionalCity ?? ""}
              disabled={!isEdit}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Улица"
              name="institutionalStreet"
              value={formValues.institutionalStreet ?? ""}
              disabled={!isEdit}
              onChange={handleChange}
            />
          </Stack>
          <Stack spacing={2} flex={1}>
            <TextField
              fullWidth
              label="Број телефона"
              name="institutionalPhone"
              value={formValues.institutionalPhone ?? ""}
              disabled={!isEdit}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Број улице"
              name="institutionalStreetNumber"
              value={formValues.institutionalStreetNumber ?? ""}
              disabled={!isEdit}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Поштански број"
              name="institutionalPostalCode"
              value={formValues.institutionalPostalCode ?? ""}
              disabled={!isEdit}
              onChange={handleChange}
            />
          </Stack>
        </Stack>
      </Stack>
      <BasicTable
        loader={false}
        headings={USER_TABLE_HEADINGS}
        metadata={institutionPaginationMetadata}
        setMetadata={setInstitutionPaginationMetadata}
      >
        {userRows && userRows.length > 0 ? (
          userRows
        ) : (
          <Typography mt={1} ml={1} variant="body1">
            Нема корисника за приказ!
          </Typography>
        )}
      </BasicTable>

      {isEdit && (
        <ModalActions
          buttonAction={handleSubmit}
          disabled={isSubmitting}
          buttonText="Сачувај"
          cancelBtnText="Назад"
          cancelAction={() => {
            setIsEdit(false);
            setShowButton(true);
          }}
        />
      )}
    </CustomModal>
  );
};

export default InstitutionModal;
