import BasicTable from "../../components/BasicTable/BasicTable";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { INSTITUTIONS_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import { initialMetadataState } from "../../common/constants/common.constants";
import { useFilterStore } from "../../store/filtersStore";
import InstitutionModal from "../../components/InstitutionModal/InstitutionModal";
import { fetchInstitutionDetails } from "../../api/institutions";
import { createToastNotification } from "../../utilities/helpers";
import { IInstitutionsDetails } from "../../common/interfaces/institutions.interface";

const Institutions = () => {
  const [institutionsMetadata, setInstitutionsMetadata] = useState(initialMetadataState);
  const [showInstitutionModal, setShowInstitutionModal] = useState(false);
  const { institutions, getInstitutions } = useFilterStore();
  const [institutionDetails, setInstitutionDetails] = useState<IInstitutionsDetails>();
  const [institutionPaginationMetadata, setInstitutionPaginationMetadata] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
  });
  const getInstitutionDetails = async (institutionID: number) => {
    try {
      const response = await fetchInstitutionDetails(
        institutionID,
        institutionPaginationMetadata.currentPage,
        institutionPaginationMetadata.itemsPerPage
      );
      setInstitutionDetails(response);
      setInstitutionPaginationMetadata({
        currentPage: response.users.currentPage,
        itemsPerPage: response.users.itemsPerPage,
        itemCount: response.users.itemCount,
        totalItems: response.users.totalItems,
        totalPages: response.users.totalPages,
      });
      setShowInstitutionModal(true);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (institutionDetails?.institutionalID) {
      getInstitutionDetails(institutionDetails.institutionalID);
    }
    // eslint-disable-next-line
  }, [institutionPaginationMetadata.currentPage, institutionPaginationMetadata.totalItems]);

  useEffect(() => {
    getInstitutions();
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="page-table">
      <BasicTable
        loader={false}
        headings={INSTITUTIONS_TABLE_HEADINGS}
        metadata={institutionsMetadata}
        setMetadata={setInstitutionsMetadata}
        noPagination
      >
        {institutions.length === 0 ? (
          <Typography>Нема институција за приказ!</Typography>
        ) : (
          institutions.map((institution, index) => (
            <TableRow key={index} onClick={() => getInstitutionDetails(institution.institutionID)}>
              <TableCell width="10%">{institution.institutionID}</TableCell>
              <TableCell>{institution.institutionName}</TableCell>
            </TableRow>
          ))
        )}
      </BasicTable>
      {showInstitutionModal && (
        <InstitutionModal
          institutionDetails={institutionDetails}
          handleClose={() => setShowInstitutionModal(false)}
          setInstitutionPaginationMetadata={setInstitutionPaginationMetadata}
          institutionPaginationMetadata={institutionPaginationMetadata}
        />
      )}
    </Box>
  );
};

export default Institutions;
