import React, { FC, ReactNode, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import "./CustomModal.scss";
interface ModalProps {
  open: boolean;
  handleClose: () => void;
  title?: string;
  children: ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
}

const CustomModal: FC<ModalProps> = ({
  open,
  handleClose,
  title,
  children,
  maxWidth,
  fullWidth,
}) => {
  useEffect(() => {
    document.documentElement.style.overflow = open ? "hidden" : "auto";
    return () => {
      document.documentElement.style.overflow = "auto";
    };
  }, [open]);

  return (
    <Dialog
      fullWidth={fullWidth}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      keepMounted
      maxWidth={maxWidth}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" className="modal">
        <DialogTitle className="modal__title">{title}</DialogTitle>
        <IconButton color="inherit" onClick={handleClose}>
          <SvgIcon>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent id="description-dialog">
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
