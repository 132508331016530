import React from "react";
import { TableCell, TableRow } from "@mui/material";
import "./NewsTableRow.scss";
import { IPublishedNews } from "../../../common/interfaces/news.interface";
import { formatDateFromApi } from "../../../utilities/news-helper";

const PublishedNewsTableRow: React.FC<{
  item: IPublishedNews;
  index: number;
  handleRowClick: (id: number) => void;
}> = ({ item, index, handleRowClick }) => {
  return (
    <TableRow
      key={index}
      onClick={() => handleRowClick(item.newsID)}
      className="table-row generic-table-row"
    >
      <TableCell>{item.title}</TableCell>
      <TableCell>{item.publishedDate ? formatDateFromApi(item.publishedDate) : "/"}</TableCell>
      <TableCell width="20%" className="table-row__featured-image-container">
        {item.imageURL ? (
          <img alt={item.title} src={item.imageURL || ""} className="table-row__featured-image" />
        ) : (
          "/"
        )}
      </TableCell>
    </TableRow>
  );
};

export default PublishedNewsTableRow;
