import {
  BibliographicUnitType,
  RestCodes,
  SignificanceLevels,
  Status,
  UserRole,
} from "../common/enums/common.enums";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

export type MessageType = "success" | "info" | "error";

export const createToastNotification = (
  messageType: MessageType,
  message: string[] | string,
  error?: any
) => {
  const messageText = message;

  if (messageType === "success") {
    return toast.success(messageText);
  } else if (messageType === "info") {
    return toast.info(messageText);
  } else {
    if (error?.response?.status === RestCodes.Unauthorized) {
      return;
    }
    toast.error(`${messageText}`);
  }
};

export const decodeUserFromToken = (token: string) => {
  try {
    const decodedToken: any = jwtDecode(token);
    return decodedToken || null;
  } catch (error) {
    // console.log("Decoding user from token failed", error);
    return undefined;
  }
};

export const getSignificanceLevelLabel = (level: string) => {
  switch (level) {
    case SignificanceLevels.Exceptional:
      return "Изузетан ниво значаја";
    case SignificanceLevels.Great:
      return "Велики ниво значаја";
    case SignificanceLevels.BasicLevel:
      return "Основни ниво значаја";
    default:
      return "Непознат ниво значаја";
  }
};

export const getStatusLabel = (status: string) => {
  switch (status) {
    case Status.Declared:
      return "Проглашен";
    case Status.Recorded:
      return "Евидентиран";
    case Status.Rejected:
      return "Одбијен";
    default:
      return status;
  }
};

export const getUnitType = (unit: string) => {
  switch (unit) {
    case BibliographicUnitType.PrintedBook:
      return "Штампана";
    case BibliographicUnitType.ManuscriptBook:
      return "Рукописна";
    case BibliographicUnitType.LegaciesAndLibraryCollections:
      return "Легати и библиотерске целине";
    default:
      return unit;
  }
};

export function mapLabels<T>(
  items: T[],
  labelFunction: (item: T) => string
): { value: T; label: string }[] {
  return items.map((item) => ({
    value: item,
    label: labelFunction(item),
  }));
}

export const formatDate = (date: string) => {
  const dateString = new Date(date);
  const day = dateString.getDate().toString().padStart(2, "0");
  const month = (dateString.getMonth() + 1).toString().padStart(2, "0");
  const year = dateString.getFullYear();

  return `${year}-${month}-${day}`;
};

export const getRoleLabel = (role: string) => {
  switch (role) {
    case UserRole.MasterAdmin:
      return "Мастер администратор";
    case UserRole.Admin:
      return "Администратор";
    case UserRole.ProtectiveLibrary:
      return "Библиотека заштитарка";
    default:
      return "Непозната улога";
  }
};
