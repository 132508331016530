import DetailsCardRow from "../BasicDetailsCardComponent/DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { ISpecimen } from "../../../common/interfaces/bibliographic-unit.interface";
import { Card, CardContent, CardHeader } from "@mui/material";

const SpecimenConservationInfo: FC<{ bibliographicUnit: ISpecimen }> = ({ bibliographicUnit }) => {
  return (
    <Card>
      <CardHeader title="Конзервација" />
      <CardContent>
        <DetailsCardRow
          title="Година извршења"
          content={bibliographicUnit.conservation?.bookBlockConservation?.yearOfProtection}
        />
        <DetailsCardRow
          title="Институцијакоја је извршила"
          content={bibliographicUnit.conservation?.bookBlockConservation?.protectionInstitution}
        />
        <DetailsCardRow
          title="Конзервација извршена"
          content={bibliographicUnit.conservation?.bookBlockConservation?.isComplete ? "Да" : "Не"}
        />
        <DetailsCardRow
          title="Напомена"
          content={bibliographicUnit.conservation?.bookBlockConservation?.note}
        />
      </CardContent>
    </Card>
  );
};
export default SpecimenConservationInfo;
