import React, { FC } from "react";
import { Stack, Button } from "@mui/material";
import SearchInput from "../SearchInput/SearchInput";
import DateRangeFilter from "../DateRangeFilter/DateRangeFilter";
import SearchIcon from "@mui/icons-material/Search";
import { IHomeFilter } from "../../common/interfaces/home.interface";
import "./HomeTableHeader.scss";
import { useFilterStore } from "../../store/filtersStore";
import CustomAutoSelectInput from "../CustomAutocompleteInput/CustomAutocompleteInput";
import CustomSelectInput from "../CustomSelectInputs/CustomSelectInput";
import { getSignificanceLevelLabel, getStatusLabel, mapLabels } from "../../utilities/helpers";
import { fetchAuthors, fetchOtherResponsibilities } from "../../api/filters";
import { fetchInstitutions } from "../../api/institutions";
import { LetterTranslator } from "../../utilities/letter-translator";

interface TableHeaderProps {
  getCulturalProperties: () => Promise<void>;
  setSearchFilters: React.Dispatch<React.SetStateAction<IHomeFilter>>;
  showBtn?: boolean;
  isCentralRegistry?: boolean;
  setIsBibliographicUnitSelected?: React.Dispatch<React.SetStateAction<boolean>>;
}
const HomeTableHeader: FC<TableHeaderProps> = ({
  getCulturalProperties,
  setSearchFilters,
  showBtn,
  isCentralRegistry,
  setIsBibliographicUnitSelected,
}) => {
  const {
    holders,
    institutions,
    authors,
    otherResponsibilities,
    yearsOfProclamation,
    proclaimedCulturalAssets,
    significanceLevel,
    statuses,
  } = useFilterStore();

  const allSignificanceLevels = significanceLevel.significanceLevels || [];
  const allStatuses = statuses.recordStatus || [];

  const allSignificanceLevelsLabels = mapLabels(allSignificanceLevels, getSignificanceLevelLabel);
  const allStatusLabels = mapLabels(allStatuses, getStatusLabel);

  const handleSearchChange = (field: string, value: any) => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
    if (field === "itemType") {
      const isBibliographicUnitSelected = value.includes("bibliographicUnit");
      const isSpecimenSelected = value.includes("specimen");

      if (setIsBibliographicUnitSelected) {
        setIsBibliographicUnitSelected(isBibliographicUnitSelected && !isSpecimenSelected);
      }
    }
  };

  const unitTypeOptions = [
    { value: "bibliographicUnit", label: "Библиографски" },
    { value: "specimen", label: "Примерак" },
  ];

  return (
    <>
      <Stack className="filter-section" alignItems="center" spacing={1} direction="row">
        <SearchInput
          icon
          setSearchValue={(value) => handleSearchChange("title", value)}
          searchTitle="Претражи по наслову"
        />
        <CustomAutoSelectInput
          setSearchValue={(value) => handleSearchChange("author", value)}
          searchTitle="Претражи по аутору"
          optionsData={authors}
          getOptionLabel={(author) => author.authorName}
          getOptionId={(author) => author.authorID}
          fetchOptions={async (input) => {
            const searchLatin = LetterTranslator.cyr2lat(input);
            const searchCyrillic = LetterTranslator.lat2cyr(input);

            const response = await fetchAuthors(1, 50, input);
            return response.authors.filter((author: { authorName: string }) => {
              const name = author.authorName.toLowerCase();
              return (
                name.includes(input.toLowerCase()) ||
                name.includes(searchLatin.toLowerCase()) ||
                name.includes(searchCyrillic.toLowerCase())
              );
            });
          }}
        />
        <CustomAutoSelectInput
          setSearchValue={(value) => handleSearchChange("otherResponsibilities", value)}
          searchTitle="Претражи по одговорностима"
          optionsData={otherResponsibilities}
          getOptionLabel={(resp) => resp.name}
          getOptionId={(resp) => resp.id}
          fetchOptions={async (input) => {
            const searchLatin = LetterTranslator.cyr2lat(input);
            const searchCyrillic = LetterTranslator.lat2cyr(input);

            const response = await fetchOtherResponsibilities(1, 50, input);
            return response.responsibilities.filter((resp: { name: string }) => {
              const name = resp.name.toLowerCase();
              return (
                name.includes(input.toLowerCase()) ||
                name.includes(searchLatin.toLowerCase()) ||
                name.includes(searchCyrillic.toLowerCase())
              );
            });
          }}
        />
        <SearchInput
          icon
          setSearchValue={(value) => handleSearchChange("placeOfIssue", value)}
          searchTitle="Претражи по месту издања"
        />
      </Stack>
      <Stack className="filter-section" direction="row" spacing={1}>
        <CustomSelectInput
          values={allSignificanceLevelsLabels}
          label="Ниво значаја"
          onChange={(value) => handleSearchChange("significanceLevel", value)}
          width="30%"
          multiple={true}
        />
        <CustomAutoSelectInput
          width="30%"
          setSearchValue={(value) => handleSearchChange("yearOfProclamation", value.map(String))}
          optionsData={yearsOfProclamation}
          getOptionLabel={(option) => (option ? option.toString() : "")}
          getOptionId={(option) => option}
          searchTitle="Година проглашења"
        />
        <CustomAutoSelectInput
          width="30%"
          setSearchValue={(value) => handleSearchChange("holder", value)}
          optionsData={holders}
          getOptionLabel={(option) => option.holderName}
          getOptionId={(option) => option.holderID}
          searchTitle="Сопственик"
        />
        <DateRangeFilter
          onChange={({ field, value }) => handleSearchChange(field, value)}
          fieldNames={{ from: "yearOfPublicationFrom", to: "yearOfPublicationTo" }}
          labels={{ from: "Година издања (од)", to: "Година издања (до)" }}
        />
      </Stack>
      <Stack className="filter-section" direction="row" spacing={1} mb={isCentralRegistry ? 0 : 2}>
        {!isCentralRegistry && (
          <CustomSelectInput
            values={allStatusLabels}
            label="Статус"
            onChange={(value) => handleSearchChange("status", value)}
            width="24.5%"
            multiple={true}
          />
        )}
        {isCentralRegistry && (
          <CustomSelectInput
            values={allStatusLabels}
            label="Статус"
            onChange={(value) => handleSearchChange("recordStatus", value)}
            width="17%"
            multiple={true}
          />
        )}
        <CustomAutoSelectInput
          width="33%"
          setSearchValue={(value) => handleSearchChange("institution", value)}
          optionsData={institutions}
          getOptionLabel={(option) => option.institutionName}
          getOptionId={(option) => option.institutionID}
          searchTitle="Институција која врши упис"
          fetchOptions={async (input) => {
            const searchLatin = LetterTranslator.cyr2lat(input);
            const searchCyrillic = LetterTranslator.lat2cyr(input);

            const response = await fetchInstitutions(input);
            return response.filter((institution: { institutionName: string }) => {
              const name = institution.institutionName.toLowerCase();
              return (
                name.includes(input.toLowerCase()) ||
                name.includes(searchLatin.toLowerCase()) ||
                name.includes(searchCyrillic.toLowerCase())
              );
            });
          }}
        />
        <CustomAutoSelectInput
          width="33%"
          setSearchValue={(value) => handleSearchChange("lists", value)}
          optionsData={proclaimedCulturalAssets}
          getOptionLabel={(option) => option.name}
          getOptionId={(option) => option.id}
          searchTitle="Листа проглашених културних добара"
        />
        {showBtn && (
          <Button
            startIcon={<SearchIcon />}
            size="large"
            variant="contained"
            className="filter-section__search-button"
            onClick={getCulturalProperties}
          >
            Претражи
          </Button>
        )}
        {isCentralRegistry && (
          <CustomSelectInput
            values={unitTypeOptions}
            label="Тип записа"
            onChange={(value) => handleSearchChange("itemType", value)}
            width="17%"
            multiple={true}
          />
        )}
      </Stack>
    </>
  );
};

export default HomeTableHeader;
