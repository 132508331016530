import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Header from "./components/Header/Header";
import NavTabs from "./components/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { useAuthStore } from "./store/authStore";
import { useNavigate } from "react-router";
import { createToastNotification, decodeUserFromToken } from "./utilities/helpers";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  CENTRAL_REGISTRY,
  HOLDER_DETAILS,
  HOLDERS,
  ADD_NEWS,
  ADMIN_NEWS_DETAILS,
  ALL_NEWS,
  EDIT_NEWS,
  HOME_PAGE,
  LIST_OF_CULTURAL_ASSETS,
  LIST_OF_INSTITUTIONS,
  LOGIN_PAGE,
  NEWS,
  USERS,
  NEWS_DETAILS,
} from "./common/constants/routes.constants";
import "./layout.scss";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer/Footer";
import slider from "./assets/library2.jpg";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { ConfirmDeleteNewsDialog } from "./components/News/ConfirmDeleteNewsDialog/ConfirmDeleteNewsDialog";
import { deleteNews } from "./api/news";
import useModalStore from "./store/useModalsStore";
import { UserRole } from "./common/enums/common.enums";
import useLoadingStore from "./store/LoadingStore";
import Loader from "./components/Loader/Loader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Layout = () => {
  const location = useLocation();
  const homePage = useMemo(() => location.pathname === HOME_PAGE, [location.pathname]);
  const newsPage = useMemo(() => location.pathname === NEWS, [location.pathname]);
  const basicNewsDetailsPage = useMemo(
    () => location.pathname.includes(NEWS_DETAILS),
    [location.pathname]
  );
  const { authState, setAuthState } = useAuthStore();
  const { isLoading, setLoading } = useLoadingStore();
  const navigate = useNavigate();
  const { openModal, openCreateListModal } = useModalStore();
  const centralRegistryPage = location.pathname === CENTRAL_REGISTRY;
  const listOfProclaimedAssetsPage = location.pathname === LIST_OF_CULTURAL_ASSETS;
  const isDetailsPage = location.pathname.includes("/details");
  const allNewsPage = location.pathname === ALL_NEWS;
  const newsDetailsPage = location.pathname.includes(`${ADMIN_NEWS_DETAILS}/`);
  const holderDetailsPage = location.pathname.includes(HOLDER_DETAILS);
  const addNewsPage = location.pathname.includes(ADD_NEWS);
  const editNewsPage = location.pathname.includes(`${EDIT_NEWS}/`);
  const isMasterAdmin = authState.role === UserRole.MasterAdmin;
  const { id: newsID } = useParams();

  const getPageTitle = useMemo(() => {
    switch (location.pathname) {
      case HOME_PAGE:
        return "";
      case CENTRAL_REGISTRY:
        return "Претрага централног регистра";
      case LIST_OF_INSTITUTIONS:
        return "Институције које врше упис у регистар";
      case NEWS:
      case ALL_NEWS:
        return "Вести";
      case USERS:
        return "Листа корисника";
      case ADD_NEWS:
        return "Креирање вести";
      case LIST_OF_CULTURAL_ASSETS:
        return "Листа проглашених културних добара";
      case HOLDERS:
        return "Листа сопственика";
      default:
        if (location.pathname.includes(`${ADMIN_NEWS_DETAILS}/`)) {
          return "Детаљи о вести";
        } else if (location.pathname.includes(`${EDIT_NEWS}/`)) {
          return "Измена вести";
        } else if (location.pathname.includes(HOLDER_DETAILS)) {
          return "Детаљи о сопственику";
        }
        return "";
    }
  }, [location.pathname]);

  const getLocation = useMemo(() => {
    if (newsDetailsPage || addNewsPage) {
      return ALL_NEWS;
    } else if (editNewsPage) {
      return ADMIN_NEWS_DETAILS + location.pathname.replace(EDIT_NEWS, "");
    } else if (holderDetailsPage) {
      return HOLDERS;
    }
    return "";
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    checkAuth();
    // eslint-disable-next-line
  }, [authState.accessToken, setAuthState, navigate]);

  const checkAuth = () => {
    if (authState.accessToken) {
      const { email, role, firstName, lastName } = decodeUserFromToken(authState.accessToken);
      setAuthState({ ...authState, firstName, lastName, email, role });
      setLoading(false);

      if (
        ![UserRole.MasterAdmin].includes(role) &&
        // eslint-disable-next-line
        (window.location.pathname === USERS ||
          window.location.pathname.startsWith("/asset-details/") ||
          window.location.pathname.startsWith(HOLDER_DETAILS) ||
          window.location.pathname === LIST_OF_CULTURAL_ASSETS ||
          window.location.pathname === HOLDERS)
      ) {
        navigate("*");
      }
    } else {
      if (!homePage && !newsPage && !basicNewsDetailsPage) {
        navigate(LOGIN_PAGE);
      }
    }
  };

  const [confirmNewsDeleteDialogIsOpened, setConfirmNewsDeleteDialogIsOpened] = useState(false);

  return (
    <Box className="layout">
      <Header />
      {authState.accessToken && <NavTabs />}
      {homePage && (
        <Stack position="relative">
          <img src={slider} alt="slider-image" className="layout__header-img" />
          <Stack
            position="absolute"
            top="18%"
            left="15%"
            right="15%"
            textAlign="center"
            spacing={3}
            fontWeight={500}
          >
            <h1>Централни регистар старе и ретке библиотечке грађе</h1>
            <p className="layout__home-image-text">
              На основу <i>Закона о културном наслеђу</i>, Народна библиотека Србије, под окриљем
              Министарства културе РС, као поверени јој посао, води
              <h3>Централни регистар старе и ретке библиотечке грађе </h3> за територију Републике
              Србије. На основу Закона и критеријума који су одређени законом, стара и ретка
              библиотечка грађа представља покретно културно добро. Регистар представља евиденцију
              категорисане старе и ретке библиотечке грађе и старе и ретке библотечке грађе под
              претходном заштитом на територији Републике Србије.
            </p>
            <p className="layout__home-image-text">
              Пословима од општег интереса у заштити старе и ретке библиотечке грађе, осим Народне
              библитеке Србије, значајно доприносе и колеге из Библиотеке Матице српске, свих
              матичних библиотека и библиотека које су територијално надлежне установе заштите,
              развијајући на тај начин систем заштите културних добара у Републици Србији. Сви
              централни регистри културних добара су јавни и омогућавају, како стручној, научној,
              тако и широј јавности, увид у стање културног наслеђа.
            </p>
          </Stack>
        </Stack>
      )}
      <Box className={`layout__children ${isDetailsPage ? "no-title" : ""}`}>
        <Stack direction="row" justifyContent="space-between" className="layout__children--title">
          <div>
            {holderDetailsPage || newsDetailsPage || addNewsPage || editNewsPage ? (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="basic-unit-details-header__navigation"
                  onClick={() => {
                    navigate(getLocation);
                  }}
                >
                  <ArrowBackIcon sx={{ height: "20px", mr: 1 }} />
                  Врати се
                </Stack>
                <Typography variant="h4">{getPageTitle}</Typography>
              </>
            ) : (
              <Typography variant="h4">{getPageTitle}</Typography>
            )}
          </div>

          {centralRegistryPage && authState.role !== UserRole.ProtectiveLibrary && (
            <Button variant="contained" startIcon={<AddIcon />} onClick={openModal}>
              Додај нову библиографску јединицу
            </Button>
          )}
          {listOfProclaimedAssetsPage && (
            <Button variant="contained" startIcon={<AddIcon />} onClick={openCreateListModal}>
              Креирај листу
            </Button>
          )}
          {allNewsPage && isMasterAdmin && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                navigate(ADD_NEWS);
              }}
            >
              Додај вест
            </Button>
          )}
          {newsDetailsPage && isMasterAdmin && (
            <>
              <ConfirmDeleteNewsDialog
                open={confirmNewsDeleteDialogIsOpened}
                confirm={async (deletionConfirmed) => {
                  if (deletionConfirmed) {
                    try {
                      await deleteNews(Number(newsID));
                      createToastNotification("success", "Вест је обрисана успешно");
                    } catch (error: any) {
                      createToastNotification("error", error?.response?.data?.message);
                    } finally {
                      navigate(`${ALL_NEWS}`);
                    }
                  }
                  setConfirmNewsDeleteDialogIsOpened(false);
                }}
              />
              <div>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    navigate(`${EDIT_NEWS}/${newsID}`);
                  }}
                >
                  Измени вест
                </Button>
                <Button
                  variant="contained"
                  startIcon={<GridDeleteIcon />}
                  onClick={() => {
                    setConfirmNewsDeleteDialogIsOpened(true);
                  }}
                  sx={{
                    marginLeft: 3,
                    backgroundColor: "red",
                    "&:hover": {
                      backgroundColor: "darkred",
                    },
                  }}
                >
                  Обриши вест
                </Button>
              </div>
            </>
          )}
        </Stack>
        <Outlet />
      </Box>

      {homePage && !authState.accessToken && <Footer />}
      {isLoading && <Loader />}
    </Box>
  );
};

export default Layout;
