import * as Yup from "yup";
export const ChangePasswordValidationSchema = Yup.object({
  password: Yup.string().matches(
    /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
    "Лозинка мора имати минимум 8 карактера, једно велико слово и специјалан карактер."
  ),
  confirmedPassword: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
      "Лозинка мора имати минимум 8 карактера, једно велико слово и специјалан карактер."
    )
    .oneOf([Yup.ref("password")], "Лозинке се не подударају")
    .required("Поновљена лозинка је обавезно поље"),
});
