import {FC} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";

export const ConfirmDeleteNewsDialog: FC<{
    open: boolean,
    confirm: (confirm: boolean) => void,
}> = ({open, confirm}) => {

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Брисање вести
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Да ли сте сигурни да желите да обришете вест?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    confirm(false)
                }}>Откажи</Button>
                <Button onClick={() => {
                    confirm(true)
                }}
                        sx={{
                            '&:hover': {
                                backgroundColor: "red",
                                color: "white"
                            }
                        }}
                >
                    Потврди брисање
                </Button>
            </DialogActions>
        </Dialog>
    );
}