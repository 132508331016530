import { postRequest } from "./utilities/http";
import { ChangePasswordDTO, LoginDto } from "../common/dtos/common.dto";
import { CHANGE_PASSWORD, LOGIN ,LOGOUT} from "./utilities/api.urls";


export async function login(loginInfo: LoginDto): Promise<any> {
  return postRequest(LOGIN, loginInfo);
}

export async function changePassword(changePasswordInfo: ChangePasswordDTO): Promise<any> {
  return postRequest(CHANGE_PASSWORD, changePasswordInfo);
}

export async function logout(): Promise<any> {
  return postRequest(LOGOUT, {});
}
