import TextField from "@mui/material/TextField";
import { InputAdornment, Typography } from "@mui/material";
import PasswordEyeToggle from "../../../components/PasswordEyeToggle/PasswordEyeToggle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import React, { FC, useMemo, useState } from "react";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { useFormik } from "formik";
import { ChangePasswordValidationSchema } from "../../../common/validation-schemas/change-password.schema";
import { ChangePasswordDTO } from "../../../common/dtos/common.dto";
import { createToastNotification } from "../../../utilities/helpers";
import { IUsers } from "../../../common/interfaces/users.interface";
import { changePassword } from "../../../api/login";

//TODO The component can be reusable if there is time, make it reusable

const initialValues = {
  password: EMPTY_STRING,
  confirmedPassword: EMPTY_STRING,
};
const ChangePasswordForUser: FC<{ userDetails: IUsers | undefined; handleClose: () => void }> = ({
  userDetails,
  handleClose,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: () => {
      handleChangePassword();
      handleClose();
    },
  });

  const isFormValid = useMemo(() => {
    return formik.dirty && formik.isValid;
  }, [formik.dirty, formik.isValid]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmedPassword = () => setShowConfirmedPassword((show) => !show);

  const handleChangePassword = async () => {
    const changePasswordDto: ChangePasswordDTO = {
      email: userDetails?.email,
      password: formik.values.password,
      confirmedPassword: formik.values.confirmedPassword,
    };
    setIsSubmitting(true);
    try {
      await changePassword(changePasswordDto);
      createToastNotification("success", "Успешно сте изменили лозинку!");
    } catch (error: any) {
      createToastNotification("error", error.response?.data?.message);
    } finally {
      formik.resetForm();
      setIsSubmitting(false);
    }
  };
  return (
    <Stack border="1px solid #000" borderRadius={1} p={1.5}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Typography variant="h6" mb={1}>
          Промени лозинку
        </Typography>
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Нова лозинка"
          margin="normal"
          variant="outlined"
          onChange={formik.handleChange}
          type={showPassword ? "text" : "password"}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PasswordEyeToggle
                  handleClickShowPassword={handleClickShowPassword}
                  showPassword={showPassword}
                />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          id="confirmedPassword"
          name="confirmedPassword"
          label="Потврда нове лозинке"
          type={showConfirmedPassword ? "text" : "password"}
          margin="normal"
          variant="outlined"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmedPassword}
          error={formik.touched.confirmedPassword && Boolean(formik.errors.confirmedPassword)}
          helperText={formik.touched.confirmedPassword && formik.errors.confirmedPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PasswordEyeToggle
                  handleClickShowPassword={handleClickShowConfirmedPassword}
                  showPassword={showConfirmedPassword}
                />
              </InputAdornment>
            ),
          }}
        />
        <Stack alignItems="flex-end">
          <Button disabled={!isFormValid || isSubmitting} type="submit" variant="contained">
            Сачувај
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
export default ChangePasswordForUser;
