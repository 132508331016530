import { Box, Typography } from "@mui/material";
import BasicTable from "../../components/BasicTable/BasicTable";
import { CULTURAL_ASSETS_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import React, { useEffect, useState } from "react";
import ProclaimedCulturalAssetsTableRow from "./ProclaimedCulturalAssetsTableRow/ProclaimedCulturalAssetsTableRow";
import { fetchProclaimedCulturalAssets } from "../../api/filters";
import { createToastNotification } from "../../utilities/helpers";
import { IProclaimedCulturalAssets } from "../../common/interfaces/fillter.interfaces";
import useModalStore from "../../store/useModalsStore";
import AddNewListModal from "./AddNewListModal/AddNewListModal";
import { useNavigate } from "react-router";
import SearchInput from "../../components/SearchInput/SearchInput";

const ListOfProclaimedCulturalAssets = () => {
  const { isCreateListModalOpen, closeCreateListModal } = useModalStore();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const [listOfProclaimedCulturalAssetsMetadata, setListOfProclaimedCulturalAssetsMetadata] =
    useState({
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 20,
      totalItems: 0,
      totalPages: 0,
    });
  const [assets, setAssets] = useState<IProclaimedCulturalAssets[]>();

  const getProclaimedAssets = async (search = "") => {
    setLoading(true);
    try {
      const response = await fetchProclaimedCulturalAssets(
        search,
        listOfProclaimedCulturalAssetsMetadata.currentPage,
        listOfProclaimedCulturalAssetsMetadata.itemsPerPage
      );
      setAssets(response.lists);
      setListOfProclaimedCulturalAssetsMetadata({
        currentPage: response.currentPage,
        itemsPerPage: response.itemsPerPage,
        itemCount: response.itemCount,
        totalItems: response.totalItems,
        totalPages: response.totalPages,
      });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProclaimedAssets();
    // eslint-disable-next-line
  }, [
    listOfProclaimedCulturalAssetsMetadata.currentPage,
    listOfProclaimedCulturalAssetsMetadata.itemsPerPage,
  ]);

  useEffect(() => {
    if (searchValue) {
      getProclaimedAssets(searchValue);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const handleRowClick = (id: number) => {
    navigate(`/asset-details/${id}`);
  };

  return (
    <Box className="page-table">
      <SearchInput icon setSearchValue={setSearchValue} searchTitle="Претражи..." />
      <BasicTable
        loader={loading}
        headings={CULTURAL_ASSETS_TABLE_HEADINGS}
        metadata={listOfProclaimedCulturalAssetsMetadata}
        setMetadata={setListOfProclaimedCulturalAssetsMetadata}
      >
        {assets && assets.length > 0 ? (
          assets.map((asset, index) => (
            <ProclaimedCulturalAssetsTableRow
              asset={asset}
              index={index}
              key={index}
              handleRowClick={handleRowClick}
            />
          ))
        ) : (
          <Typography mt={1} ml={1}>
            Нема листа за приказ!
          </Typography>
        )}
      </BasicTable>
      {isCreateListModalOpen && (
        <AddNewListModal
          handleClose={() => {
            closeCreateListModal();
            getProclaimedAssets();
          }}
        />
      )}
    </Box>
  );
};
export default ListOfProclaimedCulturalAssets;
