import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface Option {
  id: number;
  name: string;
}

interface FreeSoloProps {
  options: Option[];
  getOptionLabel: (option: string | Option) => string;
  getOptionId: (option: Option) => number;
  onInputChange: (event: React.ChangeEvent<{}>, value: string) => void;
  label: string;
  value?: string | Option | null;
  error?: boolean;
  helperText?: string;
  isOptionEqualToValue?: (option: string | Option, value: string | Option) => boolean;
  required?: boolean;
}

const FreeSoloAutocompleteComponent: React.FC<FreeSoloProps> = ({
  options,
  getOptionLabel,
  onInputChange,
  label,
  value,
  error,
  helperText,
  isOptionEqualToValue,
  required,
}) => (
  <Autocomplete
    freeSolo
    options={options}
    getOptionLabel={getOptionLabel}
    onInputChange={onInputChange}
    value={value}
    isOptionEqualToValue={isOptionEqualToValue}
    renderInput={(params) => (
      <TextField
        required={required}
        {...params}
        label={label}
        error={error}
        helperText={helperText}
      />
    )}
  />
);

export default FreeSoloAutocompleteComponent;
