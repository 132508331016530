import {deleteRequest, getRequest, objectToFormData, patchRequestFormData, postRequestFormData} from "./utilities/http";
import {NewsFormFields} from "../common/interfaces/news.interface";

export const getPublishedNews = async (page: number, perPage: number) => {
    const url = `/public/published-news?page=${page}&perPage=${perPage}`;
    return await getRequest(url);
}

export const getAllNews = async (page: number, perPage: number) => {
    const url = `/news?page=${page}&perPage=${perPage}`;
    return await getRequest(url);
}

export const getPublicNewsByID = async (id: number) => {
    const url = `/public/news/${id}`;
    return await getRequest(url);
}
export const getNewsByID = async (id: number) => {
    const url = `/news/${id}`;
    return await getRequest(url);
}
export const saveNews = async (newsData: NewsFormFields) => {

    const thumbnail = newsData.thumbnail;
    const attachments = newsData.attachments;
    // @ts-ignore
    delete newsData.thumbnail;
    // @ts-ignore
    delete newsData.attachments;

    const formData = objectToFormData(newsData);
    if (thumbnail){
        formData.append('thumbnail', thumbnail, thumbnail.name);
    }
    if (attachments && attachments?.length > 0) {
        attachments.forEach((file) => {
            formData.append('files', file, file.name);
        });
    }

    return await postRequestFormData('/news',formData);
}
export const updateNews = async (newsData: NewsFormFields) => {

    const thumbnail = newsData.thumbnail;
    const attachments = newsData.attachments;
    // @ts-ignore
    delete newsData.thumbnail;
    // @ts-ignore
    delete newsData.attachments;

    const formData = objectToFormData(newsData);
    if (thumbnail){
        formData.append('thumbnail', thumbnail, thumbnail.name);
    }
    if (attachments && attachments?.length > 0) {
        attachments.forEach((file) => {
            formData.append('files', file, file.name);
        });
    }

    return await patchRequestFormData('/news',formData);
}
export const deleteNews = async (id: number) => {
    const url = `/news/${id}`;
    return await deleteRequest(url);
}
