import {
  fetchAuthors,
  fetchHolders,
  fetchInstitutions,
  fetchOtherResponsibilities,
  fetchProclaimedCulturalAssets,
  fetchRecordStatuses,
  fetchSignificanceLevel,
  fetchYearsOfProclamation,
} from "../api/filters";
import { create } from "zustand";
import { createToastNotification } from "../utilities/helpers";
import {
  IAuthors,
  IHolders,
  IInstitutions,
  IOtherResponsibilities,
  IProclaimedCulturalAssets,
  IRecordStatuses,
  ISignificanceLevels,
} from "../common/interfaces/fillter.interfaces";

interface FilterState {
  statuses: IRecordStatuses;
  authors: IAuthors[];
  significanceLevel: ISignificanceLevels;
  holders: IHolders[];
  otherResponsibilities: IOtherResponsibilities[];
  proclaimedCulturalAssets: IProclaimedCulturalAssets[];
  yearsOfProclamation: number[];
  institutions: IInstitutions[];
  getStatuses: () => Promise<void>;
  getAuthors: () => Promise<void>;
  getInstitutions: () => Promise<void>;
  getSignificanceLevels: () => Promise<void>;
  getHolders: () => Promise<void>;
  getYearsOfProclamation: () => Promise<void>;
  getOtherResponsibilities: () => Promise<void>;
  getListsOfProclaimedAssets: () => Promise<void>;
}

export const useFilterStore = create<FilterState>((set) => ({
  statuses: { recordStatus: [] },
  authors: [],
  institutions: [],
  significanceLevel: { significanceLevels: [] },
  holders: [],
  yearsOfProclamation: [],
  proclaimedCulturalAssets: [],
  otherResponsibilities: [],

  getStatuses: async () => {
    try {
      const response = await fetchRecordStatuses();
      set({ statuses: response });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },

  getAuthors: async () => {
    try {
      const response = await fetchAuthors();
      set({ authors: response.authors });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },

  getInstitutions: async () => {
    try {
      const response = await fetchInstitutions();
      set({ institutions: response });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },

  getSignificanceLevels: async () => {
    try {
      const response = await fetchSignificanceLevel();
      set({ significanceLevel: response });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },
  getHolders: async () => {
    try {
      const response = await fetchHolders();
      set({ holders: response });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },
  getYearsOfProclamation: async () => {
    try {
      const response = await fetchYearsOfProclamation();
      set({ yearsOfProclamation: response });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },

  getListsOfProclaimedAssets: async () => {
    try {
      const response = await fetchProclaimedCulturalAssets();
      set({ proclaimedCulturalAssets: response.lists });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },

  getOtherResponsibilities: async () => {
    try {
      const response = await fetchOtherResponsibilities(1, 30);
      set({ otherResponsibilities: response.responsibilities });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  },
}));
