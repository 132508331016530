import type { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useAuthStore } from "../../store/authStore";
import { LOGIN_PAGE } from "../../common/constants/routes.constants";
import { createToastNotification } from "../../utilities/helpers";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import "./AccountDetails.scss";
import { logout } from "../../api/login";
import { useNavigate } from "react-router";
import { Stack } from "@mui/material";
import ChangePasswordModal from "../ChangePassword/ChangePassword";
import React, { useState } from "react";
import useLoadingStore from "../../store/LoadingStore";

interface AccountDetailsProps {
  anchorEl: null | Element;
  onClose: () => void;
  open?: boolean;
}

const AccountDetails: FC<AccountDetailsProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const firstName = useAuthStore((state) => state.authState.firstName);
  const lastName = useAuthStore((state) => state.authState.lastName);
  const email = useAuthStore((state) => state.authState.email);
  const clearTokensOnLogout = useAuthStore((state) => state.clearTokensOnLogout);
  const { setLoading } = useLoadingStore();
  const navigate = useNavigate();

  const handleLogout = async () => {
    setLoading(true);
    try {
      await logout();
      await clearTokensOnLogout();
      onClose();
      navigate(LOGIN_PAGE);
    } catch (error: any) {
      createToastNotification("error", error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);

  const openChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 250 } }}
      {...other}
    >
      <Box className="account-details-container">
        <Typography variant="body2" fontWeight={500} className="account-details-container__info">
          {firstName} {lastName}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          className="account-details-container__info"
        >
          {email}
        </Typography>
        <ListItemText
          onClick={() => {}}
          primary={
            <Typography
              variant="body2"
              mt={2}
              className="account-details-container__change-password"
              onClick={() => {
                openChangePasswordModal();
              }}
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <LockOutlinedIcon className="account-details-container__change-password--icon" />
                <p>Промени лозинку</p>
              </Stack>
            </Typography>
          }
        />
      </Box>
      <Divider />
      <Box className="account-details-container__logout-box">
        <Button onClick={handleLogout} size="small">
          Одјави се
        </Button>
      </Box>
      {isChangePasswordModalOpen && (
        <ChangePasswordModal closeChangePasswordModal={closeChangePasswordModal} />
      )}
    </Popover>
  );
};
export default AccountDetails;
