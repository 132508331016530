import "./Header.scss";
import AccountDetailsUserMenu from "../AccountDetailsUserMenu/AccountDetailsUserMenu";
import headerLogo from "../../assets/headerLogo.png";
import { FC } from "react";
import { useAuthStore } from "../../store/authStore";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { LOGIN } from "../../api/utilities/api.urls";
import { HOME_PAGE } from "../../common/constants/routes.constants";

//TODO check loading part

const Header: FC = () => {
  const { authState } = useAuthStore();
  const navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate(HOME_PAGE);
  };

  return (
    <header className="header">
      <div className="header__logo-container" onClick={navigateToHomePage}>
        <img src={headerLogo} alt="header-logo" className="header__header-logo" />
      </div>
      <div className="header__button-container">
        {authState.accessToken ? (
          <AccountDetailsUserMenu />
        ) : (
          <Button variant="contained" onClick={() => navigate(LOGIN)}>
            Пријави се
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;
