import * as Yup from "yup";

export const listsValidationSchema = Yup.object({
  year: Yup.number()
    .typeError("Година мора бити број")
    .integer("Година мора бити цео број")
    .test("len", "Година мора имати четири цифре", (value) => {
      if (value === undefined) return false;
      return value >= 1 && value <= 9999;
    }),
});
