import React, { FC, useEffect } from "react";
import { ILegacy } from "../../common/interfaces/bibliographic-unit.interface";
import { useFormik } from "formik";
import CustomModal from "../../components/Modal/CustomModal";
import { ListItemText, MenuItem, Stack, TextField } from "@mui/material";
import {
  createToastNotification,
  formatDate,
  getSignificanceLevelLabel,
  getStatusLabel,
} from "../../utilities/helpers";
import { DatePicker } from "@mui/x-date-pickers";
import { useFilterStore } from "../../store/filtersStore";
import ModalActions from "../../components/Modal/ModalActions";
import FreeSoloAutocompleteComponent from "../../components/FreeSoloAutocompleteComponent/FreeSoloAutocompleteComponent";
import CustomSelectInput from "../../components/CustomSelectInputs/CustomSelectInput";
import { editDetailsLegacyUnit } from "../../api/publications";
import dayjs from "dayjs";

interface StatusOption {
  value: string;
  label: string;
}
const LegacyEditModal: FC<{
  bibliographicUnit: ILegacy | undefined;
  handleClose: () => void;
  getUnitDetails: () => Promise<void>;
}> = ({ bibliographicUnit, handleClose, getUnitDetails }) => {
  function removeEmptyFields(obj: Record<string, any>): Record<string, any> {
    return Object.entries(obj)
      .filter(([_, value]) => value !== undefined && value !== null && value !== "")
      .reduce(
        (acc, [key, value]) => {
          if (key === "holder" && value.holderName === "") {
            return acc;
          }
          if (key === "yearOfProclamation" && value === 0) {
            return acc;
          }
          acc[key] = value;
          return acc;
        },
        {} as Record<string, any>
      );
  }

  const formik = useFormik({
    initialValues: {
      title: bibliographicUnit?.title,
      holder: {
        holderID: bibliographicUnit?.holder?.holderID || null,
        holderName: bibliographicUnit?.holder?.holderName || "",
      },
      institutionID: bibliographicUnit?.institution?.institutionID,
      recordStatus: bibliographicUnit?.recordStatus,
      significanceLevel: bibliographicUnit?.significanceLevel,
      yearOfProclamation: bibliographicUnit?.yearOfProclamation,
      dateOfRegistration: bibliographicUnit?.dateOfRegistration,
      description: bibliographicUnit?.description,
      specimenRangeTo: bibliographicUnit?.specimenRangeTo,
      specimenRangeFrom: bibliographicUnit?.specimenRangeFrom,
    },
    onSubmit: async () => {
      try {
        await editDetailsLegacyUnit(
          bibliographicUnit!.bibliographicUnitID,
          removeEmptyFields({
            title: formik.values?.title,
            holder: {
              holderID: formik.values.holder.holderID || null,
              holderName: formik.values.holder.holderName || "",
            },
            institutionID: formik.values.institutionID,
            recordStatus: formik.values.recordStatus,
            significanceLevel: formik.values.significanceLevel,
            yearOfProclamation: Number(formik.values.yearOfProclamation),
            dateOfRegistration: formik.values.dateOfRegistration,
            description: formik.values.description,
            specimenRangeTo: formik.values.specimenRangeTo,
            specimenRangeFrom: formik.values.specimenRangeFrom,
          })
        );

        handleClose();
        createToastNotification("success", "Успешно извршена измена!");
        getUnitDetails();
        getHolders();
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
    },
  });

  const {
    significanceLevel,
    statuses,
    holders,
    institutions,
    getStatuses,
    getSignificanceLevels,
    getHolders,
    getInstitutions,
  } = useFilterStore();
  const allStatuses = statuses.recordStatus || [];
  const allSignificanceLevels = significanceLevel.significanceLevels || [];

  const institutionOptions: StatusOption[] = institutions.map((inst) => ({
    value: inst.institutionID.toString(),
    label: inst.institutionName,
  }));

  const optionsHolders = holders.map((holder) => ({
    id: holder.holderID,
    name: holder.holderName,
  }));

  useEffect(() => {
    getStatuses();
    getSignificanceLevels();
    getHolders();
    getInstitutions();
    // eslint-disable-next-line
  }, []);

  return (
    <CustomModal open={true} title="Измени библиографску јединицу" handleClose={handleClose}>
      <Stack direction="column" spacing={2} p={1}>
        <TextField
          fullWidth
          label="Назив"
          required
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <FreeSoloAutocompleteComponent
          options={optionsHolders}
          getOptionLabel={(option) => {
            return typeof option === "string" ? option : option.name;
          }}
          getOptionId={(option) => option.id}
          onInputChange={(event, value) => {
            const selectedHolder = holders.find((holder) => holder.holderName === value);
            if (selectedHolder) {
              formik.setFieldValue("holder", {
                holderID: selectedHolder.holderID,
                holderName: selectedHolder.holderName,
              });
            } else {
              formik.setFieldValue("holder", {
                holderID: null,
                holderName: value,
              });
            }
          }}
          value={formik.values.holder ? formik.values.holder.holderName : null}
          isOptionEqualToValue={(option, value) =>
            typeof option !== "string" && typeof value !== "string" && option.id === value.id
          }
          label="Сопственик"
        />
        <CustomSelectInput
          values={institutionOptions}
          label="Институција која врши упис"
          onChange={(value) => {
            const selectedInstitution = institutions.find(
              (institution) => institution.institutionID === value
            );
            if (selectedInstitution) {
              formik.setFieldValue("institutionID", selectedInstitution.institutionID);
              formik.setFieldValue("institution", {
                institutionID: selectedInstitution.institutionID,
                institutionName: selectedInstitution.institutionName,
              });
            }
          }}
          preselectedValueIds={
            formik.values?.institutionID ? [formik.values.institutionID.toString()] : []
          }
        />
        <Stack direction="row" spacing={3}>
          <TextField
            fullWidth
            label="Статус записа"
            required
            select
            name="recordStatus"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.recordStatus}
          >
            {allStatuses.length > 0 &&
              allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <ListItemText primary={getStatusLabel(status)} />
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label="Ниво значаја"
            required
            select
            name="significanceLevel"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.significanceLevel}
          >
            {allSignificanceLevels.length > 0 &&
              allSignificanceLevels.map((significance) => (
                <MenuItem key={significance} value={significance}>
                  <ListItemText primary={getSignificanceLevelLabel(significance)} />
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextField
            sx={{ width: "50%" }}
            label="Година проглашења"
            name="yearOfProclamation"
            onChange={formik.handleChange}
            value={formik.values.yearOfProclamation}
          />
          <DatePicker
            label="Датум уписа"
            format="DD-MM-YYYY"
            onChange={(value) => {
              const formattedDate = formatDate(value!.toISOString());
              formik.setFieldValue("dateOfRegistration", formattedDate);
            }}
            value={
              formik.values.dateOfRegistration ? dayjs(formik.values.dateOfRegistration) : null
            }
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextField
            sx={{ width: "50%" }}
            label="Година грађе од"
            name="specimenRangeFrom"
            onChange={formik.handleChange}
            value={formik.values.specimenRangeFrom}
          />
          <TextField
            sx={{ width: "50%" }}
            label="Година грађе до"
            name="specimenRangeTo"
            onChange={formik.handleChange}
            value={formik.values.specimenRangeTo}
          />
        </Stack>
        <TextField
          fullWidth
          label="Oпис и напомене"
          multiline
          rows={3}
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.description}
        />
      </Stack>
      <ModalActions
        buttonAction={formik.handleSubmit}
        buttonText="Сачувај"
        cancelBtnText="Назад"
        cancelAction={handleClose}
      />
    </CustomModal>
  );
};
export default LegacyEditModal;
