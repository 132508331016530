import React, { useEffect, useState, useCallback } from "react";
import { TextField, Autocomplete, debounce } from "@mui/material";
import { LetterTranslator } from "../../utilities/letter-translator";

interface IGenericSearchInputProps<T> {
  setSearchValue: (searchValue: number[]) => void;
  searchTitle?: string;
  optionsData: T[];
  getOptionLabel: (option: T) => string;
  getOptionId: (option: T) => number;
  width?: string;
  error?: boolean;
  helperText?: string;
  handleBlur?: any;
  fetchOptions?: (inputValue: string) => Promise<T[]>;
}

const CustomAutoSelectInput = <T extends unknown>({
  setSearchValue,
  searchTitle,
  optionsData,
  getOptionLabel,
  getOptionId,
  width,
  error,
  helperText,
  handleBlur,
  fetchOptions,
}: IGenericSearchInputProps<T>) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<T[]>([]);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDynamicOptions = useCallback(
    debounce(async (query: string) => {
      if (fetchOptions && query) {
        setLoading(true);
        try {
          const fetchedOptions = await fetchOptions(query);
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Error fetching options:", error);
        } finally {
          setLoading(false);
        }
      } else if (query === "") {
        setOptions([]);
      }
    }, 300),
    [fetchOptions]
  );

  useEffect(() => {
    const selectedIds = selectedItems.map((item) => getOptionId(item));
    setSearchValue(selectedIds);
  }, [selectedItems, getOptionId, setSearchValue]);

  useEffect(() => {
    const searchQuery = inputValue.trim();

    if (fetchOptions) {
      fetchDynamicOptions(searchQuery);
    } else if (!searchQuery) {
      setOptions(optionsData);
    } else {
      const searchLatin = LetterTranslator.cyr2lat(searchQuery);
      const searchCyrillic = LetterTranslator.lat2cyr(searchQuery);

      const filteredOptions = optionsData.filter((option) => {
        const label = getOptionLabel(option).toLowerCase();
        return (
          label.includes(searchLatin.toLowerCase()) || label.includes(searchCyrillic.toLowerCase())
        );
      });
      setOptions(filteredOptions);
    }
    // eslint-disable-next-line
  }, [inputValue, optionsData]);

  return (
    <Autocomplete
      noOptionsText={loading ? "Учитавање..." : "Нема резултата"}
      style={{ width: width }}
      fullWidth={!width}
      multiple
      limitTags={2}
      filterOptions={(options) => options}
      options={options}
      getOptionLabel={(option) => getOptionLabel(option)}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      value={selectedItems}
      onChange={(event, newValue) => setSelectedItems(newValue)}
      isOptionEqualToValue={(option, value) => getOptionId(option) === getOptionId(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          className="search-input"
          variant="outlined"
          label={searchTitle}
          fullWidth
          error={error}
          helperText={helperText}
          onBlur={handleBlur}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={getOptionId(option)}>
          {getOptionLabel(option)}
        </li>
      )}
      loading={loading}
    />
  );
};

export default CustomAutoSelectInput;
