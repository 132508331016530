export class LetterTranslator {
    // eslint-disable-line
    static LETTERS = {// eslint-disable-line
        'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Ђ': 'Đ', 'Е': 'E', 'Ж': 'Ž',// eslint-disable-line
        'З': 'Z', 'И': 'I', 'Ј': 'J', 'К': 'K', 'Л': 'L', 'Љ': 'Lj', 'М': 'M', 'Н': 'N',// eslint-disable-line
        'Њ': 'Nj', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'Ћ': 'Ć', 'У': 'U',// eslint-disable-line
        'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Č', 'Џ': 'Dž', 'Ш': 'Š', 'а': 'a', 'б': 'b',// eslint-disable-line
        'в': 'v', 'г': 'g', 'д': 'd', 'ђ': 'đ', 'е': 'e', 'ж': 'ž', 'з': 'z', 'и': 'i',// eslint-disable-line
        'ј': 'j', 'к': 'k', 'л': 'l', 'љ': 'lj', 'м': 'm', 'н': 'n', 'њ': 'nj', 'о': 'o',// eslint-disable-line
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'ћ': 'ć', 'у': 'u', 'ф': 'f', 'х': 'h',// eslint-disable-line
        'ц': 'c', 'ч': 'č', 'џ': 'dž', 'ш': 'š'// eslint-disable-line
    };// eslint-disable-line
    // eslint-disable-line

    static letters2: { cyr: string, lat: string }[] = [
        {cyr: 'Њ', lat: 'Nj'}, {cyr: 'њ', lat: 'nj'}, {cyr: 'Љ', lat: 'Lj'}, {cyr: 'љ', lat: 'lj'},
        {cyr: 'Џ', lat: 'Dž'}, {cyr: 'џ', lat: 'dž'},
        {cyr: 'А', lat: 'A'}, {cyr: 'Б', lat: 'B'}, {cyr: 'В', lat: 'V'}, {cyr: 'Г', lat: 'G'},
        {cyr: 'Д', lat: 'D'}, {cyr: 'Ђ', lat: 'Đ'}, {cyr: 'Е', lat: 'E'}, {cyr: 'Ж', lat: 'Ž'},
        {cyr: 'З', lat: 'Z'}, {cyr: 'И', lat: 'I'}, {cyr: 'Ј', lat: 'J'}, {cyr: 'К', lat: 'K'},
        {cyr: 'Л', lat: 'L'}, {cyr: 'М', lat: 'M'}, {cyr: 'Н', lat: 'N'},
        {cyr: 'О', lat: 'O'}, {cyr: 'П', lat: 'P'}, {cyr: 'Р', lat: 'R'},
        {cyr: 'С', lat: 'S'}, {cyr: 'Т', lat: 'T'}, {cyr: 'Ћ', lat: 'Ć'}, {cyr: 'У', lat: 'U'},
        {cyr: 'Ф', lat: 'F'}, {cyr: 'Х', lat: 'H'}, {cyr: 'Ц', lat: 'C'}, {cyr: 'Ч', lat: 'Č'},
        {cyr: 'Ш', lat: 'Š'},
        {cyr: 'а', lat: 'a'}, {cyr: 'б', lat: 'b'}, {cyr: 'в', lat: 'v'}, {cyr: 'г', lat: 'g'},
        {cyr: 'д', lat: 'd'}, {cyr: 'ђ', lat: 'đ'},
        {cyr: 'е', lat: 'e'}, {cyr: 'ж', lat: 'ž'}, {cyr: 'з', lat: 'z'}, {cyr: 'и', lat: 'i'},
        {cyr: 'ј', lat: 'j'}, {cyr: 'к', lat: 'k'}, {cyr: 'л', lat: 'l'},
        {cyr: 'м', lat: 'm'}, {cyr: 'н', lat: 'n'}, {cyr: 'о', lat: 'o'},
        {cyr: 'п', lat: 'p'}, {cyr: 'р', lat: 'r'}, {cyr: 'с', lat: 's'}, {cyr: 'т', lat: 't'},
        {cyr: 'ћ', lat: 'ć'}, {cyr: 'у', lat: 'u'}, {cyr: 'ф', lat: 'f'}, {cyr: 'х', lat: 'h'},
        {cyr: 'ц', lat: 'c'}, {cyr: 'ч', lat: 'č'}, {cyr: 'ш', lat: 'š'},
    ];


    static cyr2lat(text: string) {
        const l = LetterTranslator.LETTERS;
        // Replace Cyrillic with Latin characters
        return text
            .split('')
            // @ts-ignore
            .map((char) => l[char] || char)
            .join('');
    }

    static lat2cyr(text: string) {
        const l = LetterTranslator.letters2;
        // Replace Latin with Cyrillic characters
        for (let i = 0; i < l.length; i++) {
            text = text.replaceAll(l[i].lat, l[i].cyr);
        }
        return text;
    }
}