import { useNavigate, useParams } from "react-router";
import React, { ChangeEvent, useEffect, useState } from "react";
import { validateFileFormat } from "../../../utilities/file-helper";
import { NewsStatus } from "../../../common/enums/news.enums";
import { getNewsByID, updateNews } from "../../../api/news";
import { ALL_NEWS } from "../../../common/constants/routes.constants";
import { createToastNotification } from "../../../utilities/helpers";
import { ConfirmNewsSaveDialog } from "../../../components/News/ConfirmNewsSaveDialog/ConfirmNewsSaveDialog";
import { Box, Button, Card, CircularProgress, TextField, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { InputFileUpload } from "../../../components/InputFileUpload/InputFileUpload";
import FileUploaderZone from "../../../uploader/FileUploaderZone/FileUploaderZone";

export const EditNews = () => {
  const { id: newsID } = useParams();
  const [newsData, setNewsData] = useState<{
    newsID: number;
    title: string;
    thumbnail: File | null;
    content: string;
    attachments: File[] | null;
  }>({ newsID: 0, title: "", thumbnail: null, content: "", attachments: null });

  const [loading, setLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (newsID) {
      fetchNewsData(Number(newsID));
    }
  }, [newsID]);

  const fetchNewsData = async (id: number) => {
    try {
      const news = await getNewsByID(id);
      let { title, content } = news;

      const downloaded =
        news.files?.length > 0
          ? await Promise.all(
              news.files.map(async (file: string) => {
                const response = await fetch(file);
                let fileName = new URL(file).pathname.split("/").pop() ?? "";
                let fileNameArr = fileName.split("-");
                if (fileNameArr[5]) {
                  fileName = fileName.replaceAll(
                    fileNameArr[0] +
                      "-" +
                      fileNameArr[1] +
                      "-" +
                      fileNameArr[2] +
                      "-" +
                      fileNameArr[3] +
                      "-" +
                      fileNameArr[4] +
                      "-",
                    ""
                  );
                }
                fileName = decodeURIComponent(fileName);
                const blob = await response.blob();
                return new File([blob], fileName, { type: blob.type });
              })
            )
          : null;

      let thumbnail: File | null = null;
      if (news.imageURL) {
        const response = await fetch(news.imageURL);
        let fileName = new URL(news.imageURL).pathname.split("/").pop() ?? "";
        let fileNameArr = fileName.split("-");
        if (fileNameArr[5]) {
          fileName = fileName.replaceAll(
            fileNameArr[0] +
              "-" +
              fileNameArr[1] +
              "-" +
              fileNameArr[2] +
              "-" +
              fileNameArr[3] +
              "-" +
              fileNameArr[4] +
              "-",
            ""
          );
        }
        fileName = decodeURIComponent(fileName);
        const blob = await response.blob();
        thumbnail = new File([blob], fileName, { type: blob.type });
      }

      setNewsData((prevData) => ({
        ...prevData,
        newsID: Number(id),
        title,
        content,
        attachments: downloaded,
        thumbnail: thumbnail,
      }));
    } catch (error) {
      createToastNotification("error", "Неуспешно дохватање вести", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewsData({ ...newsData, [name]: value });
  };

  const handleThumbnailUpload = (files: any) => {
    if (files === null) {
      setNewsData({ ...newsData, thumbnail: null });
      return;
    }

    try {
      validateFileFormat(files, 1, ["image/jpeg", "image/png", "image/jpg", "image/webp"]);
      if (files[0]) {
        setNewsData({ ...newsData, thumbnail: files[0] });
      }
    } catch (error) {}
  };

  const handleAttachmentsUpload = (files: File[] | null) => {
    setNewsData({ ...newsData, attachments: files });
  };

  const handleAttachmentsRemove = (index: number) => {
    if (!newsData.attachments) {
      setNewsData({ ...newsData, attachments: null });
      return;
    }

    const updatedFiles = newsData.attachments.filter((_, fileIndex) => fileIndex !== index);
    setNewsData({ ...newsData, attachments: updatedFiles });
  };

  const save = async (status: NewsStatus) => {
    try {
      await updateNews({
        ...newsData,
        status: status,
      });
      createToastNotification(
        "success",
        status === NewsStatus.Published ? "Вест је објављена успешно" : "Вест је измењена успешно"
      );
      navigate(ALL_NEWS);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
      setModalOpened(false);
    }
  };

  return (
    <>
      <ConfirmNewsSaveDialog
        open={modalOpened}
        saveDraft={async () => {
          await save(NewsStatus.Draft);
        }}
        saveAndPublish={async () => {
          await save(NewsStatus.Published);
        }}
        cancel={()=>{
            setLoading(false);
            setModalOpened(false);
        }}
      />
      <Box className="page-table">
        <Card sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CardContent sx={{ width: 900 }}>
            <Box>
              <TextField
                label="Наслов вести"
                name="title"
                value={newsData.title}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
              />
              <InputFileUpload
                title="Насловна фотографија"
                buttonName="Изабери насловну фотографију"
                validFileExtensions="Подржане фотографије: png, jpg, jpeg, webp. Максимална величина фотографије је 50MB"
                ImgFileThumbnailText="Подржане фотографије би требало да буду 'landscape' оријентације и минималне резолуције 1920x1080. Пример подржаних резолуција: 1920x1080, 2560x1440, 3840x2160."
                handleFilesUpload={handleThumbnailUpload}
                uploadedFile={newsData.thumbnail}
              />
              <TextField
                label="Садржај вести"
                name="content"
                value={newsData.content}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
                multiline
                rows={10}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Приложени документи
              </Typography>
              <FileUploaderZone
                handleFileChange={handleAttachmentsUpload}
                handleRemoveFile={handleAttachmentsRemove}
                files={newsData.attachments}
                maxFiles={10}
                allowedFormats={[
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  "application/vnd.oasis.opendocument.text",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "text/csv",
                  "text/plain",
                  "application/vnd.oasis.opendocument.spreadsheet",
                  "application/vnd.ms-powerpoint",
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  "application/vnd.ms-powerpoint",
                  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                  "application/vnd.oasis.opendocument.presentation",
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/webp",
                  "pdf",
                  "docx",
                  "doc",
                  "odt",
                  "xls",
                  "xlsx",
                  "csv",
                  "ods",
                  "ppt",
                  "pptx",
                  "pps",
                  "ppsx",
                  "odp",
                  "jpg",
                  "jpeg",
                  "png",
                  "webp",
                ]}
                customSizeTitle={
                  "Подржани формати докумената: pdf, docx, doc, odt, xls, xlsx, csv, ods, ppt, pptx, pps, ppsx, odp, jpg, jpeg, png, webp"
                }
              />
              <Box mt={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    setModalOpened(true);
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : "Сачувај"}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
