import React, { FC, useEffect, useState } from "react";
import { Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SearchInput from "../../../components/SearchInput/SearchInput";
import CustomSelectInput from "../../../components/CustomSelectInputs/CustomSelectInput";
import { fetchInstitutions } from "../../../api/institutions";
import { createToastNotification } from "../../../utilities/helpers";

interface TableHeaderProps {
  getHoldersData: () => Promise<void>;
  setSearchFilters: (key: "name" | "institutions", value: any) => void;
}

export const HoldersTableHeader: FC<TableHeaderProps> = ({ getHoldersData, setSearchFilters }) => {
  const [institutions, setInstitutions] = useState<{ value: string; label: string }[]>([]);

  const getInstitutions = async () => {
    try {
      const response = await fetchInstitutions();
      setInstitutions(
        response.map((institution: any) => ({
          value: institution.institutionID,
          label: institution.institutionName,
        }))
      );
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (institutions.length < 1) {
      getInstitutions();
    }
  }, [institutions]);

  return (
    <>
      <Stack className="filter-section" direction="row" spacing={1} mb={1}>
        <SearchInput
          icon
          setSearchValue={(e) => {
            setSearchFilters("name", e);
          }}
          searchTitle="Претражи по имену"
        />
        <CustomSelectInput
          values={institutions}
          label={"Институција"}
          onChange={(e: any) => {
            setSearchFilters("institutions", e);
          }}
          multiple={true}
          width={"1000px"}
        />
        <Button variant="contained" onClick={getHoldersData}>
          <SearchIcon />
        </Button>
      </Stack>
    </>
  );
};
