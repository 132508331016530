import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";

const HoldersTableRow: FC<{
    item: any;
    index: number;
    handleRowClick: () => void;
}> = ({ item, index, handleRowClick }) => {
    return (
        <TableRow key={index} onClick={handleRowClick}>
            <TableCell>{item.holderID}</TableCell>
            <TableCell>{item.holderName}</TableCell>
            <TableCell>{item.institutionName}</TableCell>
        </TableRow>
    );
};

export default HoldersTableRow;
