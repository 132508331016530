import React, { FC } from "react";
import "./Loader.scss";
import { useThemeStore } from "../../store/useThemeStore";
import { Oval } from "react-loader-spinner";
const Loader: FC = () => {
  const { themeOptions } = useThemeStore();

  return (
    <div className="loader">
      <div className="loader__container">
        <Oval
          height={50}
          width={50}
          color={themeOptions.palette?.primary.main}
          wrapperClass="oval"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor={themeOptions.palette.primary.light}
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      </div>
    </div>
  );
};
export default Loader;
