import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";

export const ConfirmNewsSaveDialog: FC<{
  open: boolean;
  cancel: () => void;
  saveDraft: () => void;
  saveAndPublish: () => void;
  disabledFirst?: boolean;
  disabledSecond?: boolean;
}> = ({ open, saveDraft, saveAndPublish, cancel,disabledFirst, disabledSecond }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Чување вести</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Да ли желите да објавите вест приликом чувања?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} disabled={disabledFirst}>
          Откажи
        </Button>
        <Button onClick={saveDraft} disabled={disabledFirst}>
          Сачувај радну верзију
        </Button>
        <Button onClick={saveAndPublish} disabled={disabledSecond} autoFocus>
          Сачувај и објави
        </Button>
      </DialogActions>
    </Dialog>
  );
};
