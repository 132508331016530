import { Box, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bibliographicUnitImage from "../../../assets/biblunitimg.png";
import editIcon from "../../../assets/icons/edit-icon.png";
import "./BasicUnitHeader.scss";
import { FC } from "react";
import { useNavigate } from "react-router";
const BasicUnitHeader: FC<{
  unitName?: string;
  unitAuthor: string;
  handleOnClick?: () => void;
  canUpdate?: boolean;
}> = ({ unitName, unitAuthor, handleOnClick, canUpdate }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="basic-unit-details-header">
      <Stack
        direction="row"
        alignItems="center"
        className="basic-unit-details-header__navigation"
        onClick={handleBackClick}
      >
        <ArrowBackIcon sx={{ height: "20px", mr: 1 }} />
        Претрага централног регистра
      </Stack>
      <div className="basic-unit-details-header__info">
        <Stack direction="row" gap={2}>
          <img
            src={bibliographicUnitImage}
            className="basic-unit-details-header__thumbnail"
            alt="bibliographic-unit-img"
          />
          <Stack direction="column" gap="8px">
            <Typography variant="h5">{unitName}</Typography>
            <Typography variant="body1">{unitAuthor}</Typography>
          </Stack>
        </Stack>
        {canUpdate && (
          <Box className="basic-unit-details-header__edit-action" onClick={handleOnClick}>
            <p>Измени</p>
            <img src={editIcon} alt="edit-icon" className="basic-unit-details-header__edit-icon" />
          </Box>
        )}
      </div>
    </div>
  );
};
export default BasicUnitHeader;
