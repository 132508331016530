import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";

const SpecimenTableRow: FC<{
  item: any;
  index: number;
  openEditSpecimenModal: (id: number) => void;
}> = ({ item, index, openEditSpecimenModal }) => {
  return (
    <TableRow key={index} onClick={() => openEditSpecimenModal(item.specimenID)}>
      <TableCell>{item.specimenID}</TableCell>
      <TableCell>{item.signature}</TableCell>
      <TableCell>{item.inventoryNumber}</TableCell>
      <TableCell>{item.holderName}</TableCell>
      <TableCell>{item.institutionName}</TableCell>
    </TableRow>
  );
};

export default SpecimenTableRow;
