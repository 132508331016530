import * as yup from "yup";

export const loginValidationSchema = yup.object({
  email: yup
    .string()
    .email("Имејл није доброг формата")
    .max(255)
    .required("Имејл је обавезно поље"),
  password: yup.string().max(255).required("Лозинка је обавезно поље"),
});
