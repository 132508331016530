import { Box } from "@mui/material";
import { createToastNotification, getRoleLabel } from "../../utilities/helpers";
import { fetchUserDetails, fetchUsers } from "../../api/users";
import { useState, useEffect } from "react";
import { IUsers } from "../../common/interfaces/users.interface";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { initialMetadataState } from "../../common/constants/common.constants";
import UserModal from "./UserModal/UserModal";

const Users = () => {
  const [users, setUsers] = useState<IUsers[]>([]);
  const [usersMetadata, setUsersMetadata] = useState(initialMetadataState);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [openModal, setOpenModal] = useState(false);
  const [userDetails, setUserDetails] = useState<IUsers>();
  const [loading, setLoading] = useState(false);

  const handleRowClick = async (params: { row: { id: any } }) => {
    const userID = params.row.id;
    try {
      const response = await fetchUserDetails(userID);
      setUserDetails(response);
      setOpenModal(true);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const handleCloseUsersDetailsModal = () => {
    setOpenModal(false);
  };
  const getListOfUsers = async (
    sortField = "",
    sortOrder?: "asc" | "desc" | null | undefined | string
  ) => {
    setLoading(true);
    try {
      const usersResponse = await fetchUsers(
        usersMetadata.currentPage,
        usersMetadata.itemsPerPage,
        sortField,
        sortOrder
      );
      setUsers(usersResponse.users);
      setUsersMetadata({
        currentPage: usersResponse.currentPage,
        itemsPerPage: usersResponse.itemsPerPage,
        itemCount: usersResponse.itemCount,
        totalItems: usersResponse.totalItems,
        totalPages: usersResponse.totalPages,
      });
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sortField = sortModel.length ? sortModel[0].field : "";
    const sortOrder = sortModel.length ? sortModel[0].sort : "";
    getListOfUsers(sortField, sortOrder);
    // eslint-disable-next-line
  }, [usersMetadata.currentPage, sortModel]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ИД",
      flex: 0.5,
      sortable: false,
    },
    {
      field: "name",
      headerName: "ИМЕ И ПРЕЗИМЕ",
      flex: 2,
      sortable: true,
      renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`,
    },
    {
      field: "email",
      headerName: "ИМЕЈЛ",
      flex: 2,
      sortable: false,
    },
    {
      field: "institutionName",
      headerName: "НАЗИВ ИНСТИТУЦИЈЕ",
      flex: 2,
      sortable: false,
    },
    {
      field: "role",
      headerName: "УЛОГА",
      flex: 1,
      sortable: true,
    },
  ];

  const rows = users.map((user) => ({
    id: user.id,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    institutionName: user.institution?.institutionName,
    role: getRoleLabel(user.role),
  }));

  return (
    <Box className="page-table">
      <DataGrid
        loading={loading}
        disableColumnMenu
        rows={rows}
        columns={columns}
        rowCount={usersMetadata.totalItems}
        pagination
        paginationMode="server"
        paginationModel={{
          page: usersMetadata.currentPage - 1,
          pageSize: usersMetadata.itemsPerPage,
        }}
        onPaginationModelChange={(model) => {
          setUsersMetadata((prev) => ({
            ...prev,
            currentPage: model.page + 1,
            itemsPerPage: model.pageSize,
          }));
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
        }}
        localeText={{
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to }) => {
              return `${from}-${to} од ${usersMetadata?.totalItems as number}`;
            },
            labelRowsPerPage: "Редова по страни",
          },
          noRowsLabel: "Нема доступних података",
        }}
        sx={{
          "& .MuiDataGrid-container--top [role='row']": {
            backgroundColor: "var(--table-hover-color)",
            fontSize: "12px",
          },
        }}
      />
      {openModal && (
        <UserModal
          handleClose={handleCloseUsersDetailsModal}
          userDetails={userDetails}
          getListOfUsers={getListOfUsers}
        />
      )}
    </Box>
  );
};

export default Users;
