import DetailsCardRow from "../BasicDetailsCardComponent/DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { ISpecimen } from "../../../common/interfaces/bibliographic-unit.interface";
import { Card, CardContent, CardHeader } from "@mui/material";

const SpecimenSterilizationInfo: FC<{ bibliographicUnit: ISpecimen }> = ({ bibliographicUnit }) => {
  return (
    <Card>
      <CardHeader title="Стерилизација" />
      <CardContent>
        <DetailsCardRow
          title="Година извршења"
          content={bibliographicUnit.conservation?.sterilization?.yearOfProtection}
        />
        <DetailsCardRow
          title="Институција која је извршила"
          content={bibliographicUnit.conservation?.sterilization?.protectionInstitution}
        />
        <DetailsCardRow
          title="Комплетирана стерилизација"
          content={bibliographicUnit.conservation?.sterilization?.isDone ? "Да" : "Не"}
        />
        <DetailsCardRow
          title="Напомена"
          content={bibliographicUnit.conservation?.sterilization?.note}
        />
      </CardContent>
    </Card>
  );
};
export default SpecimenSterilizationInfo;
