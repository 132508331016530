export const LOGIN = "/login";
export const CHANGE_PASSWORD = "/users/change-password";
export const LOGOUT = "/logout";
export const RECORD_STATUS = "/record-status";
export const AUTHORS = "/public/authors";
export const INSTITUTIONS = "/public/institutions";
export const SIGNIFICANCE_LEVEL = "/public/cultural-property/significance-level";
export const PUBLISHED_NEWS = "/public/published-news";
export const HOLDERS = "/public/holders";
export const ADMIN_HOLDERS = "/holders";
export const ADMIN_GET_HOLDER_DETAILS = "/holder";
export const YEAR_OF_PROCLAMATION = "/public/year-of-proclamation";
export const PUBLIC_LISTS_CULTURAL_PROPERTY = "/public/lists-cultural-property";
export const PUBLIC_CULTURAL_PROPERTY = "/public/cultural-property";
export const PROCLAIMED_CULTURAL_ASSETS = "/public/list-of-proclaimed";
export const OTHER_RESPONSIBILITIES = "/other-responsibilities";
export const MONOGRAPHIC_PUBLICATIONS = "/cultural-property/monographic-publications";
export const LEGACY_PUBLICATIONS = "/cultural-property/legacies-and-comprehensive-collections";
export const CULTURAL_PROPERTY = "/cultural-property";
export const BIBLIOGRAPHIC_TYPE = "/type";
export const SPECIMEN = "/specimen";
export const LISTS_CULTURAL_PROPERTY = "/lists-cultural-property";
export const ADD_UNIT = "/add-unit";
