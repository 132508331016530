import BasicUnitHeader from "./BasicUnitHeader/BasicUnitHeader";
import BasicDetailsCard from "./BasicDetailsCardComponent/BasicDetailsCardComponent";
import React, { useEffect, useState } from "react";
import {
  ILegacy,
  IOtherUnits,
  ISpecimen,
  ISpecimenList,
} from "../../common/interfaces/bibliographic-unit.interface";
import {
  deleteSpecimen,
  deleteUnit,
  fetchBibliographicUnits,
  fetchListOfSpecimens,
} from "../../api/bibliographic-unit";
import { createToastNotification } from "../../utilities/helpers";
import { useNavigate, useParams } from "react-router";
import { initialMetadataState } from "../../common/constants/common.constants";
import BasicTable from "../../components/BasicTable/BasicTable";
import { SPECIMENS_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import SpecimenTableRow from "./SpecimenTableRow/SpecimenTableRow";
import { Button, Stack, Typography } from "@mui/material";
import DeleteUnitCard from "./DeleteUnitComponent/DeleteUnitCard";
import AddIcon from "@mui/icons-material/Add";
import InfoCardComponent from "./BasicDetailsCardComponent/InfoCardComponent/InfoCardComponent";
import DigitalObjectCard from "./BasicDetailsCardComponent/DigitalObjectCard/DigitalObjectCard";
import SpecimenSterilizationInfo from "./SpecimenAdditionalDetailsCards/SpecimenSterilizationInfo";
import SpecimenRestaurtionInfo from "./SpecimenAdditionalDetailsCards/SpecimenRestaurationInfo";
import SpecimenConservationInfo from "./SpecimenAdditionalDetailsCards/SpecimenConservationInfo";
import SpecimenModal from "../../components/SpecimenModal/SpecimenModal";
import MonoEditModal from "../EditModals/MonoEditModal";
import LegacyEditModal from "../EditModals/LegacyEditModal";
import { CENTRAL_REGISTRY } from "../../common/constants/routes.constants";
import ConfirmationModal from "../../components/ConfirmationModal";

const BibliographicUnitDetails = () => {
  const [bibliographicUnit, setBibliographicUnit] = useState<ILegacy | IOtherUnits | ISpecimen>();
  const [listOfSpecimens, setListOfSpecimens] = useState<ISpecimenList[]>([]);
  const [isMonoModalOpen, setIsMonoModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpenModal] = useState<boolean>();
  const [isSpecimenDetailsOpen, setIsSpecimenDetailsOpen] = useState<boolean>(false);
  const [isLegacyModalOpen, setIsLegacyModalOpen] = useState<boolean>(false);
  const [isSpecimenModalOPen, setIsSpecimenModalOpen] = useState<boolean>(false);
  const [specimenID, setSpecimenID] = useState<number | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [listOfSpecimensMetadata, setListOfSpecimensMetadata] = useState(initialMetadataState);
  const { id, itemType } = useParams();
  const unitAuthor =
    (bibliographicUnit as IOtherUnits)?.authors?.map((author) => author.authorName).join(", ") ||
    "Непознат аутор";
  const navigate = useNavigate();
  function isOtherUnits(unit: ILegacy | IOtherUnits | ISpecimen): unit is IOtherUnits {
    return "mediaURL" in unit;
  }

  const isLegacy = (unit: any): unit is ILegacy => {
    return "specimenRangeFrom" in unit;
  };

  useEffect(() => {
    getUnitDetails();
    // eslint-disable-next-line
  }, [id, itemType]);

  useEffect(() => {
    getListOfSpecimens();
    // eslint-disable-next-line
  }, [listOfSpecimensMetadata.totalItems, listOfSpecimensMetadata.currentPage]);

  const getUnitDetails = async () => {
    try {
      if (id && itemType) {
        const response = await fetchBibliographicUnits(parseInt(id, 10), itemType);
        setBibliographicUnit(response);
      }
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const deleteSpecificUnit = async () => {
    try {
      if (
        itemType === "specimen" &&
        bibliographicUnit &&
        "specimenID" in bibliographicUnit &&
        bibliographicUnit?.specimenID
      ) {
        await deleteSpecimen(bibliographicUnit?.specimenID);
      } else if (id) {
        await deleteUnit(parseInt(id, 10));
      }
      navigate(CENTRAL_REGISTRY);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const getListOfSpecimens = async () => {
    try {
      if (id) {
        const response = await fetchListOfSpecimens(
          parseInt(id, 10),
          listOfSpecimensMetadata.currentPage,
          listOfSpecimensMetadata.itemsPerPage
        );
        setListOfSpecimens(response.specimens);
        setListOfSpecimensMetadata({
          currentPage: response.currentPage,
          itemsPerPage: response.itemsPerPage,
          itemCount: response.itemCount,
          totalItems: response.totalItems,
          totalPages: response.totalPages,
        });
      }
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const isSpecimen = (unit: any): unit is ISpecimen => {
    return (unit as ISpecimen).specimenID !== undefined;
  };

  const closeDetailsSpecimenModal = () => {
    setIsSpecimenDetailsOpen(false);
    setSpecimenID(null);
    setIsEditMode(false);
  };

  const openNewSpecimenModal = () => {
    setSpecimenID(null);
    setIsEditMode(false);
    setIsSpecimenModalOpen(true);
  };

  const openEditSpecimenModal = (id: number) => {
    setSpecimenID(id);
    setIsEditMode(true);
    setIsSpecimenModalOpen(true);
  };

  const closeSpecimenModal = () => {
    setIsSpecimenModalOpen(false);
    setSpecimenID(null);
    setIsEditMode(false);
  };

  const handleOpenModal = () => {
    if (isOtherUnits(bibliographicUnit as IOtherUnits)) {
      setIsMonoModalOpen(true);
    } else if (isLegacy(bibliographicUnit as ILegacy)) {
      setIsLegacyModalOpen(true);
    } else if (isSpecimen(bibliographicUnit as ISpecimen)) {
      setSpecimenID((bibliographicUnit as ISpecimen).specimenID);
      setIsEditMode(true);
      setIsSpecimenDetailsOpen(true);
    }
  };

  return (
    <>
      <BasicUnitHeader
        unitName={bibliographicUnit?.title}
        unitAuthor={unitAuthor}
        canUpdate={bibliographicUnit?.canUpdate}
        handleOnClick={handleOpenModal}
      />
      <Stack direction="row" spacing={4}>
        <Stack direction="column" spacing={3} width={itemType !== "specimen" ? "70%" : "100%"}>
          <BasicDetailsCard bibliographicUnit={bibliographicUnit} />
          {itemType === "specimen" && bibliographicUnit && "specimenID" in bibliographicUnit && (
            <SpecimenConservationInfo bibliographicUnit={bibliographicUnit as ISpecimen} />
          )}
          {itemType === "bibliographicUnit" &&
            bibliographicUnit &&
            isOtherUnits(bibliographicUnit) && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Листа примерака</Typography>
                  <Button onClick={openNewSpecimenModal}>
                    <AddIcon />
                  </Button>
                </Stack>
                <BasicTable
                  loader={false}
                  headings={SPECIMENS_TABLE_HEADINGS}
                  metadata={listOfSpecimensMetadata}
                  setMetadata={setListOfSpecimensMetadata}
                >
                  {listOfSpecimens.length > 0 ? (
                    listOfSpecimens.map((item, index) => (
                      <SpecimenTableRow
                        index={index}
                        item={item}
                        key={index}
                        openEditSpecimenModal={openEditSpecimenModal}
                      />
                    ))
                  ) : (
                    <Typography mt={1} ml={1} variant="body1">
                      Нема примерака за приказ!
                    </Typography>
                  )}
                </BasicTable>
              </>
            )}
        </Stack>
        <Stack direction="column" spacing={4} width={itemType !== "specimen" ? "30%" : "60%"}>
          {itemType === "bibliographicUnit" && (bibliographicUnit as ILegacy | IOtherUnits) && (
            <InfoCardComponent bibliographicUnit={bibliographicUnit} />
          )}
          {itemType === "bibliographicUnit" &&
            bibliographicUnit &&
            isOtherUnits(bibliographicUnit) && (
              <DigitalObjectCard bibliographicUnit={bibliographicUnit} />
            )}
          {itemType === "specimen" && bibliographicUnit && "specimenID" in bibliographicUnit && (
            <>
              <SpecimenSterilizationInfo bibliographicUnit={bibliographicUnit as ISpecimen} />
              <SpecimenRestaurtionInfo bibliographicUnit={bibliographicUnit as ISpecimen} />
            </>
          )}

          {bibliographicUnit?.canUpdate === true && (
            <DeleteUnitCard
              deleteButtonName="Обриши"
              handleDelete={() => {
                setIsConfirmationModalOpenModal(true);
              }}
            />
          )}
          {isConfirmationModalOpen && (
            <ConfirmationModal
              confirmationModalTitle="Да ли сте сигурни да желите да обришете?"
              confirmBtnTitle="Потврди"
              modalDescription="Имајте на уму да се једном обрисани подаци не могу касније вратити!"
              cancelAction={() => setIsConfirmationModalOpenModal(false)}
              handleDelete={() => {
                deleteSpecificUnit();
                navigate("/central-registry");
              }}
            />
          )}
        </Stack>
        {isSpecimenModalOPen && (
          <SpecimenModal
            handleClose={closeSpecimenModal}
            bibliographicUnit={bibliographicUnit as ISpecimen}
            getListOfSpecimens={getListOfSpecimens}
            specimenID={specimenID}
            isEditMode={isEditMode}
            getUnitDetails={getUnitDetails}
          />
        )}
        {isMonoModalOpen && isOtherUnits(bibliographicUnit as IOtherUnits) && (
          <MonoEditModal
            bibliographicUnit={bibliographicUnit as IOtherUnits}
            handleClose={() => {
              setIsMonoModalOpen(false);
            }}
            getUnitDetails={getUnitDetails}
          />
        )}
        {isLegacyModalOpen && isLegacy(bibliographicUnit as ILegacy) && (
          <LegacyEditModal
            bibliographicUnit={bibliographicUnit as ILegacy}
            handleClose={() => setIsLegacyModalOpen(false)}
            getUnitDetails={getUnitDetails}
          />
        )}
        {isSpecimenDetailsOpen && isSpecimen(bibliographicUnit as ISpecimen) && (
          <SpecimenModal
            handleClose={closeDetailsSpecimenModal}
            bibliographicUnit={bibliographicUnit as ISpecimen}
            getListOfSpecimens={getListOfSpecimens}
            specimenID={specimenID}
            getUnitDetails={getUnitDetails}
            isEditMode={isEditMode}
            isDetails
          />
        )}
      </Stack>
    </>
  );
};

export default BibliographicUnitDetails;
