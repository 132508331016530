import CustomModal from "../Modal/CustomModal";
import SpecimenInfoComponent from "./SpecimenInfoComponent/SpecimenInfoComponent";
import { FC, useEffect, useState } from "react";
import AddOrUpdateSpecimenInfo from "./AddOrUpdateSpecimenInfo/AddOrUpdateSpecimenInfo";
import ModalActions from "../Modal/ModalActions";
import { useFormik } from "formik";
import { createSpecimen, updateSpecimen } from "../../api/specimen";
import { createToastNotification } from "../../utilities/helpers";
import {
  ILegacy,
  IOtherUnits,
  ISpecimen,
} from "../../common/interfaces/bibliographic-unit.interface";
import { SpecimenInfoValidationSchema } from "../../common/validation-schemas/specimen-info.schema";
import { fetchBibliographicUnits } from "../../api/bibliographic-unit";
import { useAuthStore } from "../../store/authStore";
import { useFilterStore } from "../../store/filtersStore";

const SpecimenModal: FC<{
  isDetails?: boolean;
  handleClose: () => void;
  bibliographicUnit: ILegacy | IOtherUnits | ISpecimen;
  getListOfSpecimens: () => Promise<void>;
  specimenID: number | null;
  isEditMode: boolean;
  getUnitDetails: () => Promise<void>;
}> = ({
  handleClose,
  bibliographicUnit,
  getListOfSpecimens,
  specimenID,
  isEditMode,
  isDetails,
  getUnitDetails,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const bibliographicUnitID =
    "bibliographicUnitID" in bibliographicUnit ? bibliographicUnit.bibliographicUnitID : 0;
  const { authState } = useAuthStore();
  const [loading, toggleLoading] = useState(false);
  const defaultInstitutionId = Number(authState?.institution?.id);
  const institutionName = authState?.institution?.name;
  const { getHolders } = useFilterStore();

  const formik = useFormik({
    initialValues: {
      bibliographicUnitID: bibliographicUnitID,
      signature: "",
      specimenID: specimenID || null,
      inventoryNumber: "",
      holder: { holderID: null, holderName: "" },
      institution: { institutionID: defaultInstitutionId, institutionName: institutionName },
      description: "",
      digitalCopyLink: "",
      hasDigitalization: false,
      hasMicrofilm: false,
      hasConservation: false,
      conservation: {
        sterilization: {
          yearOfProtection: "",
          protectionInstitution: "",
          isDone: false,
          note: "",
        },
        bookBlockConservation: {
          yearOfProtection: "",
          protectionInstitution: "",
          isComplete: false,
          note: "",
        },
        bindingRestauration: {
          yearOfProtection: "",
          protectionInstitution: "",
          isOriginalSaved: false,
          hasNewBinding: false,
          note: "",
        },
      },
    },
    validationSchema: SpecimenInfoValidationSchema,
    onSubmit: async () => {
      if (isSubmitting) return;
      setIsSubmitting(true);
      try {
        if (isEditMode && specimenID) {
          await updateSpecimen(finalCreateSpecimenDTO);
          createToastNotification("success", "Успешно измењен примерак!");
        } else {
          await createSpecimen(createSpecimenDTO);
          createToastNotification("success", "Успешно креиран примерак!");
        }
        handleClose();
        getListOfSpecimens();
        getUnitDetails();
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
      setIsSubmitting(false);
      getHolders();
    },
  });

  useEffect(() => {
    if (specimenID) {
      toggleLoading(true);
      const fetchSpecimenDetails = async () => {
        const specimenData = await fetchBibliographicUnits(specimenID, "specimen");
        const defaultConservation = {
          sterilization: {
            yearOfProtection: "",
            protectionInstitution: "",
            isDone: false,
            note: "",
          },
          bookBlockConservation: {
            yearOfProtection: "",
            protectionInstitution: "",
            isComplete: false,
            note: "",
          },
          bindingRestauration: {
            yearOfProtection: "",
            protectionInstitution: "",
            isOriginalSaved: false,
            hasNewBinding: false,
            note: "",
          },
        };

        const conservation = specimenData.conservation || defaultConservation;

        formik.setValues({
          bibliographicUnitID: specimenData.bibliographicUnit?.bibliographicUnitID || 0,
          signature: specimenData.signature || "",
          specimenID: specimenData.specimenID || specimenID,
          inventoryNumber: specimenData.inventoryNumber || "",
          holder: {
            holderID: specimenData.holder?.holderID || null,
            holderName: specimenData.holder?.holderName || "",
          },
          institution: {
            institutionID: specimenData.institution?.institutionID,
            institutionName: specimenData.institution?.institutionName,
          },
          description: specimenData.description || "",
          digitalCopyLink: specimenData.digitalCopyLink || "",
          hasDigitalization: Boolean(specimenData.hasDigitalization),
          hasMicrofilm: Boolean(specimenData.hasMicrofilm),
          hasConservation: Boolean(specimenData.hasConservation),
          conservation: {
            sterilization: {
              yearOfProtection: conservation.sterilization?.yearOfProtection || "",
              protectionInstitution: conservation.sterilization?.protectionInstitution || "",
              isDone: conservation.sterilization?.isDone || false,
              note: conservation.sterilization?.note || "",
            },
            bookBlockConservation: {
              yearOfProtection: conservation.bookBlockConservation?.yearOfProtection || "",
              protectionInstitution:
                conservation.bookBlockConservation?.protectionInstitution || "",
              isComplete: conservation.bookBlockConservation?.isComplete || false,
              note: conservation.bookBlockConservation?.note || "",
            },
            bindingRestauration: {
              yearOfProtection: conservation.bindingRestauration?.yearOfProtection || "",
              protectionInstitution: conservation.bindingRestauration?.protectionInstitution || "",
              isOriginalSaved: conservation.bindingRestauration?.isOriginalSaved || false,
              hasNewBinding: conservation.bindingRestauration?.hasNewBinding || false,
              note: conservation.bindingRestauration?.note || "",
            },
          },
        });
        toggleLoading(false);
      };

      fetchSpecimenDetails();
    }
    // eslint-disable-next-line
  }, [specimenID]);

  const createSpecimenDTO = {
    bibliographicUnitID: formik.values.bibliographicUnitID,
    signature: formik.values.signature,
    inventoryNumber: formik.values.inventoryNumber,
    holder: {
      holderID: formik.values.holder.holderID,
      holderName: formik.values.holder.holderName,
    },
    institutionID: formik.values.institution.institutionID,
    institution: {
      institutionID: formik.values.institution.institutionID,
      institutionName: formik.values.institution.institutionName,
    },
    description: formik.values.description,
    digitalCopyLink: formik.values.digitalCopyLink,
    hasDigitalization: Boolean(formik.values.hasDigitalization),
    hasMicrofilm: Boolean(formik.values.hasMicrofilm),
    hasConservation: Boolean(formik.values.hasConservation),
    conservation: {
      sterilization: {
        yearOfProtection: Number(formik.values.conservation.sterilization.yearOfProtection),
        protectionInstitution: formik.values.conservation.sterilization.protectionInstitution,
        isDone: Boolean(formik.values.conservation.sterilization.isDone),
        note: formik.values.conservation.sterilization.note,
      },
      bookBlockConservation: {
        yearOfProtection: Number(formik.values.conservation.bookBlockConservation.yearOfProtection),
        protectionInstitution:
          formik.values.conservation.bookBlockConservation.protectionInstitution,
        isComplete: Boolean(formik.values.conservation.bookBlockConservation.isComplete),
        note: formik.values.conservation.bookBlockConservation.note,
      },
      bindingRestauration: {
        yearOfProtection: Number(formik.values.conservation.bindingRestauration.yearOfProtection),
        protectionInstitution: formik.values.conservation.bindingRestauration.protectionInstitution,
        isOriginalSaved: Boolean(formik.values.conservation.bindingRestauration.isOriginalSaved),
        hasNewBinding: Boolean(formik.values.conservation.bindingRestauration.hasNewBinding),
        note: formik.values.conservation.bindingRestauration.note,
      },
    },
  };

  const finalCreateSpecimenDTO =
    isEditMode && formik.values.specimenID
      ? { ...createSpecimenDTO, specimenID: formik.values.specimenID }
      : createSpecimenDTO;

  return (
    <CustomModal
      title={isEditMode ? "Измени примерак" : "Додај примерак"}
      handleClose={handleClose}
      open
      fullWidth
      maxWidth="md"
    >
      <SpecimenInfoComponent
        bibliographicUnit={bibliographicUnit as IOtherUnits}
        isDetails={isDetails}
      />
      {!loading && <AddOrUpdateSpecimenInfo formik={formik} />}
      <ModalActions
        buttonAction={formik.submitForm}
        disabled={isSubmitting}
        buttonText="Сачувај"
        cancelBtnText="Назад"
        cancelAction={handleClose}
      />
    </CustomModal>
  );
};
export default SpecimenModal;
