import { create } from "zustand/react";
import { decodeUserFromToken } from "../utilities/helpers";
import Cookies from "js-cookie";
import axios from "axios";
import { LOGIN_PAGE } from "../common/constants/routes.constants";
import { BASE_URL } from "../api/utilities/http";

interface AuthState {
  accessToken?: string;
  refreshToken?: string;
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  institution: {
    id: number;
    name: string;
  };
}

interface IAuthStore {
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
  setTokensOnLogin: (token: string, refresh_token: string) => void;
  refreshToken: (failedReq?: any) => Promise<void>;
  clearTokensOnLogout: () => Promise<void>;
}
function updateAuthState(authState: AuthState): AuthState {
  if (authState.accessToken) {
    const userData = decodeUserFromToken(authState.accessToken);
    return {
      accessToken: authState.accessToken,
      refreshToken: authState.refreshToken,
      email: userData.email || "",
      firstName: userData.firstName || "",
      lastName: userData.lastName || "",
      role: userData.role || "",
      institution: {
        id: userData.institution?.id || 0,
        name: userData.institution?.name || "",
      },
    };
  }
  return authState;
}
export const useAuthStore = create<IAuthStore>((set, get) => ({
  authState: {
    accessToken: Cookies.get("accessToken"),
    refreshToken: Cookies.get("refreshToken"),
    email: "",
    role: "",
    firstName: "",
    lastName: "",
    institution: {
      id: 0,
      name: "",
    },
  },

  setAuthState: (authState: AuthState) => {
    const updatedState = updateAuthState(authState);
    set({ authState: updatedState });
  },

  setTokensOnLogin: (token: string, refresh_token: string) => {
    Cookies.set("accessToken", token);
    Cookies.set("refreshToken", refresh_token);

    const decodedUser = decodeUserFromToken(token);
    set({
      authState: {
        accessToken: token,
        refreshToken: refresh_token,
        email: decodedUser?.email,
        role: decodedUser?.role,
        firstName: decodedUser?.firstName,
        lastName: decodedUser?.lastName,
        institution: {
          id: decodedUser?.institution?.id,
          name: decodedUser?.institution?.name,
        },
      },
    });
  },

  refreshToken: async (failedReq?: any) => {
    try {
      const { refreshToken } = get().authState;

      if (!refreshToken) {
        return;
      }

      const response = await axios.post(
        `${BASE_URL}/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );

      const { accessToken, refreshToken: newRefreshToken } = response.data;

      const userData = decodeUserFromToken(accessToken);

      set({
        authState: {
          accessToken,
          refreshToken: newRefreshToken,
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          role: userData.role,
          institution: {
            id: userData.institution.id,
            name: userData.institution.name,
          },
        },
      });

      Cookies.set("accessToken", accessToken);
      Cookies.set("refreshToken", newRefreshToken);

      // if (failedReq) {
      //   failedReq.response.config.headers["Authorization"] = "Bearer " + accessToken;
      //   return axios(failedReq.response.config);
      // }

      return Promise.resolve();
    } catch (error: any) {
      await get().clearTokensOnLogout();
      console.error("Token refresh failed", error);
      window.location.href = LOGIN_PAGE;
      return Promise.reject(error);
    }
  },

  clearTokensOnLogout: async () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");

    set({
      authState: {
        accessToken: undefined,
        refreshToken: undefined,
        email: "",
        role: "",
        firstName: "",
        lastName: "",
        institution: {
          id: 0,
          name: "",
        },
      },
    });
  },
}));
