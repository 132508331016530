import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { IProclaimedCulturalAssets } from "../../../common/interfaces/fillter.interfaces";
import { getSignificanceLevelLabel } from "../../../utilities/helpers";

const ProclaimedCulturalAssetsTableRow: FC<{
  asset: IProclaimedCulturalAssets;
  index: number;
  handleRowClick: (id: number) => void;
}> = ({ asset, index, handleRowClick }) => {
  return (
    <TableRow key={index} onClick={() => handleRowClick(asset.id)}>
      <TableCell>{asset.name}</TableCell>
      <TableCell>{getSignificanceLevelLabel(asset.significanceLevel)}</TableCell>
      <TableCell>{asset.year}</TableCell>
    </TableRow>
  );
};

export default ProclaimedCulturalAssetsTableRow;
