import { Card, Typography } from "@mui/material";
import { FC } from "react";
import {
  ILegacy,
  IOtherUnits,
  ISpecimen,
} from "../../../common/interfaces/bibliographic-unit.interface";
import LegacyDetailsComponent from "./LegacyDetailsComponent/LegacyDetailsComponent";
import OtherUnitComponent from "./OtherUnitsComponent/OtherUnitComponent";
import SpecimenUnitComponent from "./SpecimenUnitComponent/SpecimenUnitComponent";

interface IBasicDetailsCardProps {
  bibliographicUnit: ILegacy | IOtherUnits | ISpecimen | undefined;
}

const BasicDetailsCard: FC<IBasicDetailsCardProps> = ({ bibliographicUnit }) => {
  if (!bibliographicUnit) return null;

  const isLegacy = (unit: any): unit is ILegacy => {
    return "specimenRangeFrom" in unit;
  };

  const isSpecimen = (unit: any): unit is ISpecimen => {
    return (unit as ISpecimen).specimenID !== undefined;
  };

  return (
    <Card className="basic-details-card" style={{ padding: "0" }}>
      <Typography
        variant="h6"
        style={{ padding: "32px 24px 16px", marginBottom: 0, fontSize: "18px" }}
      >
        Детаљи
      </Typography>
      {isSpecimen(bibliographicUnit) ? (
        <SpecimenUnitComponent bibliographicUnit={bibliographicUnit} />
      ) : isLegacy(bibliographicUnit) ? (
        <LegacyDetailsComponent bibliographicUnit={bibliographicUnit} />
      ) : (
        <OtherUnitComponent bibliographicUnit={bibliographicUnit} />
      )}
    </Card>
  );
};

export default BasicDetailsCard;
