import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import FreeSoloAutocompleteComponent from "../../FreeSoloAutocompleteComponent/FreeSoloAutocompleteComponent";
import { useFilterStore } from "../../../store/filtersStore";
import CustomSelectInput from "../../CustomSelectInputs/CustomSelectInput";
import "./AddOrUpdateSpecimenInfo.scss";
import { useAuthStore } from "../../../store/authStore";

interface StatusOption {
  value: string;
  label: string;
}

interface AddSpecimenProps {
  formik: any;
}
const AddOrUpdateSpecimenInfo: FC<AddSpecimenProps> = ({ formik }) => {
  const { holders, institutions, getInstitutions, getHolders } = useFilterStore();
  const { authState } = useAuthStore();
  const defaultInstitutionId = authState?.institution?.id?.toString() || "";
  const { values, errors, handleChange, setFieldValue } = formik;
  const optionsHolders = holders.map((holder) => ({
    id: holder.holderID,
    name: holder.holderName,
  }));

  const institutionOptions: StatusOption[] = institutions.map((inst) => ({
    value: inst.institutionID.toString(),
    label: inst.institutionName,
  }));

  useEffect(() => {
    if (institutions.length === 0) {
      getInstitutions();
    }
    if (holders.length === 0) {
      getHolders();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Stack spacing={2} mt={2} className="add-or-update-specimen-info">
      <Stack direction="row" spacing={2}>
        <TextField
          required
          fullWidth
          label="Сигнатура"
          name="signature"
          onChange={handleChange}
          value={values.signature}
        />
        <TextField
          required
          fullWidth
          label="Инвертарни број"
          name="inventoryNumber"
          value={values.inventoryNumber}
          onChange={handleChange}
        />
      </Stack>
      <FreeSoloAutocompleteComponent
        required
        options={optionsHolders}
        getOptionLabel={(option) => {
          return typeof option === "string" ? option : option.name;
        }}
        getOptionId={(option) => option.id}
        onInputChange={(event, value) => {
          const selectedHolder = holders.find((holder) => holder.holderName === value);
          if (selectedHolder) {
            setFieldValue("holder", {
              holderID: selectedHolder.holderID,
              holderName: selectedHolder.holderName,
            });
          } else {
            setFieldValue("holder", {
              holderID: null,
              holderName: value,
            });
          }
        }}
        value={
          values.holder ? { id: values.holder.holderID, name: values.holder.holderName } : null
        }
        isOptionEqualToValue={(option, value) =>
          typeof option !== "string" && typeof value !== "string" && option.id === value.id
        }
        label="Сопственик"
      />
      <CustomSelectInput
        values={institutionOptions}
        label="Институција која врши упис"
        onChange={(value) => {
          const selectedInstitution = institutions.find(
            (institution) => institution.institutionID === value
          );
          if (selectedInstitution) {
            setFieldValue("institution", {
              institutionID: selectedInstitution.institutionID,
              institutionName: selectedInstitution.institutionName,
            });
          } else {
            setFieldValue("institution", {
              institutionID: defaultInstitutionId,
              institutionName: authState?.institution?.name,
            });
          }
        }}
        preselectedValueIds={
          values?.institution?.institutionID
            ? [values.institution.institutionID.toString()]
            : [defaultInstitutionId]
        }
      />
      <TextField
        fullWidth
        label="Oпис и напомене"
        multiline
        rows={2}
        name="description"
        value={values.description}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Линк дигиталне копије"
        name="digitalCopyLink"
        value={values.digitalCopyLink}
        onChange={handleChange}
        error={!!errors?.digitalCopyLink}
        helperText={errors?.digitalCopyLink}
      />
      <FormGroup row className="add-or-update-specimen-info__checkbox-container">
        <FormControlLabel
          control={
            <Checkbox
              name="hasDigitalization"
              color="primary"
              onChange={handleChange}
              value={values.hasDigitalization}
              checked={values.hasDigitalization}
            />
          }
          label="Дигитализација"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="hasMicrofilm"
              color="primary"
              onChange={handleChange}
              value={values.hasMicrofilm}
              checked={values.hasMicrofilm}
            />
          }
          label="Микрофилм"
        />
      </FormGroup>
      <FormControlLabel
        control={
          <Switch
            name="hasConservation"
            color="primary"
            onChange={(e) => {
              const checked = e.target.checked;
              setFieldValue("hasConservation", checked);
              if (!checked) {
                setFieldValue("conservation", {
                  sterilization: {
                    yearOfProtection: "",
                    protectionInstitution: "",
                    isDone: false,
                    note: "",
                  },
                  bookBlockConservation: {
                    yearOfProtection: "",
                    protectionInstitution: "",
                    isComplete: false,
                    note: "",
                  },
                  bindingRestauration: {
                    yearOfProtection: "",
                    protectionInstitution: "",
                    isOriginalSaved: false,
                    hasNewBinding: false,
                    note: "",
                  },
                });
              }
            }}
            checked={values.hasConservation}
          />
        }
        label="Извршена конзервација"
      />
      {values.hasConservation && (
        <>
          <Stack
            direction="column"
            gap={2}
            p={1.5}
            className="add-or-update-specimen-info__input-container"
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Стерилизација</Typography>
            </Stack>
            <TextField
              fullWidth
              label="Година извршења стерилизације"
              name="conservation.sterilization.yearOfProtection"
              onChange={handleChange}
              value={
                values.conservation && formik.values.conservation
                  ? values.conservation.sterilization.yearOfProtection
                  : ""
              }
              error={!!errors?.conservation?.sterilization?.yearOfProtection}
              helperText={errors?.conservation?.sterilization?.yearOfProtection}
            />
            <TextField
              fullWidth
              label="Институција која је извршила стерилизацију"
              name="conservation.sterilization.protectionInstitution"
              onChange={handleChange}
              value={values.conservation?.sterilization?.protectionInstitution}
            />
            <TextField
              fullWidth
              label="Напомена"
              name="conservation.sterilization.note"
              onChange={handleChange}
              value={values.conservation?.sterilization?.note}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="conservation.sterilization.isDone"
                  color="primary"
                  onChange={handleChange}
                  checked={values.conservation.sterilization.isDone}
                />
              }
              label="Извршена стерилизација"
            />
          </Stack>
          <Stack
            direction="column"
            gap={2}
            p={1.5}
            className="add-or-update-specimen-info__input-container"
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h6">Конзервација књижног блока</Typography>
            </Stack>
            <TextField
              fullWidth
              label="Година извршења конзервације књижног блока"
              name="conservation.bookBlockConservation.yearOfProtection"
              onChange={handleChange}
              value={values.conservation?.bookBlockConservation?.yearOfProtection ?? ""}
              error={!!errors?.conservation?.bookBlockConservation?.yearOfProtection}
              helperText={errors?.conservation?.bookBlockConservation?.yearOfProtection}
            />
            <TextField
              fullWidth
              label="Институција која је извршила конзервацију књижног блока"
              name="conservation.bookBlockConservation.protectionInstitution"
              onChange={handleChange}
              value={values.conservation.bookBlockConservation.protectionInstitution}
            />
            <TextField
              fullWidth
              label="Напомена"
              name="conservation.bookBlockConservation.note"
              onChange={handleChange}
              value={values.conservation.bookBlockConservation.note}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="conservation.bookBlockConservation.isComplete"
                  color="primary"
                  onChange={handleChange}
                  checked={values.conservation.bookBlockConservation.isComplete}
                />
              }
              label="Извршена конзервација књижног блока"
            />
          </Stack>
          <Stack
            direction="column"
            gap={2}
            p={1.5}
            className="add-or-update-specimen-info__input-container"
          >
            <Typography variant="h6">Рестаурација повеза</Typography>
            <TextField
              fullWidth
              label="Година извршења рестаурације повеза"
              name="conservation.bindingRestauration.yearOfProtection"
              onChange={handleChange}
              value={values.conservation?.bindingRestauration?.yearOfProtection ?? ""}
              error={!!errors?.conservation?.bindingRestauration?.yearOfProtection}
              helperText={errors?.conservation?.bindingRestauration?.yearOfProtection}
            />
            <TextField
              fullWidth
              label="Институција која је извршила рестаурацију повеза"
              name="conservation.bindingRestauration.protectionInstitution"
              onChange={handleChange}
              value={values.conservation.bindingRestauration.protectionInstitution}
            />
            <TextField
              fullWidth
              label="Напомена"
              name="conservation.bindingRestauration.note"
              onChange={handleChange}
              value={values.conservation.bindingRestauration.note}
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    name="conservation.bindingRestauration.isOriginalSaved"
                    color="primary"
                    onChange={handleChange}
                    checked={values.conservation.bindingRestauration.isOriginalSaved}
                  />
                }
                label="Оригинални повез сачуван"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="conservation.bindingRestauration.hasNewBinding"
                    color="primary"
                    onChange={handleChange}
                    checked={values.conservation.bindingRestauration.hasNewBinding}
                  />
                }
                label="Постављен нови повез"
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
export default AddOrUpdateSpecimenInfo;
