import { FC } from "react";
import { IPublishedNews } from "../../../common/interfaces/news.interface";
import "./LatestNews.scss";
import { useNavigate } from "react-router";
import { NEWS_DETAILS } from "../../../common/constants/routes.constants";
import noImage from "../../../assets/no-picture-frame.png";

const LatestNews: FC<{
  publishedNews: IPublishedNews;
}> = ({ publishedNews }) => {
  const navigate = useNavigate();
  return (
    <div
      className="latest-news-content"
      onClick={() => {
        navigate(`${NEWS_DETAILS}/${publishedNews.newsID}`);
      }}
    >
      <div className="latest-news-content__image-wrapper">
        <img
          alt={publishedNews.title}
          src={publishedNews.imageURL ? publishedNews.imageURL : noImage}
        />
      </div>
      <div className="latest-news-content__description">
        <p className="latest-news-content__date">
          {publishedNews.publishedDate
            ? new Date(publishedNews.publishedDate).toLocaleDateString()
            : "/"}
        </p>
        <h4>{publishedNews.title}</h4>
      </div>
    </div>
  );
};
export default LatestNews;
