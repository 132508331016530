import React, { FC, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import { InputAdornment, Modal } from "@mui/material";
import Stack from "@mui/material/Stack";
import { ChangePasswordValidationSchema } from "../../common/validation-schemas/change-password.schema";
import { createToastNotification } from "../../utilities/helpers";
import { ChangePasswordDTO } from "../../common/dtos/common.dto";
import { useAuthStore } from "../../store/authStore";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { changePassword } from "../../api/login";
import PasswordEyeToggle from "../PasswordEyeToggle/PasswordEyeToggle";
import "./ChangePassword.scss";

const initialValues = {
  password: EMPTY_STRING,
  confirmedPassword: EMPTY_STRING,
};

const ChangePassword: FC<{
  closeChangePasswordModal: () => void;
}> = ({ closeChangePasswordModal }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const email = useAuthStore((state) => state.authState.email);

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: () => {
      handleChangePassword();
    },
  });

  const isFormValid = useMemo(() => {
    return formik.dirty && formik.isValid;
  }, [formik.dirty, formik.isValid]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmedPassword = () => setShowConfirmedPassword((show) => !show);

  const handleChangePassword = async () => {
    const changePasswordDto: ChangePasswordDTO = {
      email: email,
      password: formik.values.password,
      confirmedPassword: formik.values.confirmedPassword,
    };

    try {
      await changePassword(changePasswordDto);
      createToastNotification("success", "Успешно сте изменили лозинку!");
    } catch (error: any) {
      createToastNotification("error", error.response?.data?.message);
    } finally {
      closeChangePasswordModal();
      formik.resetForm();
    }
  };

  return (
    <Modal open={true} onBackdropClick={closeChangePasswordModal}>
      <CardContent className="change-password">
        <form noValidate onSubmit={formik.handleSubmit}>
          <p className="change-password__title">Промени лозинку</p>
          <TextField
            className="change-password__input"
            fullWidth
            id="password"
            name="password"
            label="Нова лозинка"
            margin="normal"
            variant="outlined"
            onChange={formik.handleChange}
            type={showPassword ? "text" : "password"}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PasswordEyeToggle
                    handleClickShowPassword={handleClickShowPassword}
                    showPassword={showPassword}
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            className="change-password__input"
            id="confirmedPassword"
            name="confirmedPassword"
            label="Потврда нове лозинке"
            type={showConfirmedPassword ? "text" : "password"}
            margin="normal"
            variant="outlined"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmedPassword}
            error={formik.touched.confirmedPassword && Boolean(formik.errors.confirmedPassword)}
            helperText={formik.touched.confirmedPassword && formik.errors.confirmedPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PasswordEyeToggle
                    handleClickShowPassword={handleClickShowConfirmedPassword}
                    showPassword={showConfirmedPassword}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="row" spacing={2} className="change-password__button-container">
            <Button
              className="change-password__button"
              variant="outlined"
              onClick={() => {
                closeChangePasswordModal();
                formik.resetForm();
              }}
            >
              Назад
            </Button>
            <Button
              disabled={!isFormValid}
              className="change-password__button"
              type="submit"
              variant="contained"
            >
              Сачувај
            </Button>
          </Stack>
        </form>
      </CardContent>
    </Modal>
  );
};

export default ChangePassword;
