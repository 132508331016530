export enum RestCodes {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Conflict = 409,
}

export enum DocumentType {
  Monograph = "monograph",
  Legacy = "legacy",
}

export enum Status {
  Declared = "declared",
  Recorded = "recorded",
  Rejected = "rejected",
}

export enum SignificanceLevels {
  Exceptional = "exceptional",
  Great = "great",
  BasicLevel = "basicLevel",
}

export enum BibliographicUnitType {
  PrintedBook = "printedBook",
  ManuscriptBook = "manuscriptBook",
  LegaciesAndLibraryCollections = "legaciesAndLibraryCollections",
}

export enum BibliographicUnitLanguage {
  Foreign = "strana",
  Serbian = "srpska",
}
export enum UserRole {
  MasterAdmin = "master-admin",
  Admin = "admin",
  ProtectiveLibrary = "protective-library",
}
