import { Box } from "@mui/material";
import BasicTable from "../../../components/BasicTable/BasicTable";
import { useEffect, useState } from "react";
import PublishedNewsTableRow from "../../../components/News/NewsTableRow/NewsTableRow";
import NoDataTableRow from "../../../components/NoDataTableRow/NoDataTableRow";
import { PUBLISHED_NEWS_TABLE_HEADINGS } from "../../../common/constants/news.constants";
import { IPublishedNews } from "../../../common/interfaces/news.interface";
import { ITableMetadata } from "../../../common/interfaces/common.interfaces";
import { initialMetadataState } from "../../../common/constants/common.constants";
import { createToastNotification } from "../../../utilities/helpers";
import { getPublishedNews } from "../../../api/news";
import { useNavigate } from "react-router-dom";
import { NEWS_DETAILS } from "../../../common/constants/routes.constants";

const ListOfPublishedNews = () => {
  const navigate = useNavigate();
  const [news, setNews] = useState<IPublishedNews[]>();
  const [metadata, setMetadata] = useState<ITableMetadata>(initialMetadataState);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [metadata.currentPage, metadata.itemsPerPage]);

  const fetchData = async () => {
    try {
      const response = await getPublishedNews(metadata.currentPage, metadata.itemsPerPage);
      setNews(response.news);
      setMetadata(response);
    } catch (error: any) {
      createToastNotification("error", "Дошло је до грешке приликом приказа листе вести", error);
    }
  };

  const handleRowClick = (id: number) => {
    navigate(`${NEWS_DETAILS}/${id}`);
  };

  return (
    <Box className="page-table">
      <BasicTable
        headings={PUBLISHED_NEWS_TABLE_HEADINGS}
        loader={false}
        metadata={metadata}
        setMetadata={setMetadata}
      >
        {news && news.length > 0 ? (
          news.map((item, index) => (
            <PublishedNewsTableRow
              key={index}
              handleRowClick={handleRowClick}
              index={index}
              item={item}
            />
          ))
        ) : (
          <NoDataTableRow message="Нема вести за приказ" />
        )}
      </BasicTable>
    </Box>
  );
};

export default ListOfPublishedNews;
