import { Box, ListItemText, MenuItem, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { FC, useEffect } from "react";
import { formatDate, getSignificanceLevelLabel, getStatusLabel } from "../../../utilities/helpers";
import { useFilterStore } from "../../../store/filtersStore";
import FreeSoloAutocompleteComponent from "../../FreeSoloAutocompleteComponent/FreeSoloAutocompleteComponent";
import CustomSelectInput from "../../CustomSelectInputs/CustomSelectInput";
import { useAuthStore } from "../../../store/authStore";

interface LegacyContainerProps {
  formik: any;
}

interface StatusOption {
  value: string;
  label: string;
}

const LegacyLibrariesContainer: FC<LegacyContainerProps> = ({ formik }) => {
  const {
    significanceLevel,
    statuses,
    holders,
    institutions,
    getStatuses,
    getSignificanceLevels,
    getHolders,
    getInstitutions,
  } = useFilterStore();
  const { authState } = useAuthStore();
  const defaultInstitutionId = authState?.institution?.id?.toString() || "";
  const allStatuses = statuses.recordStatus || [];
  const allSignificanceLevels = significanceLevel.significanceLevels || [];
  const institutionOptions: StatusOption[] = institutions.map((inst) => ({
    value: inst.institutionID.toString(),
    label: inst.institutionName,
  }));

  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = formik;
  useEffect(() => {
    getStatuses();
    getSignificanceLevels();
    getHolders();
    getInstitutions();
    // eslint-disable-next-line
  }, []);

  const optionsHolders = holders.map((holder) => ({
    id: holder.holderID,
    name: holder.holderName,
  }));

  return (
    <Box>
      <Stack direction="column" spacing={2} p={1}>
        <TextField
          fullWidth
          label="Назив"
          required
          name="legacy.title"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.legacy.title}
          error={touched.legacy?.title && Boolean(errors.legacy?.title)}
          helperText={touched.legacy?.title && errors.legacy?.title}
        />
        <FreeSoloAutocompleteComponent
          options={optionsHolders}
          getOptionLabel={(option) => {
            return typeof option === "string" ? option : option.name;
          }}
          getOptionId={(option) => option.id}
          onInputChange={(event, value) => {
            const selectedHolder = holders.find((holder) => holder.holderName === value);
            if (selectedHolder) {
              setFieldValue("legacy.holder", {
                holderID: selectedHolder.holderID,
                holderName: selectedHolder.holderName,
              });
            } else {
              setFieldValue("legacy.holder", {
                holderID: null,
                holderName: value,
              });
            }
          }}
          label="Сопственик"
          error={touched.legacy?.holderID && Boolean(errors.legacy?.holderID)}
          helperText={touched.legacy?.holderID && errors.legacy?.holderID}
        />
        <CustomSelectInput
          values={institutionOptions}
          label="Институција која врши упис"
          onChange={(value) => {
            const selectedInstitution = institutions.find(
              (institution) => institution.institutionID === value
            );
            if (selectedInstitution) {
              formik.setFieldValue("legacy.institutionID", selectedInstitution.institutionID);
            }
          }}
          preselectedValueIds={[defaultInstitutionId]}
        />
        <Stack direction="row" spacing={3}>
          <TextField
            fullWidth
            label="Статус записа"
            required
            select
            name="legacy.recordStatus"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.legacy.recordStatus}
            error={touched.legacy?.recordStatus && Boolean(errors.legacy?.recordStatus)}
            helperText={touched.legacy?.recordStatus && errors.legacy?.recordStatus}
          >
            {allStatuses.length > 0 &&
              allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <ListItemText primary={getStatusLabel(status)} />
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label="Ниво значаја"
            required
            select
            name="legacy.significanceLevel"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.legacy.significanceLevel}
            error={touched.legacy?.significanceLevel && Boolean(errors.legacy?.significanceLevel)}
            helperText={touched.legacy?.significanceLevel && errors.legacy?.significanceLevel}
          >
            {allSignificanceLevels.length > 0 &&
              allSignificanceLevels.map((significance) => (
                <MenuItem key={significance} value={significance}>
                  <ListItemText primary={getSignificanceLevelLabel(significance)} />
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextField
            sx={{ width: "50%" }}
            label="Година проглашења"
            name="legacy.yearOfProclamation"
            onChange={handleChange}
            value={values.legacy.yearOfProclamation}
          />
          <DatePicker
            label="Датум уписа"
            format="DD-MM-YYYY"
            onChange={(value) => {
              const formattedDate = formatDate(value!.toISOString());
              setFieldValue("legacy.dateOfRegistration", formattedDate);
            }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <TextField
            sx={{ width: "50%" }}
            label="Година грађе од"
            name="legacy.specimenRangeFrom"
            onChange={handleChange}
            value={values.legacy.specimenRangeFrom}
          />
          <TextField
            sx={{ width: "50%" }}
            label="Година грађе до"
            name="legacy.specimenRangeТо"
            onChange={handleChange}
            value={values.legacy.specimenRangeTo}
          />
        </Stack>
        <TextField
          fullWidth
          label="Oпис и напомене"
          multiline
          rows={3}
          name="legacy.description"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.legacy.description}
          error={touched.legacy?.description && Boolean(errors.legacy?.description)}
          helperText={touched.legacy?.description && errors.legacy?.description}
        />
      </Stack>
    </Box>
  );
};
export default LegacyLibrariesContainer;
