import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { IOtherUnits } from "../../../../common/interfaces/bibliographic-unit.interface";
import "./DigitalObjectCard.scss";
import pdfIcon from "../../../../assets/icons/files/pdf.png";
import wordIcon from "../../../../assets/icons/files/doc.png";
import excelIcon from "../../../../assets/icons/files/xls.png";
import pptIcon from "../../../../assets/icons/files/ppt.png";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Box from "@mui/material/Box";

const DigitalObjectCard: FC<{ bibliographicUnit: IOtherUnits }> = ({ bibliographicUnit }) => {
  const [attachment, setAttachment] = useState<File | null>(null);

  const isImageFile = (file: File) => {
    let fileExtension = file.name.split(".").pop();
    return (
      file.type.startsWith("image/") ||
      (fileExtension && ["png", "jpeg", "jpg", "webp"].includes(fileExtension))
    );
  };

  useEffect(() => {
    populateAttachment();
    // eslint-disable-next-line
  }, [bibliographicUnit?.mediaURL]);

  const populateAttachment = async () => {
    if (!bibliographicUnit?.mediaURL) {
      return;
    }

    const attachmentResponse = await fetch(bibliographicUnit.mediaURL);
    let fileName = new URL(bibliographicUnit?.mediaURL).pathname.split("/").pop() ?? "";
    let fileNameArr = fileName.split("-");
    if (fileNameArr[5]) {
      fileName = fileName.replaceAll(
        fileNameArr[0] +
          "-" +
          fileNameArr[1] +
          "-" +
          fileNameArr[2] +
          "-" +
          fileNameArr[3] +
          "-" +
          fileNameArr[4] +
          "-",
        ""
      );
    }
    fileName = decodeURIComponent(fileName);
    const blob = await attachmentResponse.blob();
    const attachment = new File([blob], fileName, { type: blob.type });
    setAttachment(attachment);
  };

  const getFileIcon = (file: File) => {
    const fileType = file.name.split(".").pop();
    switch (fileType) {
      case "pdf":
        return <img src={pdfIcon} alt="pdf icon" style={{ width: "50px", height: "50px" }} />;
      case "docx":
      case "doc":
        return <img src={wordIcon} alt="word icon" style={{ width: "50px", height: "50px" }} />;
      case "xlsx":
      case "xls":
        return <img src={excelIcon} alt="excel icon" style={{ width: "50px", height: "50px" }} />;
      case "pptx":
      case "ppt":
        return <img src={pptIcon} alt="ppt icon" style={{ width: "50px", height: "50px" }} />;
      default:
        return <AttachmentIcon style={{ fontSize: "50px", color: "#555" }} />;
    }
  };

  const downloadFile = () => {
    if (attachment) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(attachment);
      link.download = attachment.name;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  return (
    <Card className="digital-object-card">
      <CardHeader title="Дигитални објекат" />
      <CardContent className="digital-object-card__image-container">
        {attachment ? (
          isImageFile(attachment) ? (
            <img
              src={bibliographicUnit.mediaURL}
              alt="digital-object"
              onClick={downloadFile}
              style={{ cursor: "pointer", width: "100%", height: "200px" }}
            />
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              onClick={downloadFile}
              sx={{ ":hover": { cursor: "pointer" } }}
            >
              {getFileIcon(attachment)}
              <Typography variant="caption">{attachment.name}</Typography>
            </Box>
          )
        ) : (
          <Typography variant="body2">Нема доступних медија</Typography>
        )}
      </CardContent>
    </Card>
  );
};
export default DigitalObjectCard;
