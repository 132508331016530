import { IHolderDetailsSpecimen } from "../../../common/interfaces/holders.interface";
import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";

export const HolderDetailsSpecimenTableRow: FC<{
  index: number;
  specimen: IHolderDetailsSpecimen;
  handleRowClick: () => void;
}> = ({ specimen, handleRowClick, index }) => {
  return (
    <TableRow key={index} onClick={handleRowClick}>
      <TableCell>{specimen.title}</TableCell>
      <TableCell>{specimen?.authors?.join(', ')}</TableCell>
      <TableCell>{specimen.yearOfPublication}</TableCell>
      <TableCell>{specimen.placeOfIssue}</TableCell>
      <TableCell>{specimen.cobbisID}</TableCell>
      <TableCell>{specimen.nationalBibliographyNumber}</TableCell>
      <TableCell>{specimen.signature}</TableCell>
      <TableCell>{specimen.inventoryNumber}</TableCell>
      <TableCell>{specimen.institutionName}</TableCell>
    </TableRow>
  );
};
