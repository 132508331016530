import CustomModal from "../../../components/Modal/CustomModal";
import UserDetails from "../UserDetails/UserDetails";
import { FC } from "react";
import { useFormik } from "formik";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { IUsers } from "../../../common/interfaces/users.interface";
import { editUserDetail } from "../../../api/users";
import { createToastNotification } from "../../../utilities/helpers";
import ChangePasswordForUser from "../ChangePasswordForUser/ChangePasswordForUser";
import { Stack } from "@mui/material";

interface UserModalProps {
  handleClose: () => void;
  userDetails: IUsers | undefined;
  getListOfUsers: (
    sortField: string,
    sortOrder?: "asc" | "desc" | string | undefined | null
  ) => Promise<void>;
}
const UserModal: FC<UserModalProps> = ({ handleClose, userDetails, getListOfUsers }) => {
  const formik = useFormik({
    initialValues: {
      user: {
        id: userDetails?.id,
        firstname: userDetails?.firstname || EMPTY_STRING,
        lastname: userDetails?.lastname || EMPTY_STRING,
        email: userDetails?.email || EMPTY_STRING,
        institution: {
          institutionID: userDetails?.institution.institutionID || 0,
          institutionName: userDetails?.institution.institutionName || EMPTY_STRING,
        },
        role: userDetails?.role || EMPTY_STRING,
      },
      password: {
        email: userDetails?.email || EMPTY_STRING,
        password: EMPTY_STRING,
        confirmedPassword: EMPTY_STRING,
      },
    },
    onSubmit: async (values) => {
      try {
        const userData = {
          id: userDetails?.id,
          firstname: values?.user.firstname || EMPTY_STRING,
          lastname: values?.user?.lastname || EMPTY_STRING,
          email: values?.user?.email || EMPTY_STRING,
          role: values?.user?.role || EMPTY_STRING,
          institution: values.user?.institution.institutionID,
        };
        await editUserDetail(userData);
        handleClose();
        createToastNotification("success", "Успешно измењен корисник.");
        getListOfUsers("");
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
    },
  });

  return (
    <CustomModal title="Детаљи о кориснику" handleClose={handleClose} open fullWidth maxWidth="md">
      <Stack direction="column" spacing={5}>
        <UserDetails formik={formik} />
        <ChangePasswordForUser handleClose={handleClose} userDetails={userDetails} />
      </Stack>
    </CustomModal>
  );
};
export default UserModal;
