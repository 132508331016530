import {NewsStatus, NewsStatusLabel} from "../common/enums/news.enums";

export const formatDateFromApi = (date: string) => {
    const dateString = new Date(date);
    const day = dateString.getDate().toString().padStart(2, "0");
    const month = (dateString.getMonth() + 1).toString().padStart(2, "0");
    const year = dateString.getFullYear();

    return `${day}-${month}-${year}`;
};

export const getNewsStatusChipColor = (status: NewsStatus) => {
    switch (status) {
        case NewsStatus.Published:
            return { backgroundColor: "#15B79E1F", color: "#107569" };
        case NewsStatus.Draft:
            return { backgroundColor: "#E5E7EB", color: "#6C737F" };
        default:
            return { backgroundColor: "grey", color: "black" };
    }
};

export const getNewsStatusLabel = (status: NewsStatus) => {
    switch (status) {
        case NewsStatus.Published:
            return NewsStatusLabel.Published;
        case NewsStatus.Draft:
            return NewsStatusLabel.Draft;
        default:
            return "";
    }
};