import React from "react";
import { Route, Routes as Router } from "react-router";
import CentralRegistry from "./pages/CentralRegistry/CentralRegistry";
import {
  CENTRAL_REGISTRY,
  LIST_OF_CULTURAL_ASSETS,
  HOME_PAGE,
  USERS,
  ADMIN_NEWS_DETAILS,
  ALL_NEWS,
  NEWS,
  NEWS_DETAILS,
  ADD_NEWS,
  EDIT_NEWS,
  HOLDER_DETAILS,
  HOLDERS,
  LIST_OF_INSTITUTIONS,
} from "./common/constants/routes.constants";
import Login from "./pages/Login/Login";
import { LOGIN } from "./api/utilities/api.urls";
import Layout from "./layout";
import Home from "./pages/Home/Home";
import BibliographicUnitDetails from "./pages/BibliographicUnitDetails/BibliographicUnitDetails";
import Institutions from "./pages/Institutions/Institutions";
import Users from "./pages/Users/Users";
import NotFound from "./pages/NotFound/NotFound";
import ListOfPublishedNews from "./pages/News/ListOfPublishedNews/ListOfPublishedNews";
import ListOfAllNews from "./pages/News/ListOfAllNews/ListOfAllNews";
import DetailsPublicNews from "./pages/News/DetailsPublicNews/DetailsPublicNews";
import DetailsNews from "./pages/News/DetailsNews/DetailsNews";
import { AddNews } from "./pages/News/AddNews/AddNews";
import { EditNews } from "./pages/News/EditNews/EditNews";
import ListOfProclaimedCulturalAssets from "./pages/ListOfProclaimedCulturalAssets/ListOfProclaimedCulturalAssets";
import ListOfProclaimedAssetsDetails from "./pages/ListOfProclaimedAssetsDetails/ListOfProclaimedAssetsDetails";
import { Holders } from "./pages/Holders/Holders";
import { HolderDetails } from "./pages/Holders/HolderDetails";

const Routes = () => {
  return (
    <Router>
      <Route element={<Layout />}>
        <Route element={<Home />} path={HOME_PAGE} />
        <Route element={<CentralRegistry />} path={CENTRAL_REGISTRY} />
        <Route element={<Institutions />} path={LIST_OF_INSTITUTIONS} />
        <Route element={<ListOfPublishedNews />} path={NEWS} />
        <Route element={<ListOfAllNews />} path={ALL_NEWS} />
        <Route element={<DetailsPublicNews />} path={`${NEWS_DETAILS}/:id`} />
        <Route element={<DetailsNews />} path={`${ADMIN_NEWS_DETAILS}/:id`} />
        <Route element={<BibliographicUnitDetails />} path="/details/:id/:itemType" />
        <Route element={<AddNews />} path={ADD_NEWS} />
        <Route element={<EditNews />} path={`${EDIT_NEWS}/:id`} />
        <Route element={<Users />} path={USERS} />
        <Route element={<ListOfProclaimedCulturalAssets />} path={LIST_OF_CULTURAL_ASSETS} />
        <Route element={<ListOfProclaimedAssetsDetails />} path="/asset-details/:id" />
        <Route element={<Holders />} path={HOLDERS} />
        <Route element={<HolderDetails />} path={`${HOLDER_DETAILS}/:id`} />
      </Route>
      <Route element={<Login />} path={LOGIN} />
      <Route element={<NotFound />} path="*" />
    </Router>
  );
};

export default Routes;
