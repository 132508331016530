import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { loginValidationSchema } from "../../common/validation-schemas/login.schema";
import { useFormik } from "formik";
import { LoginDto } from "../../common/dtos/common.dto";
import logoNBS from "../../../src/assets/logoNBS.svg";
import { login } from "../../api/login";
import { createToastNotification } from "../../utilities/helpers";
import { EMPTY_STRING } from "../../common/constants/common.constants";
import { useNavigate } from "react-router";
import "./Login.scss";
import { useAuthStore } from "../../store/authStore";
import { HOME_PAGE } from "../../common/constants/routes.constants";
import PasswordEyeToggle from "../../components/PasswordEyeToggle/PasswordEyeToggle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useLoadingStore from "../../store/LoadingStore";

const initialValues: LoginDto = {
  email: EMPTY_STRING,
  password: EMPTY_STRING,
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { setLoading } = useLoadingStore();
  const navigate = useNavigate();
  const { authState, setTokensOnLogin } = useAuthStore();
  const formik = useFormik({
    initialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (): Promise<any> => await handleSubmitForm(),
  });

  useEffect(() => {
    if (authState.accessToken) {
      navigate(HOME_PAGE);
    }
  }, [navigate, authState.accessToken]);

  const handleSubmitForm = async () => {
    const loginDto: LoginDto = {
      email: formik.values.email,
      password: formik.values.password,
    };
    setLoading(true);
    try {
      const response = await login(loginDto);
      const { refreshToken, accessToken } = response;
      setTokensOnLogin(accessToken, refreshToken);
      navigate(HOME_PAGE);
    } catch (error: any) {
      createToastNotification("error", error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const isFormValid = useMemo(() => {
    return formik.isValid && formik.dirty;
  }, [formik.dirty, formik.isValid]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Stack direction="row" className="login-wrapper">
      <Box className="login-logo">
        <img src={logoNBS} width={290} height={290} alt={"Hero img"} />
      </Box>
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <Stack
          mb={2}
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate(HOME_PAGE)}
        >
          <ArrowBackIcon sx={{ height: "20px", mr: 1 }} />
          Назад на почетну страну
        </Stack>
        <Typography variant="h5" fontWeight={700}>
          Пријава
        </Typography>
        <TextField
          error={!!(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Имејл адреса"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
        />
        <TextField
          error={!!(formik.touched.password && formik.errors.password)}
          fullWidth
          helperText={formik.touched.password && formik.errors.password}
          label="Лозинка"
          name="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type={showPassword ? "text" : "password"}
          value={formik.values.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PasswordEyeToggle
                  handleClickShowPassword={handleClickShowPassword}
                  showPassword={showPassword}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button fullWidth size="large" type="submit" variant="contained" disabled={!isFormValid}>
          Пријави се
        </Button>
      </form>
    </Stack>
  );
};
export default Login;
