import DetailsCardRow from "../DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { IOtherUnits } from "../../../../common/interfaces/bibliographic-unit.interface";

const OtherUnitComponent: FC<{ bibliographicUnit: IOtherUnits }> = ({ bibliographicUnit }) => {
  return (
    <>
      <DetailsCardRow
        title="Број нац. библиографије"
        content={bibliographicUnit?.nationalBibliographyNumber}
      />
      <DetailsCardRow title="Наслов" content={bibliographicUnit?.title} />
      <DetailsCardRow title="Аутор" content={ bibliographicUnit?.authors
          ?.map(author => author.authorName)
          .join(', ') || 'Непознат аутор'} />
      <DetailsCardRow title="Година проглашења" content={bibliographicUnit?.yearOfProclamation} />
      <DetailsCardRow title="Место издања" content={bibliographicUnit?.placeOfIssue} />
      <DetailsCardRow title="Година издања" content={bibliographicUnit?.yearOfPublication} />
      <DetailsCardRow title="Издавач" content={bibliographicUnit?.publisher} />
      <DetailsCardRow
        title="Линк дигиталне библиотеке"
        content={bibliographicUnit?.digitalLibraryLink}
      />
      <DetailsCardRow
        title="Порекло"
        content={bibliographicUnit?.isForeign ? "Страно" : "Српско"}
      />
      {bibliographicUnit?.writingMaterial && (
        <DetailsCardRow
          title="Писани материјал"
          content={bibliographicUnit?.writingMaterial ?? "Није доступно"}
        />
      )}
      {bibliographicUnit?.signature && (
        <DetailsCardRow
          title="Сигнатура"
          content={bibliographicUnit?.signature ?? "Није доступно"}
        />
      )}
    </>
  );
};
export default OtherUnitComponent;
