import React, { FC, useEffect, useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";
import { IOtherUnits } from "../../common/interfaces/bibliographic-unit.interface";
import { ListItemText, MenuItem, Paper, Radio, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import {
  createToastNotification,
  formatDate,
  getSignificanceLevelLabel,
  getStatusLabel,
} from "../../utilities/helpers";
import { DatePicker } from "@mui/x-date-pickers";
import OtherResponsibilitiesCustomComponent from "../../components/OtherResponsibilityComponent/otherResponsibilityComponent";
import FileUploaderZone from "../../uploader/FileUploaderZone/FileUploaderZone";
import { useFilterStore } from "../../store/filtersStore";
import ModalActions from "../../components/Modal/ModalActions";
import { editDetailsForMonographicUnit } from "../../api/publications";
import dayjs from "dayjs";
import { AuthorsComponent } from "../../components/AuthorsComponent/AuthorsComponent";
import { fetchAuthors, fetchOtherResponsibilities } from "../../api/filters";
import { editValidation } from "../../common/validation-schemas/publications.shema";

const MonoEditModal: FC<{
  bibliographicUnit: IOtherUnits | undefined;
  handleClose: () => void;
  getUnitDetails: () => Promise<void>;
}> = ({ bibliographicUnit, handleClose, getUnitDetails }) => {
  function removeEmptyFields(obj: Record<string, any>): Record<string, any> {
    return Object.entries(obj)
      .filter(([_, value]) => value !== undefined && value !== null && value !== "")
      .reduce(
        (acc, [key, value]) => {
          if (key === "holder" && value.holderName === "") {
            return acc;
          }
          if (key === "yearOfProclamation" && value === 0) {
            return acc;
          }
          acc[key] = value;
          return acc;
        },
        {} as Record<string, any>
      );
  }

  const formik = useFormik({
    initialValues: {
      isHandwriting: bibliographicUnit?.isHandwriting,
      title: bibliographicUnit?.title,
      authors: bibliographicUnit?.authors,
      otherResponsibilities:
        (bibliographicUnit?.otherResponsibilities || []).map((item) => ({
          id: item.id,
          name: item.name,
        })) || [],
      isForeign: bibliographicUnit?.isForeign,
      yearOfPublication: bibliographicUnit?.yearOfPublication,
      dateOfRegistration: bibliographicUnit?.dateOfRegistration,
      placeOfIssue: bibliographicUnit?.placeOfIssue,
      numberOfPages: bibliographicUnit?.numberOfPages,
      dimension: bibliographicUnit?.dimension,
      digitalLibraryLink: bibliographicUnit?.digitalLibraryLink,
      cobissID: bibliographicUnit?.cobissID,
      nationalBibliographyNumber: bibliographicUnit?.nationalBibliographyNumber,
      media: null,
      recordStatus: bibliographicUnit?.recordStatus,
      significanceLevel: bibliographicUnit?.significanceLevel,
      yearOfProclamation: bibliographicUnit?.yearOfProclamation,
      writingMaterial: bibliographicUnit?.writingMaterial,
      signatureBibliographicUnit: bibliographicUnit?.signature,
    },
    validationSchema: editValidation,
    onSubmit: async () => {
      try {
        await editDetailsForMonographicUnit(
          bibliographicUnit!.bibliographicUnitID,
          removeEmptyFields({
            isHandwriting: formik.values?.isHandwriting,
            title: formik.values?.title,
            authors: formik.values?.authors,
            otherResponsibilities: formik.values?.otherResponsibilities,
            isForeign: formik.values?.isForeign,
            yearOfPublication: formik.values?.yearOfPublication,
            dateOfRegistration: formik.values?.dateOfRegistration,
            placeOfIssue: formik.values?.placeOfIssue,
            numberOfPages: formik.values?.numberOfPages,
            dimension: formik.values?.dimension,
            digitalLibraryLink: formik.values?.digitalLibraryLink,
            cobissID: formik.values?.cobissID,
            nationalBibliographyNumber: formik.values?.nationalBibliographyNumber,
            media: formik.values?.media,
            recordStatus: formik.values?.recordStatus,
            significanceLevel: formik.values?.significanceLevel,
            yearOfProclamation: formik.values?.yearOfProclamation,
            writingMaterial: formik.values?.writingMaterial,
            signatureBibliographicUnit: formik.values?.signatureBibliographicUnit,
          })
        );
        handleClose();
        createToastNotification("success", "Успешно извршена измена!");
        await getUnitDetails();
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
    },
  });

  const {
    statuses,
    getStatuses,
    significanceLevel,
    otherResponsibilities,
    getSignificanceLevels,
    getOtherResponsibilities,
  } = useFilterStore();
  const allStatuses = statuses.recordStatus || [];
  const allSignificanceLevels = significanceLevel.significanceLevels || [];
  const [files, setFiles] = useState<File[] | null>(null);
  const handleFileChange = (files: File[] | null) => {
    setFiles(files);
    formik.setFieldValue("media", files);
  };
  const [optionsAuthor, setOptionsAuthor] = useState<{ authorId: number; authorName: string }[]>(
    []
  );

  const handleRemoveFile = (index: number) => {
    if (!files) {
      setFiles([]);
      formik.setFieldValue("media", []);
      return;
    }

    const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
    setFiles(updatedFiles);
    formik.setFieldValue("media", updatedFiles);
  };

  useEffect(() => {
    populateAuthors();
    populateOtherResp();
    getStatuses();
    getSignificanceLevels();
    getOtherResponsibilities();
    populateUploadedFile();
    // eslint-disable-next-line
  }, []);

  const populateUploadedFile = async () => {
    if (!bibliographicUnit?.mediaURL) {
      return;
    }

    const attachmentResponse = await fetch(bibliographicUnit.mediaURL);
    let fileName = new URL(bibliographicUnit?.mediaURL).pathname.split("/").pop() ?? "";
    let fileNameArr = fileName.split("-");
    if (fileNameArr[5]) {
      fileName = fileName.replaceAll(
        fileNameArr[0] +
          "-" +
          fileNameArr[1] +
          "-" +
          fileNameArr[2] +
          "-" +
          fileNameArr[3] +
          "-" +
          fileNameArr[4] +
          "-",
        ""
      );
    }
    fileName = decodeURIComponent(fileName);
    const blob = await attachmentResponse.blob();
    const attachment = new File([blob], fileName, { type: blob.type });
    handleFileChange([attachment]);
  };

  const populateAuthors = async () => {
    const response = await fetchAuthors(1, 50);
    const authors = response.authors as { authorID: number; authorName: string }[];
    setOptionsAuthor(
      authors.map((author) => ({
        authorId: author.authorID,
        authorName: author.authorName,
      }))
    );
  };

  const [optionsOtherResp, setOptionsOtherResp] = useState<{ id: number | null; name: string }[]>(
    []
  );
  const populateOtherResp = async () => {
    const response = await fetchOtherResponsibilities(1, 50);
    const other = response.responsibilities as { id: number | null; name: string }[];
    setOptionsOtherResp(
      other.map((resp) => ({
        id: resp.id,
        name: resp.name,
      }))
    );
  };

  return (
    <CustomModal open={true} title="Измени библиографску јединицу" handleClose={handleClose}>
      <Stack direction="column" spacing={2} p={1}>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Број националне библиографије"
            name="nationalBibliographyNumber"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.nationalBibliographyNumber}
          />
          <TextField
            fullWidth
            label="Начин израде"
            required
            select
            name="isHandwriting"
            onChange={formik.handleChange}
            value={formik.values.isHandwriting}
          >
            <MenuItem value="true">Рукописно</MenuItem>
            <MenuItem value="false">Штампано</MenuItem>
          </TextField>
        </Stack>
        <TextField
          fullWidth
          label="Наслов"
          required
          name="title"
          onChange={formik.handleChange}
          value={formik.values.title}
        />
        <AuthorsComponent
          setSearchValue={(value) => {
            if (Array.isArray(value)) {
              const updatedAuthors = value.map((selectedValue) => {
                if (typeof selectedValue === "string") {
                  return { authorId: null, authorName: selectedValue };
                } else {
                  const selectedAuthor = optionsAuthor.find(
                    (author) => author.authorName === selectedValue.authorName
                  );
                  return selectedAuthor
                    ? { authorId: selectedAuthor.authorId, authorName: selectedAuthor.authorName }
                    : { authorId: null, authorName: selectedValue.authorName };
                }
              });
              formik.setFieldValue("authors", updatedAuthors);
            }
          }}
          searchTitle="Аутори"
          optionsData={optionsAuthor}
          updateAuthorOptions={(authors) => {
            setOptionsAuthor([
              ...optionsAuthor,
              ...authors
                .map((author) => ({ authorId: author.authorID, authorName: author.authorName }))
                .filter(
                  (author) =>
                    !optionsAuthor.some(
                      (existingAuthor) => existingAuthor.authorId === author.authorId
                    )
                ),
            ]);
          }}
          getOptionLabel={(a) => a.authorName}
          getOptionId={(a) => a.authorId}
          value={
            formik.values.authors
              ? formik.values.authors.map((item) => ({
                  authorId: item.authorID,
                  authorName: item.authorName,
                }))
              : []
          }
        />
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Година издања"
            className="publication-form-container__date"
            name="yearOfPublication"
            onChange={formik.handleChange}
            value={formik.values.yearOfPublication}
          />
          <TextField
            fullWidth
            label="Meсто издања"
            className="publication-form-container__date"
            name="placeOfIssue"
            onChange={formik.handleChange}
            value={formik.values.placeOfIssue}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Статус записа"
            required
            select
            name="recordStatus"
            onChange={formik.handleChange}
            value={formik.values.recordStatus}
          >
            {allStatuses.length > 0 &&
              allStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <ListItemText primary={getStatusLabel(status)} />
                </MenuItem>
              ))}
          </TextField>
          <TextField
            fullWidth
            label="Ниво значаја"
            required
            select
            name="significanceLevel"
            onChange={formik.handleChange}
            value={formik.values.significanceLevel}
          >
            {allSignificanceLevels.length > 0 &&
              allSignificanceLevels.map((significance) => (
                <MenuItem key={significance} value={significance}>
                  <ListItemText primary={getSignificanceLevelLabel(significance)} />
                </MenuItem>
              ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Година проглашења"
            name="yearOfProclamation"
            onChange={formik.handleChange}
            value={formik.values.yearOfProclamation}
          />
          <TextField
            fullWidth
            label="Cobiss/Bisis ID"
            name="cobissID"
            value={formik.values.cobissID}
            onChange={formik.handleChange}
          />
        </Stack>
        {formik.values.isHandwriting === true && (
          <Stack direction="row" spacing={2}>
            <TextField
              sx={{ width: "50%" }}
              label="Материјал за писање"
              name="writingMaterial"
              onChange={formik.handleChange}
              value={formik.values.writingMaterial}
            />
            <TextField
              sx={{ width: "50%" }}
              label="Сигнатура"
              name="signatureBibliographicUnit"
              onChange={formik.handleChange}
              value={formik.values.signatureBibliographicUnit}
            />
          </Stack>
        )}
        <DatePicker
          format="DD-MM-YYYY"
          label="Датум регистрације"
          onChange={(value) => {
            const formattedDate = formatDate(value!.toISOString());
            formik.setFieldValue("dateOfRegistration", formattedDate);
          }}
          value={formik.values.dateOfRegistration ? dayjs(formik.values.dateOfRegistration) : null}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            label="Број страна/листова"
            name="numberOfPages"
            value={formik.values.numberOfPages}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            label="Димензије"
            name="dimension"
            onChange={formik.handleChange}
            value={formik.values.dimension}
          />
        </Stack>
        <TextField
          fullWidth
          label="Линк ка дигиталним библиотекама"
          name="digitalLibraryLink"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.digitalLibraryLink}
          error={formik.touched.digitalLibraryLink && Boolean(formik.errors.digitalLibraryLink)}
          helperText={formik.touched.digitalLibraryLink && formik.errors.digitalLibraryLink}
        />

        <OtherResponsibilitiesCustomComponent
          setSearchValue={(value) => {
            if (Array.isArray(value)) {
              const updatedResponsibilities = value.map((selectedValue) => {
                if (typeof selectedValue === "string") {
                  return { id: null, name: selectedValue };
                } else {
                  const selectedOtherResp = otherResponsibilities.find(
                    (other) => other.name === selectedValue.otherResponsibilityName
                  );
                  return selectedOtherResp
                    ? { id: selectedOtherResp.id, name: selectedOtherResp.name }
                    : { id: null, name: selectedValue.otherResponsibilityName };
                }
              });
              formik.setFieldValue("otherResponsibilities", updatedResponsibilities);
            }
          }}
          searchTitle="Остале одговорности"
          optionsData={optionsOtherResp}
          getOptionLabel={(resp) => resp.name}
          getOptionId={(resp) => resp.id}
          value={
            formik.values.otherResponsibilities
              ? formik.values.otherResponsibilities.map((item) => ({
                  id: item.id,
                  name: item.name,
                }))
              : []
          }
          updateOtherRespoOptions={(others) => {
            setOptionsOtherResp([
              ...optionsOtherResp,
              ...others.filter((o) => !optionsOtherResp.some((existing) => existing.id === o.id)),
            ]);
          }}
        />
        <Typography variant="body2">
          Одаберите врсту библиографске јединице на основу језика
        </Typography>
        <Stack direction="row" spacing={2}>
          <Paper elevation={0} className="publication-form-container__checkbox-buttons">
            <Radio
              checked={formik.values.isForeign === false}
              onChange={() => formik.setFieldValue("isForeign", false)}
              value={false}
            />
            <Typography variant="body2">Српска књига</Typography>
          </Paper>
          <Paper elevation={0} className="publication-form-container__checkbox-buttons">
            <Radio
              checked={formik.values.isForeign === true}
              onChange={() => formik.setFieldValue("isForeign", true)}
              value={true}
            />
            <Typography variant="body2">Страна књига</Typography>
          </Paper>
        </Stack>
        <Typography variant="body2">Дигитални објекат</Typography>
        <FileUploaderZone
          handleFileChange={handleFileChange}
          handleRemoveFile={handleRemoveFile}
          files={files}
          maxFiles={1}
          allowedFormats={[
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.oasis.opendocument.text",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
            "application/vnd.oasis.opendocument.spreadsheet",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
            "application/vnd.oasis.opendocument.presentation",
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/webp",
          ]}
          customSizeTitle={
            "pdf, docx, doc, odt, xls, xlsx, csv, ods, ppt, pptx, pps, ppsx, odp, jpg, jpeg, png, webp"
          }
        />
      </Stack>
      <ModalActions
        buttonAction={formik.handleSubmit}
        buttonText="Сачувај"
        cancelBtnText="Назад"
        cancelAction={handleClose}
      />
    </CustomModal>
  );
};
export default MonoEditModal;
