import ListBasicInfo from "./ListBasicInfo/ListBasicInfo";
import { createToastNotification } from "../../utilities/helpers";
import {
  deleteAsset,
  fetchAssetDetails,
  fetchUnitForList,
  fetchUnits,
  removeUnitFromList,
} from "../../api/lists";
import React, { useEffect, useState } from "react";
import { IAssetDetails, IUnits } from "../../common/interfaces/lists.interface";
import { useNavigate, useParams } from "react-router";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteUnitCard from "../BibliographicUnitDetails/DeleteUnitComponent/DeleteUnitCard";
import BasicTable from "../../components/BasicTable/BasicTable";
import ConfirmationModal from "../../components/ConfirmationModal";
import { LISTS_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import AddUnitToListModal from "./AddUnitToListModal/AddUnitToListModal";

const ListOfProclaimedAssetsDetails = () => {
  const [assetInfo, setAssetInfo] = useState<IAssetDetails>();
  const [unitToAddOnList, setUnitToAddOnList] = useState<IUnits[]>();
  const [units, setUnits] = useState<IUnits[]>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpenModal] = useState<boolean>();
  const [isAddOnListModalOpen, setIsAddOnListModalOpenModalOpenModal] = useState<boolean>();
  const [isRemoveConfirmationModalOpen, setIsRemoveConfirmationModalOpenModal] =
    useState<boolean>();
  const [metadata, setMetadata] = useState({
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUnit, setSelectedUnit] = useState<any | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, unit: any) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setSelectedUnit(unit);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { id } = useParams();
  const getListOfProclaimedAssetDetails = async () => {
    try {
      if (typeof id === "string") {
        const response = await fetchAssetDetails(parseInt(id, 10));
        setAssetInfo(response);
      }
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getListOfProclaimedAssetDetails();
    // eslint-disable-next-line
  }, [id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const deleteSpecificAsset = async () => {
    try {
      if (id) {
        await deleteAsset(parseInt(id, 10));
        createToastNotification("success", "Успешно обрисана листа!");
      }
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const getUnitsForListTable = async () => {
    try {
      if (typeof id === "string") {
        const response = await fetchUnitForList(
          parseInt(id, 10),
          "bibliographicUnit",
          metadata.currentPage,
          metadata.itemsPerPage
        );
        setUnits(response.units);
        setMetadata({
          currentPage: response.currentPage,
          itemsPerPage: response.itemsPerPage,
          itemCount: response.itemCount,
          totalItems: response.totalItems,
          totalPages: response.totalPages,
        });
      }
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const getUnitsToAddOnList = async () => {
    try {
      const response = await fetchUnits(assetInfo?.level, "bibliographicUnit");
      setUnitToAddOnList(response.units);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
  };

  const removeUnitFromTable = async () => {
    if (selectedUnit && selectedUnit.bibliographicUnitID) {
      try {
        await removeUnitFromList(selectedUnit.bibliographicUnitID);
        setUnits((prevUnits) =>
          prevUnits?.filter((unit) => unit.bibliographicUnitID !== selectedUnit.bibliographicUnitID)
        );
        createToastNotification("success", "Успешно уклоњено дело са листе!");
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      } finally {
        handleMenuClose();
      }
    }
  };

  useEffect(() => {
    getUnitsForListTable();
    // eslint-disable-next-line
  }, [metadata.totalItems, metadata.currentPage]);

  useEffect(() => {
    if (assetInfo) {
      getUnitsToAddOnList();
    }
    // eslint-disable-next-line
  }, [assetInfo]);

  return (
    <Stack>
      <div className="basic-unit-details-header">
        <Stack
          direction="row"
          alignItems="center"
          className="basic-unit-details-header__navigation"
          onClick={handleBackClick}
        >
          <ArrowBackIcon sx={{ height: "20px", mr: 1 }} />
          Листа проглашених културних добара
        </Stack>
      </div>
      <Stack direction="row" spacing={3}>
        <Stack width="70%">
          <ListBasicInfo asset={assetInfo} />
        </Stack>
        <DeleteUnitCard
          handleDelete={() => {
            setIsConfirmationModalOpenModal(true);
          }}
          deleteButtonName="Обриши листу"
        />
        {isConfirmationModalOpen && (
          <ConfirmationModal
            confirmationModalTitle="Да ли сте сигурни да желите да обришете листу?"
            confirmBtnTitle="Потврди"
            modalDescription="Имајте на уму да се једном обрисани подаци не могу касније вратити!"
            cancelAction={() => setIsConfirmationModalOpenModal(false)}
            handleDelete={() => {
              deleteSpecificAsset();
              navigate("/cultural-assets-list");
            }}
          />
        )}
      </Stack>
      <>
        <Stack direction="row" justifyContent="space-between" mt={3} mb={2}>
          <Typography variant="h6">Листа дела</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsAddOnListModalOpenModalOpenModal(true)}
          >
            Додај на листу
          </Button>
        </Stack>
        <BasicTable
          loader={false}
          headings={LISTS_TABLE_HEADINGS}
          metadata={metadata}
          setMetadata={setMetadata}
        >
          {units && units.length > 0 ? (
            units.map((unit, index) => (
              <TableRow key={index} className="nonclickable-style">
                <TableCell>{unit.title}</TableCell>
                <TableCell>{unit.authors.join(",")}</TableCell>
                <TableCell>{unit.yearOfPublication}</TableCell>
                <TableCell>{unit.placeOfIssue}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="more options"
                    onClick={(event) => handleMenuOpen(event, unit)}
                  >
                    <MoreHorizOutlinedIcon sx={{ color: "gray" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <Typography m={2}>Нема дела за приказ!</Typography>
          )}
        </BasicTable>
        <Menu
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              setIsRemoveConfirmationModalOpenModal(true);
            }}
          >
            Уклони са листе
          </MenuItem>
        </Menu>
        {isRemoveConfirmationModalOpen && (
          <ConfirmationModal
            confirmationModalTitle="Да ли сте сигурни да желите да обришете дело са листе?"
            confirmBtnTitle="Потврди"
            modalDescription="Имајте на уму да се једном обрисани подаци не могу касније вратити!"
            cancelAction={() => setIsRemoveConfirmationModalOpenModal(false)}
            handleDelete={() => {
              removeUnitFromTable();
              setIsRemoveConfirmationModalOpenModal(false);
              getUnitsForListTable();
              setSelectedUnit(null);
            }}
          />
        )}
      </>
      {isAddOnListModalOpen && (
        <AddUnitToListModal
          unitToAddOnList={unitToAddOnList}
          handleClose={() => setIsAddOnListModalOpenModalOpenModal(false)}
          assetInfo={assetInfo}
          getUnitsForListTable={getUnitsForListTable}
        />
      )}
    </Stack>
  );
};
export default ListOfProclaimedAssetsDetails;
