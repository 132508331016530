import React, { FC, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchInput.scss";

const SearchInput: FC<{
  setSearchValue: (searchValue: string) => void;
  icon?: boolean;
  searchTitle?: string;
}> = ({ setSearchValue, icon, searchTitle }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => setSearchValue(value), 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [value]);

  return (
    <TextField
      className="search-input"
      variant="outlined"
      placeholder={searchTitle}
      value={value}
      fullWidth
      onChange={(e) => setValue(e.target.value)}
      InputProps={
        icon
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="search-input__icon" onClick={() => null} />
                </InputAdornment>
              ),
            }
          : {}
      }
    />
  );
};

export default SearchInput;
