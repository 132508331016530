import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";

interface StatusOption {
  value: string;
  label: string;
}

interface SelectInputProps {
  values: StatusOption[];
  label: string;
  onChange: (value: string | number | (string | number)[]) => void;
  width?: string;
  multiple?: boolean;
  preselectedValueIds?: string[];
  disabled?: boolean;
}

const CustomSelectInput: FC<SelectInputProps> = ({
  width,
  values,
  label,
  onChange,
  multiple,
  preselectedValueIds,
  disabled,
}) => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  useEffect(() => {
    if (preselectedValueIds) {
      setSelectedValue(preselectedValueIds);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    const newValue = Array.isArray(value) ? value : value.split(",");
    const outputValue =
      !multiple && newValue.length === 1
        ? isNaN(Number(newValue[0]))
          ? newValue[0]
          : Number(newValue[0])
        : newValue.map((v) => (isNaN(Number(v)) ? v : Number(v)));

    setSelectedValue(newValue);
    onChange(outputValue);
  };

  return (
    <FormControl style={{ width: width }} disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple={multiple}
        value={selectedValue}
        onChange={handleChange}
        renderValue={(selected) => {
          return (selected as string[])
            .map((value) => {
              const selectedItem = values.find((item) => item.value === value);
              return selectedItem ? selectedItem.label : value;
            })
            .join(", ");
        }}
        label={label}
      >
        {values.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={selectedValue.includes(value)} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectInput;
