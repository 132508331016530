import { getRequest, patchRequest } from "./utilities/http";
import { IInstitutionInfo } from "../common/interfaces/institutions.interface";
import { INSTITUTIONS } from "./utilities/api.urls";

export async function fetchInstitutionDetails(ID: number, pageUser?: number, perPageUser?: number) {
  const queryParams = new URLSearchParams({
    pageUser: String(pageUser),
    perPageUser: String(perPageUser),
  });
  const url = `/institutions/${ID}/?${queryParams}`;
  return getRequest(url);
}

export async function editInstitutionDetails(ID: number, data: IInstitutionInfo) {
  const url = `/institution/${ID}`;
  return patchRequest(url, data);
}

export async function fetchInstitutions(search?: string) {
  let url = `${INSTITUTIONS}`;
  if (search) {
    url += `?${new URLSearchParams({
      search: search,
    })}`;
  }

  return await getRequest(url);
}
