import { Typography } from "@mui/material";
import { FC } from "react";
import "./DetailsCardRow.scss";

const DetailsCardRow: FC<{ title: string; content: string | number | null | undefined }> = ({
  title,
  content,
}) => {
  return (
    <div className="card-row">
      <div className="card-row__title-column">
        <Typography variant="subtitle2">{title}</Typography>
      </div>
      <div className="card-row__content-column">
        <Typography variant="body2">
          {typeof content === "string" && content.startsWith("http") ? (
            <a href={content} target="_blank" rel="noopener noreferrer" className="card-row__link">
              Линк
            </a>
          ) : content === 0 ? (
            "0"
          ) : (
            content || "/"
          )}
        </Typography>
      </div>
    </div>
  );
};
export default DetailsCardRow;
