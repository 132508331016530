import React, { FC } from "react";
import { Button, DialogActions } from "@mui/material";
import "./CustomModal.scss";

//TODO change style when design is ready

const ModalActions: FC<{
  buttonAction: () => void;
  buttonText?: string;
  cancelAction?: () => void;
  cancelBtnText?: string;
  disabled?: boolean;
  buttonsFullWidth?: boolean;
}> = ({ buttonText, buttonsFullWidth, cancelAction, buttonAction, cancelBtnText, disabled }) => {
  return (
    <DialogActions className="action-button">
      {cancelAction && (
        <Button onClick={cancelAction} variant="outlined" fullWidth={buttonsFullWidth}>
          {cancelBtnText ?? "Cancel"}
        </Button>
      )}
      <Button
        variant="contained"
        type="submit"
        onClick={buttonAction}
        disabled={disabled}
        fullWidth={buttonsFullWidth}
      >
        {buttonText ?? "Confirm"}
      </Button>
    </DialogActions>
  );
};

export default ModalActions;
