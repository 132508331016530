import { useLocation, useNavigate } from "react-router";
import { Button, Toolbar } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import "./NavTabs.scss";
import { Newspaper } from "@mui/icons-material";
import { ALL_NEWS, NEWS } from "../../common/constants/routes.constants";
import { useAuthStore } from "../../store/authStore";
import { UserRole } from "../../common/enums/common.enums";
import {
  CENTRAL_REGISTRY,
  HOLDERS,
  HOME_PAGE,
  LIST_OF_CULTURAL_ASSETS,
  LIST_OF_INSTITUTIONS,
  USERS,
} from "../../common/constants/routes.constants";

const NavTabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authState } = useAuthStore();
  const isMasterAdmin = authState.role === UserRole.MasterAdmin;
  let newsPath = NEWS;
  if (isMasterAdmin) {
    newsPath = ALL_NEWS;
  }

  const navItems = [
    { path: HOME_PAGE, label: "Почетна страна", icon: <HomeOutlinedIcon /> },
    { path: CENTRAL_REGISTRY, label: "Централни регистар", icon: <MenuBookOutlinedIcon /> },
    { path: LIST_OF_INSTITUTIONS, label: "Листа институција", icon: <ListIcon /> },
    { path: USERS, label: "Корисници", icon: <PersonIcon /> },
    {
      path: LIST_OF_CULTURAL_ASSETS,
      label: "Листе проглашених културних добара",
      icon: <ListAltIcon />,
    },
    { path: HOLDERS, label: "Сопственици", icon: <SupervisedUserCircleIcon /> },
    { path: newsPath, label: "Вести", icon: <Newspaper /> },
  ];

  const filteredNavItems = navItems.filter((item) => {
    if (authState.role === UserRole.MasterAdmin || authState.role === UserRole.Admin) {
      if (item.path === LIST_OF_INSTITUTIONS) {
        return true;
      }
    }
    if (authState.role === UserRole.MasterAdmin) {
      return true;
    }

    if (
      item.path === LIST_OF_INSTITUTIONS ||
      item.path === USERS ||
      item.path === LIST_OF_CULTURAL_ASSETS ||
      item.path === HOLDERS
    ) {
      return false;
    }

    return true;
  });

  return (
    <Toolbar className="toolbar">
      {filteredNavItems.map(({ path, label, icon }) => (
        <Button
          key={path}
          onClick={() => navigate(path)}
          sx={{
            color: location.pathname === path ? "#111927" : "#6C737F",
            fontWeight: 600,
          }}
          startIcon={icon}
        >
          {label}
        </Button>
      ))}
    </Toolbar>
  );
};
export default NavTabs;
