import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import "./NoDataTableRow.scss";

const NoDataTableRow: React.FC<{
    message: string;
}> = ({ message }) => {
    return (
        <TableRow className="no-data-row">
            <TableCell className="no-data-row__message" align="center" colSpan={10}>
                <div className="no-data-row__message--text">
                    <Typography variant="body1">{message}</Typography>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default NoDataTableRow;
