import React, { FC, useState } from "react";
import { TextField, Box } from "@mui/material";

const DateRangeFilter: FC<{
  onChange: (value: any) => void;
  fieldNames: { from: string; to: string };
  labels: { from: string; to: string };
}> = ({ onChange, fieldNames, labels }) => {
  const [yearRange, setYearRange] = useState({
    [fieldNames.from]: "",
    [fieldNames.to]: "",
  });

  const handleYearChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;

    const updatedYearRange = {
      ...yearRange,
      [name]: value,
    };

    setYearRange(updatedYearRange);
    onChange({ field: name, value });
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="30%">
      <TextField
        fullWidth
        label={labels.from}
        variant="outlined"
        size="medium"
        name={fieldNames.from}
        value={yearRange[fieldNames.from]}
        onChange={handleYearChange}
      />
      <TextField
        fullWidth
        label={labels.to}
        variant="outlined"
        size="medium"
        name={fieldNames.to}
        value={yearRange[fieldNames.to]}
        onChange={handleYearChange}
      />
    </Box>
  );
};

export default DateRangeFilter;
