import { Box, Button, Card, CircularProgress, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router";
import { InputFileUpload } from "../../../components/InputFileUpload/InputFileUpload";
import { validateFileFormat } from "../../../utilities/file-helper";
import FileUploaderZone from "../../../uploader/FileUploaderZone/FileUploaderZone";
import { ConfirmNewsSaveDialog } from "../../../components/News/ConfirmNewsSaveDialog/ConfirmNewsSaveDialog";
import { saveNews } from "../../../api/news";
import { NewsStatus } from "../../../common/enums/news.enums";
import { ALL_NEWS } from "../../../common/constants/routes.constants";
import { createToastNotification } from "../../../utilities/helpers";

export const AddNews = () => {
  const [newsData, setNewsData] = useState<{
    title: string;
    thumbnail: File | null;
    content: string;
    attachments: File[] | null;
  }>({ title: "", thumbnail: null, content: "", attachments: null });

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setNewsData({ ...newsData, [name]: value });
  };

  const handleThumbnailUpload = (files: any) => {
    if (files === null) {
      setNewsData({ ...newsData, thumbnail: null });
      return;
    }

    try {
      validateFileFormat(files, 1, ["image/jpeg", "image/png", "image/jpg", "image/webp"]);
      if (files[0]) {
        setNewsData({ ...newsData, thumbnail: files[0] });
      }
    } catch (error) {}
  };

  const handleAttachmentsUpload = (files: File[] | null) => {
    setNewsData({ ...newsData, attachments: files });
  };

  const handleAttachmentsRemove = (index: number) => {
    if (!newsData.attachments) {
      setNewsData({ ...newsData, attachments: null });
      return;
    }

    const updatedFiles = newsData.attachments.filter((_, fileIndex) => fileIndex !== index);
    setNewsData({ ...newsData, attachments: updatedFiles });
  };

  const save = async (status: NewsStatus) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await saveNews({
        ...newsData,
        status: status,
      });

      createToastNotification(
        "success",
        status === NewsStatus.Published ? "Вест је објављена успешно" : "Вест је креирана успешно"
      );
      navigate(ALL_NEWS);
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    } finally {
      setLoading(false);
      setModalOpened(false);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <ConfirmNewsSaveDialog
        open={modalOpened}
        disabledFirst={isSubmitting}
        disabledSecond={isSubmitting}
        saveDraft={async () => {
          await save(NewsStatus.Draft);
        }}
        saveAndPublish={async () => {
          await save(NewsStatus.Published);
        }}
        cancel={()=>{
            setLoading(false);
            setModalOpened(false);
        }}
      />
      <Box className="page-table">
        <Card sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CardContent sx={{ width: 900 }}>
            <Box>
              <TextField
                label="Наслов вести"
                name="title"
                value={newsData.title}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
              />
              <InputFileUpload
                title="Насловна фотографија"
                buttonName="Изабери насловну фотографију"
                validFileExtensions="Подржане фотографије: png, jpg, jpeg, webp. Максимална величина фотографије је 50MB"
                ImgFileThumbnailText="Подржане фотографије би требало да буду 'landscape' оријентације и минималне резолуције 1920x1080. Пример подржаних резолуција: 1920x1080, 2560x1440, 3840x2160."
                handleFilesUpload={handleThumbnailUpload}
                uploadedFile={newsData.thumbnail}
              />
              <TextField
                label="Садржај вести"
                name="content"
                value={newsData.content}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                required
                multiline
                rows={10}
              />
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Приложени документи
              </Typography>
              <FileUploaderZone
                handleFileChange={handleAttachmentsUpload}
                handleRemoveFile={handleAttachmentsRemove}
                files={newsData.attachments}
                maxFiles={10}
                allowedFormats={[
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  "application/vnd.oasis.opendocument.text",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  "text/csv",
                  "text/plain",
                  "application/vnd.oasis.opendocument.spreadsheet",
                  "application/vnd.ms-powerpoint",
                  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                  "application/vnd.ms-powerpoint",
                  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                  "application/vnd.oasis.opendocument.presentation",
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "image/webp",
                ]}
                customSizeTitle={
                  "Подржани формати докумената: pdf, docx, doc, odt, xls, xlsx, csv, ods, ppt, pptx, pps, ppsx, odp, jpg, jpeg, png, webp"
                }
              />
              <Box mt={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    setModalOpened(true);
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : "Сачувај"}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
