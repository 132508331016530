import { useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { IHolderDetailsDetailsResponse } from "../../common/interfaces/holders.interface";
import { fetchHolderDetails } from "../../api/holders";
import { createToastNotification } from "../../utilities/helpers";
import { Box, Stack, Typography } from "@mui/material";
import BasicTable from "../../components/BasicTable/BasicTable";
import {
  HOLDER_DETAILS_LEGACY_TABLE_HEADINGS,
  HOLDER_DETAILS_SPECIMEN_TABLE_HEADINGS,
} from "../../common/constants/table-headings.constants";
import NoDataTableRow from "../../components/NoDataTableRow/NoDataTableRow";
import { HolderDetailsLegacyTableRow } from "./HolderDetailsTableRow/HolderDetailsLegacyTableRow";
import { HolderDetailsSpecimenTableRow } from "./HolderDetailsTableRow/HolderDetailsSpecimenTableRow";
import HolderInfoComponent from "./HolderInfoComponent/HolderInfoComponent";

export const HolderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [legacyPaginationMetadata, setLegacyPaginationMetadata] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
  });

  const [specimenPaginationMetadata, setSpecimenPaginationMetadata] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
  });

  const [holderDetails, setHolderDetails] = useState<IHolderDetailsDetailsResponse | null>(null);
  const getHolderDetails = async () => {
    if (id) {
      try {
        const response = await fetchHolderDetails(
          Number(id),
          legacyPaginationMetadata.currentPage,
          legacyPaginationMetadata.itemsPerPage,
          specimenPaginationMetadata.currentPage,
          specimenPaginationMetadata.itemsPerPage
        );
        setHolderDetails(response);
        setLegacyPaginationMetadata({
          currentPage: response.legacies.currentPage,
          totalPages: response.legacies.totalPages,
          totalItems: response.legacies.totalItems,
          itemCount: response.legacies.itemCount,
          itemsPerPage: response.legacies.itemsPerPage,
        });
        setSpecimenPaginationMetadata({
          currentPage: response.specimens.currentPage,
          totalPages: response.specimens.totalPages,
          totalItems: response.specimens.totalItems,
          itemCount: response.specimens.itemCount,
          itemsPerPage: response.specimens.itemsPerPage,
        });
      } catch (error: any) {
        createToastNotification("error", error.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    getHolderDetails();
    // eslint-disable-next-line
  }, [
    legacyPaginationMetadata.currentPage,
    legacyPaginationMetadata.itemsPerPage,
    specimenPaginationMetadata.currentPage,
    specimenPaginationMetadata.itemsPerPage,
  ]);

  return (
    <Stack spacing={4}>
      <HolderInfoComponent holderDetails={holderDetails} />
      <Box className="page-table" sx={{ marginTop: 4 }}>
        <Typography sx={{ marginTop: 2 }} variant="h5" gutterBottom>
          Легати
        </Typography>
        <BasicTable
          headings={HOLDER_DETAILS_LEGACY_TABLE_HEADINGS}
          loader={false}
          metadata={legacyPaginationMetadata}
          setMetadata={setLegacyPaginationMetadata}
        >
          {holderDetails?.legacies && holderDetails?.legacies.legacies.length > 0 ? (
            holderDetails?.legacies.legacies.map((item, index) => {
              return (
                <HolderDetailsLegacyTableRow
                  index={index}
                  legacy={item}
                  handleRowClick={() => {
                    navigate(`/details/${item.bibliographicUnitID}/bibliographicUnit`);
                  }}
                />
              );
            })
          ) : (
            <NoDataTableRow message="Нема легата за приказ" />
          )}
        </BasicTable>
      </Box>
      <Typography sx={{ marginTop: 2 }} variant="h5" gutterBottom>
        Примерци
      </Typography>
      <Box className="page-table">
        <BasicTable
          headings={HOLDER_DETAILS_SPECIMEN_TABLE_HEADINGS}
          loader={false}
          metadata={specimenPaginationMetadata}
          setMetadata={setSpecimenPaginationMetadata}
        >
          {holderDetails?.legacies && holderDetails?.legacies.legacies.length > 0 ? (
            holderDetails?.specimens.specimens.map((item, index) => {
              return (
                <HolderDetailsSpecimenTableRow
                  index={index}
                  specimen={item}
                  handleRowClick={() => {
                    navigate(`/details/${item.specimenID}/specimen`);
                  }}
                />
              );
            })
          ) : (
            <NoDataTableRow message="Нема примерака за приказ" />
          )}
        </BasicTable>
      </Box>
    </Stack>
  );
};
