import {
  objectToFormData,
  patchRequest,
  patchRequestFormData,
  postRequest,
  postRequestFormData,
} from "./utilities/http";
import { LEGACY_PUBLICATIONS, MONOGRAPHIC_PUBLICATIONS } from "./utilities/api.urls";
import { LegacyDTO, MonoPublicationDTO } from "../common/dtos/publications.dto";

export async function createMonographicPublication(data: MonoPublicationDTO) {
  const files = data.media;
  // @ts-ignore
  delete data.media;
  const authors: { authorName: string; authorId: number | null }[] | [] = data.authors
    ? data.authors
    : [];
  delete data.authors;

  const otherR: any[] | [] = data.otherResponsibilities ? data.otherResponsibilities : [];
  delete data.otherResponsibilities;

  for (const key in data) {
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (data[key] == undefined) {
      // @ts-ignore
      delete data[key];
    }
  }

  const formData = objectToFormData(data);
  if (authors) {
    for (let i = 0; i < authors.length; i++) {
      formData.append(
        `authors[${i}][authorID]`,
        authors[i].authorId == null ? "0" : String(authors[i].authorId)
      );
      formData.append(`authors[${i}][authorName]`, authors[i].authorName);
    }
  }

  if (otherR) {
    for (let i = 0; i < otherR.length; i++) {
      formData.append(
        `otherResponsibilities[${i}][otherResponsibilityID]`,
        otherR[i].otherResponsibilityID == null ? "0" : String(otherR[i].otherResponsibilityID)
      );
      formData.append(
        `otherResponsibilities[${i}][otherResponsibilityName]`,
        otherR[i].otherResponsibilityName
      );
    }
  }
  if (files && files?.length > 0) {
    files.forEach((file) => {
      formData.append("media", file, file.name);
    });
  }

  return postRequestFormData(MONOGRAPHIC_PUBLICATIONS, formData);
}

export async function createLegacyPublication(data: LegacyDTO) {
  return postRequest(LEGACY_PUBLICATIONS, data);
}

export function editDetailsForMonographicUnit(ID: number, data2: any) {
  const url = `/cultural-property/printed-or-manuscript-book/${ID}`;
  const data = data2 as MonoPublicationDTO;
  const files = data.media;
  // @ts-ignore
  delete data.media;

  const authors: { authorName: string; authorId: number | null }[] | [] = data.authors
    ? data.authors
    : [];
  delete data.authors;

  const otherR: any[] | [] = data.otherResponsibilities ? data.otherResponsibilities : [];
  //@ts-ignore
  delete data.otherResponsibilities;
  for (const key in data) {
    // @ts-ignore
    // eslint-disable-next-line eqeqeq
    if (data[key] == undefined) {
      // @ts-ignore
      delete data[key];
    }
  }

  const formData = objectToFormData(data);
  if (authors) {
    for (let i = 0; i < authors.length; i++) {
      formData.append(
        `authors[${i}][authorID]`,
        authors[i].authorId == null ? "0" : String(authors[i].authorId)
      );
      formData.append(`authors[${i}][authorName]`, authors[i].authorName);
    }
  }

  if (otherR) {
    for (let i = 0; i < otherR.length; i++) {
      formData.append(
        `otherResponsibilities[${i}][otherResponsibilityID]`,
        otherR[i].id == null ? "0" : String(otherR[i].id)
      );
      formData.append(`otherResponsibilities[${i}][otherResponsibilityName]`, otherR[i].name);
    }
  }

  if (files && files?.length > 0) {
    files.forEach((file: File | null) => {
      if (file) {
        formData.append("media", file, file.name);
      }
    });
  }

  return patchRequestFormData(url, formData);
}

export function editDetailsLegacyUnit(ID: number, data: any) {
  const url = `/cultural-property/legacies-and-comprehensive-collections/${ID}`;
  return patchRequest(url, data);
}
