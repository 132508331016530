import { TableCell, TableRow } from "@mui/material";
import React, { FC } from "react";
import { getSignificanceLevelLabel, getStatusLabel, getUnitType } from "../../../utilities/helpers";

const CentralRegistryTableRow: FC<{
  item: any;
  index: number;
  handleRowClick: () => void;
}> = ({ item, index, handleRowClick }) => {
  return (
    <TableRow key={index} onClick={handleRowClick}>
      <TableCell>{item.bibliographicUnitID}</TableCell>
      <TableCell>{item.title}</TableCell>
      <TableCell>{item.authors?.join(', ')}</TableCell>
      <TableCell>{item.placeOfIssue}</TableCell>
      <TableCell>{item.yearOfPublication}</TableCell>
      <TableCell>{item.holderName}</TableCell>
      <TableCell>{item.institutionName}</TableCell>
      <TableCell>{item.otherResponsibilities.join(", ")}</TableCell>
      <TableCell>{getSignificanceLevelLabel(item.significanceLevel)}</TableCell>
      <TableCell>{getUnitType(item.unitType)}</TableCell>
      <TableCell>{getStatusLabel(item.recordStatus)}</TableCell>
      <TableCell>{item.yearOfProclamation}</TableCell>
      <TableCell width="8%">{item.dateOfRegistration}</TableCell>
    </TableRow>
  );
};

export default CentralRegistryTableRow;
