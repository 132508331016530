import * as yup from "yup";

export const validationSchema = yup.object({
  mono: yup.object({
    cobissID: yup.string(),
    nationalBibliographyNumber: yup.string(),
    title: yup.string().required("Ово је обавезно поље"),
    authors: yup.array(),
    yearOfPublication: yup.string(),
    placeOfIssue: yup.string(),
    recordStatus: yup.string().required("Ово је обавезно поље"),
    significanceLevel: yup.string().required("Ово је обавезно поље"),
    isHandwriting: yup.boolean().required("Ово је обавезно поље"),
    digitalLibraryLink: yup.string().url("Молимо вас унесите валидан URL"),
    otherResponsibilities: yup.array(),
    publicationYear: yup.string(),
    dimension: yup.string(),
    numberOfPages: yup.string(),
    yearOfProclamation: yup.number(),
    isForeign: yup.boolean(),
    dateOfRegistration: yup.string(),
    writingMaterial: yup.string(),
    signatureBibliographicUnit: yup.string(),
  }),
  legacy: yup.object({
    title: yup.string().required("Ово је обавезно поље"),
    recordStatus: yup.string().required("Ово је обавезно поље"),
    significanceLevel: yup.string().required("Ово је обавезно поље"),
    institutionID: yup.string(),
    description: yup.string(),
    specimenRangeТо: yup.string(),
    specimenRangeFrom: yup.string(),
    dateOfRegistration: yup.string(),
    yearOfProclamation: yup.number(),
  }),
});

export const editValidation = yup.object({
  digitalLibraryLink: yup.string().nullable().url("Молимо вас унесите валидан URL"),
});
