import DetailsCardRow from "../DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { ILegacy } from "../../../../common/interfaces/bibliographic-unit.interface";

const LegacyDetailsComponent: FC<{ bibliographicUnit: ILegacy }> = ({ bibliographicUnit }) => {
  return (
    <>
      <DetailsCardRow title="Наслов" content={bibliographicUnit?.title} />
      <DetailsCardRow title="Сопственик" content={bibliographicUnit?.holder?.holderName} />
      <DetailsCardRow
        title="Институција"
        content={bibliographicUnit?.institution?.institutionName}
      />
      <DetailsCardRow title="Година проглашења" content={bibliographicUnit?.yearOfProclamation} />
      <DetailsCardRow title="Датум уписа" content={bibliographicUnit?.dateOfRegistration} />
      <DetailsCardRow title="Опис" content={bibliographicUnit?.description} />
      <DetailsCardRow
        title="Распон година примерака"
        content={
          bibliographicUnit.specimenRangeFrom && bibliographicUnit.specimenRangeTo
            ? `${bibliographicUnit.specimenRangeFrom}-${bibliographicUnit.specimenRangeTo}`
            : "/"
        }
      />
    </>
  );
};
export default LegacyDetailsComponent;
