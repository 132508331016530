import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { FC, ReactNode, useMemo } from "react";
import { ITableHeadings, ITableMetadata } from "../../common/interfaces/common.interfaces";
import "./BasicTable.scss";

const BasicTable: FC<{
  loader: boolean;
  headings: ITableHeadings[];
  children: ReactNode;
  metadata: ITableMetadata;
  setMetadata: React.Dispatch<React.SetStateAction<ITableMetadata>>;
  noPagination?: boolean;
}> = ({ headings, children, metadata, setMetadata, loader, noPagination }) => {
  const handlePageChange = (page: number) => {
    setMetadata({ ...metadata, currentPage: page + 1 });
  };

  const handleRowsPerPageChange = (newItemsPerPage: number) => {
    setMetadata({
      ...metadata,
      itemsPerPage: newItemsPerPage,
      currentPage: 1,
    });
  };

  const labelDisplayedRows = useMemo(
    () => (paginationInfo: { from: number; to: number; count: number; page: number }) => {
      const { count, page } = paginationInfo;
      const from = count > 0 ? page * metadata.itemsPerPage + 1 : 0;
      const to = Math.min(count, (page + 1) * metadata.itemsPerPage);

      return `${from} - ${to} од ${count}`;
    },
    [metadata.itemsPerPage]
  );

  return (
    <TableContainer className="basic-table">
      <Table sx={{ minWidth: 650 }} aria-label="basic table">
        <TableHead className="basic-table__header">
          <TableRow>
            {headings.map((item) => (
              <TableCell key={item.id}>
                <p className="basic-table__row">{item.label}</p>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loader ? (
            <TableRow>
              <TableCell>
                <CircularProgress className="basic-table__loader" />
              </TableCell>
            </TableRow>
          ) : (
            children
          )}
        </TableBody>
        <TableFooter>
          {!noPagination && (
            <TableRow className="nonclickable-style">
              <TablePagination
                onPageChange={(event: React.MouseEvent | null, selectedPage: number) => {
                  handlePageChange(selectedPage);
                }}
                page={metadata.currentPage - 1}
                count={metadata.totalItems}
                rowsPerPage={metadata.itemsPerPage}
                labelRowsPerPage="Редова по страни"
                onRowsPerPageChange={(event) => {
                  handleRowsPerPageChange(Number(event.target.value));
                }}
                labelDisplayedRows={labelDisplayedRows}
              />
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
export default BasicTable;
