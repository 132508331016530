import DetailsCardRow from "../DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { ISpecimen } from "../../../../common/interfaces/bibliographic-unit.interface";
import { Stack } from "@mui/material";

const SpecimenUnitComponent: FC<{ bibliographicUnit: ISpecimen }> = ({ bibliographicUnit }) => {
  return (
    <>
      <Stack direction="column" gap={1} className="add-or-update-specimen-info__input-container">
        <DetailsCardRow
          title="Аутори"
          content={bibliographicUnit?.bibliographicUnit?.authors
            ?.map((author) => author.authorName)
            .join(", ")}
        />
        <DetailsCardRow
          title="Национални библиографски број"
          content={bibliographicUnit?.bibliographicUnit?.nationalBibliographyNumber}
        />
        <DetailsCardRow
          title="Cossib ID"
          content={bibliographicUnit?.bibliographicUnit?.cobissID}
        />
        <DetailsCardRow
          title="Место издања"
          content={bibliographicUnit?.bibliographicUnit?.placeOfIssue}
        />
        <DetailsCardRow
          title="Година издања"
          content={bibliographicUnit?.bibliographicUnit?.yearOfPublication}
        />
      </Stack>
      <DetailsCardRow title="Шифра примерка" content={bibliographicUnit?.specimenID} />
      <DetailsCardRow title="Наслов" content={bibliographicUnit?.title} />
      <DetailsCardRow title="Сопственик" content={bibliographicUnit?.holder.holderName} />
      <DetailsCardRow
        title="Институција"
        content={bibliographicUnit?.institution.institutionName}
      />
      <DetailsCardRow title="Опис" content={bibliographicUnit?.description} />
      <DetailsCardRow title="Линк" content={bibliographicUnit?.digitalCopyLink} />
      <DetailsCardRow
        title="Дигитализација"
        content={bibliographicUnit?.hasDigitalization ? "Извршена" : "Није извршена"}
      />
      <DetailsCardRow
        title="Микрофилм"
        content={bibliographicUnit?.hasMicrofilm ? "Постоји" : "Не постоји"}
      />

      <DetailsCardRow title="Сигнатура" content={bibliographicUnit?.signature} />
      <DetailsCardRow title="Инвентарски број" content={bibliographicUnit?.inventoryNumber} />
    </>
  );
};
export default SpecimenUnitComponent;
