import DetailsCardRow from "../BasicDetailsCardComponent/DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { ISpecimen } from "../../../common/interfaces/bibliographic-unit.interface";
import { Card, CardContent, CardHeader } from "@mui/material";

const SpecimenRestaurtionInfo: FC<{ bibliographicUnit: ISpecimen }> = ({ bibliographicUnit }) => {
  return (
    <Card>
      <CardHeader title="Рестаурација" />
      <CardContent>
        <DetailsCardRow
          title="Година извршења"
          content={bibliographicUnit.conservation?.bindingRestauration?.yearOfProtection}
        />
        <DetailsCardRow
          title="Институција која је извршила"
          content={bibliographicUnit.conservation?.bindingRestauration?.protectionInstitution}
        />
        <DetailsCardRow
          title="Сачуван оригинал"
          content={
            bibliographicUnit.conservation?.bindingRestauration?.isOriginalSaved ? "Да" : "Не"
          }
        />
        <DetailsCardRow
          title="Постоји нови повез"
          content={bibliographicUnit.conservation?.bindingRestauration?.hasNewBinding ? "Да" : "Не"}
        />
        <DetailsCardRow
          title="Напомена"
          content={bibliographicUnit.conservation?.bindingRestauration?.note}
        />
      </CardContent>
    </Card>
  );
};
export default SpecimenRestaurtionInfo;
