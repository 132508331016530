import { getRequest, postRequest } from "./utilities/http";

export async function fetchBibliographicUnits(itemID: number, itemType: string) {
  const url = `/cultural-property/${itemID}?itemType=${itemType}`;
  return getRequest(url);
}

export async function fetchListOfSpecimens(
  bibliographicID: number,
  page?: number,
  perPage?: number
) {
  const queryParams = new URLSearchParams({
    page: String(page || 1),
    perPage: String(perPage || 10),
  });
  const url = `/specimens/${bibliographicID}?${queryParams}`;
  return getRequest(url);
}

export async function deleteUnit(ID: number) {
  const url = `/cultural-property/${ID}/delete`;
  return postRequest(url, {});
}

export async function deleteSpecimen(ID: number) {
  const url = `/specimen/${ID}/delete`;
  return postRequest(url, {});
}
