import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Typography,
  CardContent,
  Grid,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getPublicNewsByID } from "../../../api/news";
import { useParams } from "react-router-dom";
import { createToastNotification } from "../../../utilities/helpers";
import { INews } from "../../../common/interfaces/news.interface";
import wordIcon from "../../../assets/icons/files/doc.png";
import pdfIcon from "../../../assets/icons/files/pdf.png";
import excelIcon from "../../../assets/icons/files/xls.png";
import pptIcon from "../../../assets/icons/files/ppt.png";
import AttachmentIcon from "@mui/icons-material/Attachment";
import "./DetailsPublicNews.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import { NEWS } from "../../../common/constants/routes.constants";

export const DetailsPublicNews = () => {
  const { id } = useParams();
  const [news, setNews] = useState<INews | null>(null);
  const [loading, setLoading] = useState(true);
  const [downloadedFiles, setDownloadedFiles] = useState<File[]>([]);
  const [hoveredFile, setHoveredFile] = useState<string | null>(null); // State to track the hovered file
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 }); // Track mouse position
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      fetchNewsData(Number(id));
    }
  }, [id]);

  useEffect(() => {
    if (news?.files) {
      downloadFilesInBackground(news.files);
    }
  }, [news]);

  const fetchNewsData = async (id: number) => {
    try {
      const response = await getPublicNewsByID(id);
      setNews(response);
    } catch (error) {
      createToastNotification("error", "Неуспешно дохватање вести", error);
    } finally {
      setLoading(false);
    }
  };

  const downloadFilesInBackground = async (files: string[]) => {
    try {
      const downloaded = await Promise.all(
        files.map(async (file) => {
          const response = await fetch(file);
          let fileName = new URL(file).pathname.split("/").pop() ?? "";
          let fileNameArr = fileName.split("-");
          if (fileNameArr[5]) {
            fileName = fileName.replaceAll(
              fileNameArr[0] +
                "-" +
                fileNameArr[1] +
                "-" +
                fileNameArr[2] +
                "-" +
                fileNameArr[3] +
                "-" +
                fileNameArr[4] +
                "-",
              ""
            );
          }
          fileName = decodeURIComponent(fileName);
          const blob = await response.blob();
          return new File([blob], fileName, { type: blob.type });
        })
      );
      setDownloadedFiles(downloaded);
    } catch (error) {
      createToastNotification("error", "Грешка при преузимању датотека", error);
    }
  };

  const captureMousePosition = (e: React.MouseEvent) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const isImageFile = (file: File) => {
    const fileType = file.name.split(".").pop();
    switch (fileType) {
      case "png":
      case "jpg":
      case "jpeg":
      case "webp":
        return true;
      default:
        return false;
    }
  };
  const getFileIcon = (fileName: string) => {
    const fileType = fileName.split(".").pop();
    switch (fileType) {
      case "pdf":
        return <img src={pdfIcon} alt="pdf icon" style={{ width: "50px", height: "50px" }} />;
      case "docx":
      case "doc":
        return <img src={wordIcon} alt="word icon" style={{ width: "50px", height: "50px" }} />;
      case "xlsx":
      case "xls":
        return <img src={excelIcon} alt="excel icon" style={{ width: "50px", height: "50px" }} />;
      case "pptx":
      case "ppt":
        return <img src={pptIcon} alt="ppt icon" style={{ width: "50px", height: "50px" }} />;
      default:
        return <AttachmentIcon style={{ fontSize: "50px", color: "#555" }} />;
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!news) {
    return <Typography>Детаљи нису дохваћени</Typography>;
  }

  return (
    <Box className="page-table details-public-news">
      <Stack
        direction="row"
        alignItems="center"
        className="basic-unit-details-header__navigation"
        onClick={() => {
          navigate(NEWS);
        }}
      >
        <ArrowBackIcon sx={{ height: "20px", mr: 1 }} />
        Врати се
      </Stack>
      <Card sx={{ marginTop: 2 }}>
        {news.imageURL && (
          <CardMedia
            component="img"
            image={news.imageURL}
            alt={news.title}
            style={{ maxHeight: 400, objectFit: "cover" }}
          />
        )}
        <br />

        <CardContent>
          <Typography variant="h4" gutterBottom>
            {news.title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Објављено дана: {new Date(news.publishedDate).toLocaleDateString()}
          </Typography>
        </CardContent>

        {/* Display content */}
        <CardContent>
          <Typography
            variant="body1"
            paragraph
            component="pre"
            sx={{
              marginTop: 6,
              whiteSpace: "pre-wrap",
              fontFamily: "inherit",
            }}
          >
            {news.content}
          </Typography>
        </CardContent>

        {/* Display file blocks */}
        {news.files.length > 0 && (
          <CardContent>
            <Typography variant="h6">Приложена документа:</Typography>
            <br></br>
            <Grid container spacing={2}>
              {downloadedFiles.map((file, index) => (
                <Grid item xs={12} sm={3} key={index}>
                  <Box
                    className="attachment-block"
                    sx={{
                      padding: 2,
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      textAlign: "center",
                      background: "#f9f9f9",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      "&:hover": { background: "#f1f1f1" },
                    }}
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = URL.createObjectURL(file);
                      link.download = file.name;
                      link.click();
                      URL.revokeObjectURL(link.href);
                    }}
                    onMouseEnter={(e) => {
                      setHoveredFile(file.name);
                      captureMousePosition(e);
                    }}
                    onMouseLeave={() => setHoveredFile(null)}
                  >
                    {isImageFile(file) ? (
                      <img
                        src={news.files[index]}
                        alt={file.name}
                        style={{
                          width: "200px",
                          height: "40px",
                          objectFit: "cover",
                          marginBottom: "8px",
                        }}
                      />
                    ) : (
                      getFileIcon(file.name)
                    )}

                    {hoveredFile === file.name && (
                      <Box
                        sx={{
                          position: "fixed",
                          top: `${mousePosition.y + 50}px`,
                          left: `${mousePosition.x}px`,
                          transform: "translate(-50%, -50%)",
                          bgcolor: "rgba(0, 0, 0, 0.8)",
                          color: "white",
                          padding: "10px",
                          borderRadius: "2px",
                          zIndex: 9999,
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          fontSize: "0.8em",
                          maxWidth: "90vw",
                          textOverflow: "ellipsis",
                          pointerEvents: "none",
                          wordBreak: "break-word",
                        }}
                      >
                        {file.name}
                      </Box>
                    )}

                    <Typography variant="body2" noWrap title={file.name}>
                      {file.name.length > 30 ? `${file.name.slice(0, 30)}...` : file.name}
                    </Typography>

                    <Typography variant="caption" color="textSecondary">
                      Кликните да бисте преузели
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default DetailsPublicNews;
