import { PUBLIC_CULTURAL_PROPERTY } from "./utilities/api.urls";
import { postRequest } from "./utilities/http";
import { IHomeFilter } from "../common/interfaces/home.interface";

export async function fetchCulturalProperty(
  page?: number,
  perPage?: number,
  searchFilters?: IHomeFilter
) {
  const queryParams = new URLSearchParams({
    page: String(page),
    perPage: String(perPage),
  });

  const url = `${PUBLIC_CULTURAL_PROPERTY}?${queryParams}`;
  const data = searchFilters ? { ...searchFilters, page, perPage } : { page, perPage };

  return postRequest(url, data);
}
