import { FC } from "react";
import warning from "../assets/warning.svg";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import ModalActions from "./Modal/ModalActions";
import CustomModal from "./Modal/CustomModal";

type ConfirmationModalProps = {
  modalDescription?: string;
  confirmationModalTitle: string;
  confirmBtnTitle?: string;
  cancelAction: () => void;
  handleDelete: () => void;
};
const ConfirmationModal: FC<ConfirmationModalProps> = ({
  modalDescription,
  confirmationModalTitle,
  confirmBtnTitle,
  cancelAction,
  handleDelete,
}) => {
  return (
    <CustomModal open handleClose={cancelAction} fullWidth maxWidth="md">
      <Stack direction="row" gap={2}>
        <img src={warning} alt="icon" width="48px" height="48px" />
        <Stack gap={1}>
          <Typography variant="h5" color="#111927">
            {confirmationModalTitle}
          </Typography>
          <Typography variant="body2" mb={3}>
            {modalDescription}
          </Typography>
        </Stack>
      </Stack>
      <ModalActions
        buttonText={confirmBtnTitle}
        cancelBtnText="Назад"
        buttonAction={handleDelete}
        cancelAction={cancelAction}
      />
    </CustomModal>
  );
};
export default ConfirmationModal;
