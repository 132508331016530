import React, {FC, useMemo, useState, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {IconButton, Paper, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {uploadFile} from "../../utilities/file-helper";
import uploadImg from "../../assets/icons/uploadIcon.svg";
import "./FileUploaderZone.scss";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentIcon from "@mui/icons-material/Attachment";
import wordIcon from "../../assets/icons/files/doc.png";
import excelIcon from "../../assets/icons/files/xls.png";
import pptIcon from "../../assets/icons/files/ppt.png";
import pdfIcon from "../../assets/icons/files/pdf.png";

const FileUploaderZone: FC<{
    handleFileChange: (files: File[] | null) => void;
    customSizeTitle?: string;
    handleRemoveFile: (index: number) => void;
    files: File[] | null;
    maxFiles: number;
    allowedFormats: string[];
}> = ({customSizeTitle, handleFileChange, files, handleRemoveFile, maxFiles, allowedFormats}) => {
    const [hoveredFile, setHoveredFile] = useState<string | null>(null);
    const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({x: 0, y: 0});

    const clickTitle = "Кликни да отпремиш";
    const dragTitle = " или превуци и отпусти";
    const formatTitle = customSizeTitle || "(JPG, PNG)";

    const {getRootProps, getInputProps, open} = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => {
            uploadFile(
                files === null ? [] : files,
                acceptedFiles,
                (files: File[]) => {
                    handleFileChange(files);
                },
                maxFiles,
                allowedFormats
            );
        },
    });

    const isImageFile = (file: File) => {
        const fileExtension = file.name.split(".").pop();
        return file.type.startsWith("image/") || (fileExtension && ["png", "jpeg", "jpg", "webp"].includes(fileExtension));
    };

    const getFileIcon = (file: File) => {
        const fileType = file.name.split(".").pop();
        switch (fileType) {
            case "pdf":
                return <img src={pdfIcon} alt="pdf icon" style={{width: "50px", height: "50px"}}/>;
            case "docx":
            case "doc":
                return <img src={wordIcon} alt="word icon" style={{width: "50px", height: "50px"}}/>;
            case "xlsx":
            case "xls":
                return <img src={excelIcon} alt="excel icon" style={{width: "50px", height: "50px"}}/>;
            case "pptx":
            case "ppt":
                return <img src={pptIcon} alt="ppt icon" style={{width: "50px", height: "50px"}}/>;
            default:
                return <AttachmentIcon style={{fontSize: "50px", color: "#555"}}/>;
        }
    };

    // Memoize file previews
    const filePreviews = useMemo(() => {
        const previews = files?.map((file) => {
            const url = isImageFile(file) ? URL.createObjectURL(file) : null;
            return {file, url};
        });

        return previews || [];
    }, [files]);

    // Cleanup URLs on unmount or when files change
    useEffect(() => {
        return () => {
            filePreviews.forEach((preview) => {
                if (preview.url) {
                    URL.revokeObjectURL(preview.url);
                }
            });
        };
    }, [filePreviews]);

    const captureMousePosition = (e: React.MouseEvent) => {
        setMousePosition({x: e.clientX, y: e.clientY});
    };

    return (
        <Paper {...getRootProps()}>
            <>
                <Box
                    className="uploader-zone__image-container"
                    display="grid"
                    gridTemplateColumns="repeat(3, 1fr)"
                    gap={2}
                >
                    {filePreviews.map((preview, index) => {
                        const truncatedFileName =
                            preview.file.name.length > 10 ? `${preview.file.name.slice(0, 10)}...` : preview.file.name;

                        return (
                            <Box
                                key={index}
                                className="uploader-zone__file-preview"
                                position="relative"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                onMouseEnter={(e) => {
                                    setHoveredFile(preview.file.name);
                                    captureMousePosition(e);
                                }}
                                onMouseLeave={() => setHoveredFile(null)}
                            >
                                {preview.url ? (
                                    <img
                                        src={preview.url}
                                        alt={preview.file.name}
                                        className="uploaded-image"
                                        style={{width: "100%", height: "auto", borderRadius: "8px"}}
                                    />
                                ) : (
                                    <Box display="flex" flexDirection="column" alignItems="center">
                                        {getFileIcon(preview.file)}
                                        <Typography variant="caption">{truncatedFileName}</Typography>
                                    </Box>
                                )}

                                {hoveredFile === preview.file.name && (
                                    <Box
                                        sx={{
                                            position: "fixed",
                                            top: `${mousePosition.y + 50}px`,
                                            left: `${mousePosition.x}px`,
                                            transform: "translate(-50%, -50%)",
                                            bgcolor: "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                            padding: "10px",
                                            borderRadius: "2px",
                                            zIndex: 9999,
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            fontSize: "0.8em",
                                            maxWidth: "90vw",
                                            textOverflow: "ellipsis",
                                            pointerEvents: "none",
                                            wordBreak: "break-word",
                                        }}
                                    >
                                        {preview.file.name}
                                    </Box>
                                )}

                                <IconButton
                                    className="remove-image-button"
                                    onClick={() => handleRemoveFile(index)}
                                    style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        backgroundColor: "white",
                                        borderRadius: "50%",
                                        padding: "4px",
                                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)",
                                    }}
                                >
                                    <CloseIcon style={{color: "black", fontSize: "16px"}}/>
                                </IconButton>
                            </Box>
                        );
                    })}
                </Box>

                {files === null || files.length < maxFiles ? (
                    <Box className="uploader-zone__drop-zone" {...getRootProps()} onClick={open} sx={{marginTop:5}}>
                        <Box className="uploader-zone__icon-container">
                            <img src={uploadImg} alt="upload-icon"/>
                        </Box>
                        <Box>
                            <input {...getInputProps()} />
                            <Box className="uploader-zone__upload-text">
                                <span className="uploader-zone__upload-text--underlined">{clickTitle}</span>
                                {dragTitle}
                            </Box>
                            <p className="uploader-zone__format-title">{formatTitle}</p>
                        </Box>
                    </Box>
                ) : null}
            </>
        </Paper>
    );
};

export default FileUploaderZone;
