import { Box, Typography } from "@mui/material";
import BasicTable from "../../components/BasicTable/BasicTable";
import { HOLDERS_TABLE_HEADINGS } from "../../common/constants/table-headings.constants";
import React, { useEffect, useState } from "react";
import { HoldersInterface, IHoldersFilter } from "../../common/interfaces/holders.interface";
import { HoldersTableHeader } from "./HoldersTableHeader/HoldersTableHeader";
import { fetchHolders } from "../../api/holders";
import { createToastNotification } from "../../utilities/helpers";
import HoldersTableRow from "./HoldersTableRow/HoldersTableRow";
import { useNavigate } from "react-router";
import { HOLDER_DETAILS } from "../../common/constants/routes.constants";

export const Holders = () => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<IHoldersFilter>({});
  const [holders, setHolders] = useState<HoldersInterface[]>([]);
  const navigate = useNavigate();

  const [holdersPaginationMetadata, setHoldersPaginationMetadata] = useState({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
  });

  const getHoldersData = async () => {
    try {
      const response = await fetchHolders(
        holdersPaginationMetadata.currentPage,
        holdersPaginationMetadata.itemsPerPage,
        filters
      );
      setHolders(response.holders);
      setHoldersPaginationMetadata({
        currentPage: response.currentPage,
        totalPages: response.totalPages,
        totalItems: response.totalItems,
        itemCount: response.itemCount,
        itemsPerPage: response.itemsPerPage,
      });
    } catch (error: any) {
      createToastNotification("error", error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const setSearchFilters = (key: "name" | "institutions", value: any) => {
    const updatedSearchFilters = filters;
    if (key === "name") {
      updatedSearchFilters.name = value;
    } else {
      updatedSearchFilters.institutions = value;
    }
    setFilters(updatedSearchFilters);
  };

  useEffect(() => {
    setLoading(true);
    getHoldersData();
    // eslint-disable-next-line
  }, [holdersPaginationMetadata.currentPage, holdersPaginationMetadata.itemsPerPage]);

  return (
    <Box className="page-table">
      <HoldersTableHeader getHoldersData={getHoldersData} setSearchFilters={setSearchFilters} />
      <BasicTable
        loader={loading}
        headings={HOLDERS_TABLE_HEADINGS}
        metadata={holdersPaginationMetadata}
        setMetadata={setHoldersPaginationMetadata}
      >
        {holders.length > 0 ? (
          holders.map((item, index) => {
            return (
              <HoldersTableRow
                item={item}
                index={Number(item.holderID)}
                key={index}
                handleRowClick={() => {
                  navigate(HOLDER_DETAILS + "/" + item.holderID);
                }}
              />
            );
          })
        ) : (
          <Typography m={2}>Нема сопственика за приказ!</Typography>
        )}
      </BasicTable>
    </Box>
  );
};
