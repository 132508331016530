import CustomModal from "../../../components/Modal/CustomModal";
import { Autocomplete, Paper, Stack, TextField } from "@mui/material";
import { FC, useState } from "react";
import { IAssetDetails, IUnits } from "../../../common/interfaces/lists.interface";
import ModalActions from "../../../components/Modal/ModalActions";
import { useParams } from "react-router";
import { createToastNotification } from "../../../utilities/helpers";
import { addUnitToList } from "../../../api/lists";

const AddUnitToListModal: FC<{
  unitToAddOnList: IUnits[] | undefined;
  handleClose: () => void;
  assetInfo: IAssetDetails | undefined;
  getUnitsForListTable: () => Promise<void>;
}> = ({ unitToAddOnList, handleClose, assetInfo, getUnitsForListTable }) => {
  const { id } = useParams();
  const [selectedUnit, setSelectedUnit] = useState<IUnits | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formattedOptions =
    unitToAddOnList?.map(
      (unit) => `${unit.title} - ${unit.authors.join(",") || "Непознат аутор"}`
    ) || [];

  const submitUnitToList = async () => {
    if (!selectedUnit) {
      createToastNotification("error", "Нисте изабрали дело.");
      return;
    }
    setIsSubmitting(true);
    try {
      if (typeof id === "string") {
        const data = {
          listID: parseInt(id, 10),
          bibliographicUnitID: selectedUnit.bibliographicUnitID,
        };
        await addUnitToList(data);
        createToastNotification("success", "Дело је успешно додато на листу.");
        handleClose();
        getUnitsForListTable();
      }
    } catch (error: any) {
      createToastNotification("error", error?.response?.data?.message);
    }
    setIsSubmitting(false);
  };

  return (
    <CustomModal
      title={`Додај дело на листу ${assetInfo?.name?.toLowerCase()}`}
      open
      handleClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Stack m={2} direction="column" spacing={4}>
        <Autocomplete
          disablePortal
          options={formattedOptions}
          onChange={(event, newValue) => {
            const unit = unitToAddOnList?.find((unit) => {
              const authors = unit.authors?.join(", ") || "Непознат аутор";
              return `${unit.title} - ${authors}` === newValue;
            });
            setSelectedUnit(unit || null);
          }}
          renderInput={(params) => <TextField {...params} label="Дела" />}
          PaperComponent={(props) => (
            <Paper {...props} style={{ maxHeight: 100, overflowY: "hidden" }} />
          )}
          noOptionsText="Нема опција"
        />
        <ModalActions
          buttonText="Додај"
          cancelBtnText="Назад"
          buttonAction={submitUnitToList}
          disabled={isSubmitting || !selectedUnit}
          cancelAction={handleClose}
        />
      </Stack>
    </CustomModal>
  );
};
export default AddUnitToListModal;
