import React, { FC } from "react";
import { TableCell, TableRow } from "@mui/material";
import { getSignificanceLevelLabel, getStatusLabel, getUnitType } from "../../../utilities/helpers";

const HomeTableRow: FC<{ item: any; index: number }> = ({ item, index }) => {
  return (
    <TableRow key={index} className="nonclickable-style">
      <TableCell>{item.bibliographicUnitID}</TableCell>
      <TableCell>{item.title}</TableCell>
      <TableCell>{item.authors?.join(", ")}</TableCell>
      <TableCell>{item.placeOfIssue}</TableCell>
      <TableCell>{item.yearOfPublication}</TableCell>
      <TableCell>{item.holderName}</TableCell>
      <TableCell>{item.institutionName}</TableCell>
      <TableCell>{item.otherResponsibilities.join(", ")}</TableCell>
      <TableCell>{getSignificanceLevelLabel(item.significanceLevel)}</TableCell>
      <TableCell>{getUnitType(item.unitType)}</TableCell>
      <TableCell>{getStatusLabel(item.recordStatus)}</TableCell>
      <TableCell>{item.yearOfProclamation}</TableCell>
      <TableCell width="8%">{item.dateOfRegistration}</TableCell>
    </TableRow>
  );
};

export default HomeTableRow;
