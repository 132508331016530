import CustomModal from "../../../components/Modal/CustomModal";
import ModalActions from "../../../components/Modal/ModalActions";
import Stack from "@mui/material/Stack";
import { ListItemText, MenuItem, TextField } from "@mui/material";
import React, { FC, useEffect } from "react";
import { createToastNotification, getSignificanceLevelLabel } from "../../../utilities/helpers";
import { useFilterStore } from "../../../store/filtersStore";
import { useFormik } from "formik";
import { EMPTY_STRING } from "../../../common/constants/common.constants";
import { createList } from "../../../api/lists";
import { listsValidationSchema } from "../../../common/validation-schemas/lists.schema";

const AddNewListModal: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const formik = useFormik({
    initialValues: {
      year: undefined,
      significanceLevel: EMPTY_STRING,
      officialMessengerIssueNumber: EMPTY_STRING,
    },
    validationSchema: listsValidationSchema,
    onSubmit: async () => {
      try {
        await createList(createListDTO);
        createToastNotification("success", "Успешно креирана листа.");
        handleClose();
      } catch (error: any) {
        createToastNotification("error", error?.response?.data?.message);
      }
    },
  });

  const createListDTO = {
    year: Number(formik.values.year),
    significanceLevel: formik.values.significanceLevel,
    officialMessengerIssueNumber: formik.values.officialMessengerIssueNumber,
  };

  const { significanceLevel, getSignificanceLevels } = useFilterStore();
  const allSignificanceLevels = significanceLevel.significanceLevels || [];

  useEffect(() => {
    getSignificanceLevels();
    // eslint-disable-next-line
  }, []);

  return (
    <CustomModal title="Креирај листу" open handleClose={handleClose} fullWidth maxWidth="sm">
      <Stack spacing={3} m={1}>
        <TextField
          fullWidth
          label="Година листе"
          name="year"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.year && Boolean(formik.errors.year)}
          helperText={formik.touched.year && formik.errors.year}
        />
        <TextField
          fullWidth
          label="Ниво значаја"
          required
          select
          name="significanceLevel"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.significanceLevel}
        >
          {allSignificanceLevels.length > 0 &&
            allSignificanceLevels.map((significance) => (
              <MenuItem key={significance} value={significance}>
                <ListItemText primary={getSignificanceLevelLabel(significance)} />
              </MenuItem>
            ))}
        </TextField>
        <TextField
          fullWidth
          label="Број из службеног гласника"
          name="officialMessengerIssueNumber"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
      </Stack>
      <ModalActions
        buttonAction={formik.handleSubmit}
        buttonText="Сачувај"
        cancelBtnText="Назад"
        cancelAction={handleClose}
      />
    </CustomModal>
  );
};
export default AddNewListModal;
