import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Card, CardMedia, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const InputFileUpload: FC<{
  title: string;
  buttonName: string;
  validFileExtensions?: string;
  multipleFiles?: boolean;
  handleFilesUpload: (files: any) => void;
  uploadedFile?: File | null;
  ImgFileThumbnailText?: string;
}> = ({
  title,
  buttonName,
  validFileExtensions,
  multipleFiles = false,
  handleFilesUpload,
  uploadedFile = null,
  ImgFileThumbnailText,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Typography variant="body2" color="textSecondary" sx={{ marginBottom: "10px" }}>
        {title}
      </Typography>
      {uploadedFile ? (
        <>
          <Card
            sx={{
              maxWidth: 300,
              // margin: 'auto',
              boxShadow: 3,
              position: "relative",
              overflow: "visible",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered && (
              <IconButton
                size="small"
                sx={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                  zIndex: 10,
                  backgroundColor: "error.main",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "error.dark",
                  },
                  width: 24,
                  height: 24,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFilesUpload(null);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            <CardMedia
              component="img"
              height="40"
              image={URL.createObjectURL(uploadedFile)}
              alt={uploadedFile.name}
              sx={{
                height: 300,
                objectFit: "cover",
              }}
            />
            <CardContent
              sx={{
                padding: 1,
                "&:last-child": {
                  paddingBottom: 1,
                },
              }}
            >
              <Typography variant="body2" color="text.secondary" noWrap>
                {uploadedFile.name}
              </Typography>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {buttonName}
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => {
                handleFilesUpload(event.target.files);
              }}
              multiple={multipleFiles}
            />
          </Button>
          {validFileExtensions ? (
            <Stack direction="column" gap={1}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  marginTop: "10px",
                  fontSize: "0.8em",
                }}
              >
                {validFileExtensions}
              </Typography>
              {ImgFileThumbnailText && (
                <Typography
                  variant="body1"
                  color="textSecondary"
                  sx={{
                    fontSize: "0.8em",
                  }}
                >
                  {ImgFileThumbnailText}
                </Typography>
              )}
            </Stack>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
