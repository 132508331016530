import { Card, CardContent } from "@mui/material";
import React, { FC } from "react";
import DetailsCardRow from "../../BibliographicUnitDetails/BasicDetailsCardComponent/DetailsCardRow/DetailsCardRow";
import { IHolderDetailsDetailsResponse } from "../../../common/interfaces/holders.interface";

const HolderInfoComponent: FC<{ holderDetails: IHolderDetailsDetailsResponse | null }> = ({
  holderDetails,
}) => {
  return (
    <Card>
      <CardContent>
        <DetailsCardRow title="Шифра сопственика:" content={holderDetails?.holderID} />
        <DetailsCardRow title="Назив сопственика:" content={holderDetails?.holderName} />
        <DetailsCardRow
          title="Институција која врши упис"
          content={holderDetails?.institutionName}
        />
        <DetailsCardRow title="Укупан број легата:" content={holderDetails?.countOfLegacy} />
        <DetailsCardRow title="Укупан број примерака:" content={holderDetails?.countOfSpecimen} />
      </CardContent>
    </Card>
  );
};
export default HolderInfoComponent;
