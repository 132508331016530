import axios from "axios";
import { RestCodes } from "../../common/enums/common.enums";
import { useAuthStore } from "../../store/authStore";
import createAuthRefreshInterceptor from "axios-auth-refresh";

type HttpMethod = "POST" | "GET" | "PUT" | "PATCH" | "DELETE";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const { accessToken, refreshToken } = useAuthStore.getState().authState;
    if (config.url === `${process.env.REACT_APP_BASE_URL}/refresh`) {
      config.headers["Authorization"] = `Bearer ${refreshToken}`;
    } else if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const { refreshToken } = useAuthStore.getState();
createAuthRefreshInterceptor(axiosInstance, refreshToken, { statusCodes: [401] });

export function postRequest<T = any>(path: string, data: Record<string, any>): Promise<T> {
  return makeRequest("POST", path, data);
}

export function putRequest<T = any>(path: string, data: Record<string, any>): Promise<T> {
  return makeRequest("PUT", path, data);
}

export function patchRequest<T = any>(path: string, data: Record<string, any>): Promise<T> {
  return makeRequest("PATCH", path, data);
}

export function deleteRequest<T = any>(path: string, data?: Record<string, any>): Promise<T> {
  return makeRequest("DELETE", path, data);
}

export function getRequest<T = any>(path: string): Promise<T> {
  return makeRequest("GET", path);
}

export async function makeRequest(method: HttpMethod, path?: string, data?: Record<string, any>) {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
    Accept: "application/json, */*",
  };

  const requestInit: RequestInit = {
    method,
    headers,
  };

  if (data) {
    requestInit.body = JSON.stringify(data);
  }

  const url = `${BASE_URL}${path}`;

  const response = await axiosInstance.request<any>({
    method,
    url,
    data,
  });

  if (response.status >= RestCodes.BadRequest) {
    return response.statusText;
  }

  if (method === "DELETE") {
    return response;
  }

  return response.data;
}

export async function postRequestFormData(path: string, formData: FormData) {
  const url = `${BASE_URL}${path}`;
  const method = "POST";
  try {
    const response = await axiosInstance.request<any>({
      method,
      url,
      data: formData,
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status >= RestCodes.BadRequest) {
      throw new Error(response.statusText);
    }

    return response.data;
  } catch (error) {
    console.error("Request failed", error);
    throw error;
  }
}
export async function patchRequestFormData(path: string, formData: FormData) {
  const url = `${BASE_URL}${path}`;
  const method = "PATCH";
  try {
    const response = await axiosInstance.request<any>({
      method,
      url,
      data: formData,
      headers: {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status >= RestCodes.BadRequest) {
      throw new Error(response.statusText);
    }

    return response.data;
  } catch (error) {
    console.error("Request failed", error);
    throw error;
  }
}

export function objectToFormData(
  obj: Record<string, any>,
  formData: FormData = new FormData(),
  parentKey: string | null = null
): FormData {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const formKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof File) {
      formData.append(formKey, value);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        objectToFormData({ [index]: item }, formData, formKey);
      });
    } else if (value && typeof value === "object" && !(value instanceof Date)) {
      objectToFormData(value, formData, formKey);
    } else {
      formData.append(formKey, value != null ? value : "");
    }
  });

  return formData;
}
