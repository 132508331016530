import { ITableMetadata } from "../interfaces/common.interfaces";

export const EMPTY_STRING = "";
export const MAX_FILE_SIZE = 50000000;

export const initialMetadataState: ITableMetadata = {
  currentPage: 1,
  itemCount: 0,
  itemsPerPage: 10,
  totalItems: 0,
  totalPages: 0,
};
