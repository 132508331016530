import { patchRequest, postRequest } from "./utilities/http";
import { SPECIMEN } from "./utilities/api.urls";
import { IAddSpecimen } from "../common/interfaces/specimen.interface";

export async function createSpecimen(data: IAddSpecimen) {
  return postRequest(SPECIMEN, data);
}

export async function updateSpecimen(data: IAddSpecimen) {
  return patchRequest(SPECIMEN, data);
}
