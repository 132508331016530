import { Box, Typography } from "@mui/material";
import "./SpecimenInfoComponent.scss";
import { FC } from "react";
import { IOtherUnits, ISpecimen } from "../../../common/interfaces/bibliographic-unit.interface";

const SpecimenInfoComponent: FC<{
  bibliographicUnit: IOtherUnits | ISpecimen;
  isDetails?: boolean;
}> = ({ bibliographicUnit, isDetails }) => {
  const renderBibliographicUnit = () => {
    if (isDetails) {
      const specimen = bibliographicUnit as ISpecimen;
      return (
        <>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Наслов</Typography>
            <Typography className="specimen-info-component__value">{specimen.title}</Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Аутори</Typography>
            <Typography className="specimen-info-component__value">
              {specimen.bibliographicUnit.authors.map((author)=>(author.authorName)).join(', ')}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Година издања</Typography>
            <Typography className="specimen-info-component__value">
              {specimen.bibliographicUnit.yearOfPublication}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Место издања</Typography>
            <Typography className="specimen-info-component__value">
              {specimen.bibliographicUnit.placeOfIssue}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Cobiss/Bisis ID</Typography>
            <Typography className="specimen-info-component__value">
              {specimen.bibliographicUnit.cobissID}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">
              Бр. нац. библиографије
            </Typography>
            <Typography className="specimen-info-component__value">
              {specimen.bibliographicUnit.nationalBibliographyNumber}
            </Typography>
          </Box>
        </>
      );
    } else {
      const otherUnits = bibliographicUnit as IOtherUnits;
      return (
        <>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Наслов</Typography>
            <Typography className="specimen-info-component__value">{otherUnits.title}</Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Аутор</Typography>
              <Typography className="specimen-info-component__value">
                  {otherUnits?.authors
                      ?.map(author => author.authorName)
                      .join(', ') || 'Непознат аутор'}
              </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Година издања</Typography>
            <Typography className="specimen-info-component__value">
              {otherUnits.yearOfPublication}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Место издања</Typography>
            <Typography className="specimen-info-component__value">
              {otherUnits.placeOfIssue}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">Cobiss/Bisis ID</Typography>
            <Typography className="specimen-info-component__value">
              {otherUnits.cobissID}
            </Typography>
          </Box>
          <Box className="specimen-info-component__row">
            <Typography className="specimen-info-component__label">
              Бр. нац. библиографије
            </Typography>
            <Typography className="specimen-info-component__value">
              {otherUnits.nationalBibliographyNumber}
            </Typography>
          </Box>
        </>
      );
    }
  };

  return <Box className="specimen-info-component">{renderBibliographicUnit()}</Box>;
};

export default SpecimenInfoComponent;
