import IconButton from "@mui/material/IconButton";
import eyeIcon from "../../assets/icons/eye-icon.png";
import eyeIconCrossedOut from "../../assets/icons/eye-icon-crossed.png";
import React, { FC } from "react";

interface PasswordEyeToggleProps {
  handleClickShowPassword: () => void;
  showPassword: boolean;
}

const PasswordEyeToggle: FC<PasswordEyeToggleProps> = ({
  handleClickShowPassword,
  showPassword,
}) => {
  return (
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      edge="end"
      className="password-eye-toggle__icon-button"
    >
      {showPassword ? (
        <img src={eyeIcon} alt="eye-icon" />
      ) : (
        <img src={eyeIconCrossedOut} alt="eye-crossed-out-icon" />
      )}
    </IconButton>
  );
};
export default PasswordEyeToggle;
