import type { FC } from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CustomAvatar from "../CustomAvatar/CustomAvatar";
import AccountDetails from "../AccountDetails/AccountDetails";
import { usePopover } from "../../hooks/use-popover";

const AccountDetailsUserMenu: FC = () => {
  const popover = usePopover<HTMLButtonElement>();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        height={40}
        width={40}
        className="account-details-button"
      >
        <CustomAvatar size="medium" />
      </Box>
      <AccountDetails
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
export default AccountDetailsUserMenu;
