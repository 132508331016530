import React from "react";
import { Chip, TableCell, TableRow } from "@mui/material";
import "./AllNewsTableRow.scss";
import { INewsTable } from "../../../common/interfaces/news.interface";
import { formatDateFromApi } from "../../../utilities/news-helper";
import { NewsStatus } from "../../../common/enums/news.enums";
import noPic from "../../../assets/no-picture-frame.png";

const AllNewsTableRow: React.FC<{
  item: INewsTable;
  index: number;
  handleRowClick: (id: number) => void;
}> = ({ item, index, handleRowClick }) => {
  const getStatusBox = (status: NewsStatus) => {
    switch (status) {
      case NewsStatus.Draft:
        return (
          <Chip
            label="Радна верзија"
            color="warning"
            sx={{
              backgroundColor: "#FFEA61",
              color: "black",
              fontSize: "1em",
              height: 40,
              width: 160,
              padding: "0 16px",
            }}
          />
        );
      case NewsStatus.Published:
        return (
          <Chip
            label="Објављено"
            color="success"
            sx={{ fontSize: "1em", height: 40, width: 160, padding: "0 16px" }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <TableRow
      key={index}
      onClick={() => handleRowClick(item.newsID)}
      className="table-row generic-table-row"
    >
      <TableCell>{item.title}</TableCell>
      <TableCell>{item.publishedDate ? formatDateFromApi(item.publishedDate) : "/"}</TableCell>
      <TableCell>{getStatusBox(item.status)}</TableCell>
      <TableCell width="20%" className="table-row__featured-image-container">
        {item.imageURL ? (
          <img alt={item.title} src={item.imageURL || ""} className="table-row__featured-image" />
        ) : (
          <img src={noPic} alt="img" className="table-row__featured-image" />
        )}
      </TableCell>
    </TableRow>
  );
};

export default AllNewsTableRow;
