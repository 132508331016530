import { Card, CardContent, CardHeader } from "@mui/material";
import DetailsCardRow from "../DetailsCardRow/DetailsCardRow";
import { FC } from "react";
import { IOtherUnits } from "../../../../common/interfaces/bibliographic-unit.interface";
import { getSignificanceLevelLabel, getStatusLabel } from "../../../../utilities/helpers";
const InfoCardComponent: FC<{ bibliographicUnit: any }> = ({ bibliographicUnit }) => {
  return (
    <Card>
      <CardHeader title="Основне информације" />
      <CardContent>
        <DetailsCardRow title="Индекс" content={bibliographicUnit?.bibliographicUnitID} />
        {(bibliographicUnit as IOtherUnits) && (
          <DetailsCardRow title="Cobiss/Bisis ID" content={bibliographicUnit?.cobissID} />
        )}
        <DetailsCardRow
          title="Статус записа"
          content={getStatusLabel(bibliographicUnit?.recordStatus)}
        />
        <DetailsCardRow
          title="Ниво значаја"
          content={getSignificanceLevelLabel(bibliographicUnit?.significanceLevel)}
        />
      </CardContent>
    </Card>
  );
};
export default InfoCardComponent;
